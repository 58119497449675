import { gql, useMutation } from '@apollo/client';

export const ASSIGN_RATE = gql`
  mutation assignRate(
    $rateId: ID
    $isRole: Boolean
    $assignees: [ID]
    $role: RoleAssign
  ) {
    assignRate(
      rateId: $rateId
      isRole: $isRole
      assignees: $assignees
      role: $role
    ) {
      session
      duration
      currency
      rate
      selected
    }
  }
`;

export default function useAssignRate() {
  const [assignRate, result] = useMutation(ASSIGN_RATE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          rates() {
            return data?.assignRate;
          },
          rateByRole() {
            return data?.assignRate;
          },
        },
      });
    },
  });
  return { assignRate, ...result };
}
