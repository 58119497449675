import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customTooltip: (props: { isSidebar?: boolean }) => ({
    backgroundColor: !props.isSidebar ? 'white' : '#101828',
    color: !props.isSidebar ? 'black' : 'white',
    marginLeft: !props.isSidebar ? theme.spacing(1) : theme.spacing(0),
    borderRadius: '8px',
    padding: '4px !important',
    textAlign: 'center',
    zIndex: 1,
  }),
  customArrow: (props: { isSidebar?: boolean }) => ({
    color: !props.isSidebar ? 'white' : '#101828',
    zIndex: 1,
  }),
}));
const Tooltpip = ({
  children,
  title,
  placement,
  isLarge = false,
  isSidebar = false,
}) => {
  const classes = useStyles({ isSidebar });
  if (isLarge)
    return (
      <Tooltip
        title={title}
        placement={placement}
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        arrow
      >
        {children}
      </Tooltip>
    );
  return <>{children}</>;
};

export default Tooltpip;
