import React, { FC, useCallback } from 'react';
import { Avatar, ListItem, Typography, Box } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import TrashIcon from 'assets/TrashIcon';
import {
  getHourAndMinutesFromDate,
  formatDateMessage,
  formatInboxDate,
} from 'utils';
import concatName, { participantNames } from 'utils/userUtils';
import useStyles from './styles';
import ConversationItemPopover from './ConversationItemPopover';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useProvider } from 'pages/InboxPage/state/Provider';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { useLocation } from 'react-router-dom';
import { ReactComponent as GroupChatIcon } from 'assets/GroupChatIcon.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface IConversationItemProps {
  handleSelected: (threadId: string) => void;
  selected: string;
  data: GetAllThreadConversationByUser_getAllThreadConvertationByUser;
  messages: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
}
const ConversationItem: FC<IConversationItemProps> = ({
  data,
  handleSelected,
  selected,
  messages,
}) => {
  const isReadItMessage = (unReadItMessage, userId, lastUpdateTime) => {
    if (unReadItMessage) {
      const readItMessage = unReadItMessage.filter(
        (item) => item.user === userId
      );
      if (readItMessage.length === 0) {
        return false;
      }
      const { lastMessageReadIt } = readItMessage[0];
      return lastMessageReadIt >= lastUpdateTime;
    }
    return false;
  };
  const { t } = useTranslation();
  const classes = useStyles({ isPatient: false });
  const location = useLocation();
  const { setMessageHidden } = useProvider();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const {
    updatedAt,
    _id,
    creator,
    lastMessage,
    unReadItMessage,
    lastUpdateTime,
    participants,
    subject,
  } = data;
  const recepient = React.useMemo(() => {
    return participants?.find((p) => p?.user?._id !== user?._id);
  }, [participants, user]);
  const recepientName = `${recepient?.user?.firstname || ''} ${
    recepient?.user?.lastname || ''
  }`;

  const {
    content: { text },
  } = lastMessage;
  const { firstname, middlename, lastname, picture } = creator;
  const participant = participants
    ?.map((el) => el?.user)
    .filter((element) => element?._id !== (user?._id || user?.user?._id));
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleSelectedThead = useCallback(() => {
    handleSelected(_id);
    setMessageHidden(false);
  }, [handleSelected, _id]);
  const handleConfirmModalDelete = (event: any) => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { handelDeleteThreadConversation } = useProvider();
  const handleDeleteItem = () => {
    handelDeleteThreadConversation(_id);
  };
  const handleDelete = () => {
    handleDeleteItem();
    handleClose();
  };
  function determineType(mimeType: string) {
    if (mimeType && mimeType.startsWith('image/')) {
      return 'image';
    }
    if (mimeType && mimeType.startsWith('application/')) {
      return 'attachment';
    }
    return 'text';
  }
  const getLastMessage = () => {
    const isSender = lastMessage?.user?._id === user?._id;
    const senderText = isSender ? t('You') : lastMessage?.user?.username;
    const messageType = determineType(
      lastMessage?.content?.files?.[0]?.mimeType || ''
    );
    let message;

    if (!!lastMessage?.content?.text && lastMessage?.content?.text.length > 0) {
      return `${
        senderText === 'You' ? 'You: ' : `${lastMessage?.user?.firstname}: `
      }${lastMessage?.content?.text}`;
    }

    if (messageType === 'image') {
      message = `${senderText} ${t('sent an image')}`;
    } else if (messageType === 'attachment') {
      message = `${senderText} ${t('sent an attachment')}`;
    }

    return message;
  };
  const message = getLastMessage();
  const isUnread = !isReadItMessage(unReadItMessage, user?._id, lastUpdateTime);
  const isSelected = selected === _id;
  return (
    <Box className={classes.hover}>
      <Box
        style={{
          backgroundColor: isSelected ? '#96CEFD' : 'transparent',
        }}
        className={classes.conversationItem}
        onClick={handleSelectedThead}
      >
        {participant?.length > 1 ? (
          participant?.slice(0, 2)?.map((part, index) => (
            <Box
              ml={index == 0 ? 2.5 : 0}
              mt={index > 0 ? 3 * index : 1.5}
              className={classes.cardSpacing}
              key={part._id}
            >
              <Avatar
                alt={part && part?.firstname.toUpperCase()}
                src={part && part?.picture}
                className={classes.avatarCard}
                style={{
                  width: '30px',
                  height: '30px',
                  marginTop: '6px !important',
                }}
              >
                {part?.firstname.slice(0, 1).toUpperCase()}
              </Avatar>
            </Box>
          ))
        ) : (
          <Avatar
            alt={participant && participant[0]?.firstname.toUpperCase()}
            src={participant && participant[0]?.picture}
            className={classes.Avatar}
          >
            {participant[0]?.firstname.slice(0, 1).toUpperCase()}
          </Avatar>
        )}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '10px',
            flex: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                className={classes.recepientName}
                style={{
                  fontWeight: isUnread ? 700 : 600,
                }}
              >
                {recepientName}
              </Typography>
            </Box>
            <Typography
              className={classes.recepientName}
              style={{
                fontWeight: isUnread ? '700' : '500',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                overflow: 'hidden',
              }}
            >
              Re: {subject || ''}
            </Typography>
            <Typography
              className={classes.recepientName}
              style={{
                fontWeight: isUnread ? 600 : 500,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {getLastMessage()}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            marginLeft: '10px',
          }}
        >
          <Typography
            className={classes.date}
            style={{
              color: isSelected ? '#182230' : '#667085',
              textTransform: 'capitalize',
            }}
          >
            {formatInboxDate(lastMessage?.updatedAt || lastMessage?.createdAt)}
          </Typography>
          {/*isUnread && (
            <Box className={classes.unreadBox}>
              <Typography className={classes.unread}>+9</Typography>
            </Box>
          )*/}
        </Box>
      </Box>

      <ModalDelete
        text="conversation"
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default ConversationItem;
