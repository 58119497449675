import React, { useEffect } from 'react';
import { CustomAccordion } from './CustomAccordion';
import { Summary } from './Summary';
import { FromComponent } from './FromComponent';
import { InvoiceDetails } from './InvoiceDetails';
import { BillTo } from './BillTo';
import { useProvider as useAuth } from 'contexts/AuthContext';
import { Box } from '@material-ui/core';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import { useTranslation } from 'react-i18next';
import { UseFormMethods } from 'react-hook-form';

interface ICreateInvoiceForm {
  isCurrent?: boolean;
  methods: any;
  invoiceRef: number;
  patient?: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    country: string;
    suite: string;
    city: string;
    state: string;
    zipcode: string;
  };
}

export const CreateInvoiceForm = ({
  isCurrent = false,
  methods,
  invoiceRef,
  patient = undefined,
}: ICreateInvoiceForm) => {
  const { watch } = methods;
  const { doctor: _doctor } = useAuth();
  const selectedPatient = watch('patient');
  const { data } = useGetDoctor({ id: _doctor._id });
  const doctor = data?.doctor;
  const { t } = useTranslation();
  useEffect(() => {
    methods.reset({
      ...watch(),
      nameOnInvoice: `${doctor?.firstname} ${doctor?.lastname}`,
      doctorEmail: doctor?.email,
      doctorPhone: doctor?.phone,
      doctorCountry: doctor?.country,
      doctorAddress: doctor?.address,
      doctorSuite: doctor?.suite,
      doctorCity: doctor?.city,
      doctorState: doctor?.state,
      doctorZipcode: doctor?.zipcode,
    });
  }, [doctor]);

  useEffect(() => {
    methods.reset({
      ...watch(),
      patient: selectedPatient,
      patientEmail: selectedPatient?.email,
      patientPhone: selectedPatient?.phone,
      patientCountry: selectedPatient?.country,
      patientAddress: selectedPatient?.address,
      patientSuite: selectedPatient?.suite,
      patientCity: selectedPatient?.city,
      patientState: selectedPatient?.state,
      patientZipcode: selectedPatient?.zipcode,
    });
  }, [selectedPatient]);

  const doctorSummaryData = {
    name: watch('nameOnInvoice') ?? '',
    email: watch('doctorEmail') ?? '',
    phone: watch('doctorPhone') ?? '',
    address: `${watch('doctorAddress') ? watch('doctorAddress') + ', ' : ''}${
      watch('doctorSuite') ? watch('doctorSuite') + ', ' : ''
    }${
      watch('doctorState')
        ? watch('doctorCountry')
          ? watch('doctorState') + ', '
          : watch('doctorState')
        : ''
    }${watch('doctorCountry') ?? ''}`,
  };

  const patientSummaryData = {
    name: `${watch('patient')?.firstname ?? ''} ${
      watch('patient')?.lastname ?? ''
    }`,
    email: watch('patientEmail') ?? '',
    phone: watch('patientPhone') ?? '',
    address: `${watch('patientAddress') ? watch('patientAddress') + ', ' : ''}${
      watch('patientSuite') ? watch('patientSuite') + ', ' : ''
    }${
      watch('patientState')
        ? watch('patientCountry')
          ? watch('patientState') + ', '
          : watch('patientState')
        : ''
    }${watch('patientCountry') ?? ''}`,
  };

  return (
    <Box
      style={{
        visibility: isCurrent ? 'visible' : 'hidden',
        height: isCurrent ? 'auto' : 0,
        overflow: !isCurrent && 'hidden',
      }}
    >
      <CustomAccordion
        title={t('From')}
        defaultExpanded={false}
        AccordionSummary={<Summary {...doctorSummaryData} />}
        AccordionContent={<FromComponent methods={methods} />}
      />
      <CustomAccordion
        title={t('Bill to')}
        AccordionSummary={<Summary {...patientSummaryData} />}
        AccordionContent={<BillTo methods={methods} />}
      />
      <CustomAccordion
        title={`${t('Invoice n°')}${invoiceRef}`}
        hasOnlyTitle
        defaultExpanded
        AccordionSummary={<></>}
        AccordionContent={<InvoiceDetails methods={methods} />}
      />
    </Box>
  );
};
