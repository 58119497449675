import { Box, Grid } from '@material-ui/core';
import FeatureNotUsed from 'components/FeatureNotUsed';
import BasicModal from 'components/ui/Modal';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import React, { ReactElement, useState } from 'react';
import Conversation from './components/Conversation/Conversation';
import Message from './components/Message';
import Modals from './modals';
import InboxPageProvider from './state/Provider';
import useStyles from './style';
import { useProvider } from './state/Provider';
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const Inbox = (props): ReactElement => {
  const { t } = useTranslation();
  const [firstTimeModal, setFirstTimeModal] = useState(true);
  const [createNewThread, setCreateNewThread] = useState(true);
  const { doctor } = useAuth();
  const { noUsedFeatures } = doctor || {};
  const classes = useStyles();
  const { messageHidden } = useProvider();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  React.useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Messaging') });
  }, [headerTitleDispatcher]);

  const { firstname, lastname, middlename, _id, email, picture } =
    props?.data?.patient || {};

  return (
    <div className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxSizing="border-box"
        height="100%"
        width="100%"
        p="1rem"
        style={{
          gap: '1rem',
        }}
      >
        <Box
          height="100%"
          className={classes.widthBox}
          minWidth={'25%'}
          boxSizing="border-box"
        >
          <Conversation />
        </Box>
        <Box height="100%" flex={1} boxSizing="border-box">
          <Message />
        </Box>
      </Box>
      <Modals
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
    </div>
  );
};

export default Inbox;
