import { Box, Divider } from '@material-ui/core';
import Tabs from 'components/ui/Tabs';
import { useProvider } from 'pages/InboxPage/state/Provider';
import React from 'react';
import ConversationList from './ConversationList';
import ConversationListHeader from './ConversationListHeader';
import useStyles from './styles';
import SearchInput from 'components/ui/SearchInput';
import { debounce } from 'lodash';
import SearchItemListCard from './SearchItemListCard';
import { useTranslation } from 'react-i18next';

const Conversation = () => {
  const { t } = useTranslation();
  const classes = useStyles({ isPatient: false });
  const {
    archivedConversationListData,
    unArchivedConversationListData,
    handleSelectedThead,
    selected,
    searchThreadData,
    handelSearchThread,
  } = useProvider();
  const { data: searchData, loading: searchLoading } = searchThreadData;

  const handleSearch = React.useCallback((value: string) => {
    handelSearchThread(value);
  }, []);
  const debouncedSearch = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      handleSearch(e?.target?.value);
    },
    1000
  );
  return (
    <Box
      height="100%"
      style={{
        borderRadius: '12px',
        overflow: 'hidden',
        backgroundColor: 'white',
      }}
    >
      <ConversationListHeader />
      <Box pr={3} pl={2} py={1}>
        <SearchInput
          groupedOptions={
            searchData?.getAutoCompleteSearchThreadConversation || []
          }
          handleChange={debouncedSearch}
          loading={searchLoading}
          renderOption={(props) => <SearchItemListCard data={props} />}
          onChange={(__, value) => handleSelectedThead(value._id)}
        />
      </Box>
      {/* <Divider /> */}
      <Tabs
        tabsBar={[t('Active'), t('Archived')]}
        classNameTab={classes.tab}
        tabsContent={[
          <ConversationList
            isArchived={false}
            data={unArchivedConversationListData}
            handleSelected={handleSelectedThead}
            selected={selected}
          />,
          <ConversationList
            isArchived
            data={archivedConversationListData}
            handleSelected={handleSelectedThead}
            selected={selected}
          />,
        ]}
        tabsMode="simple"
        isConversation={true}
      />
    </Box>
  );
};

export default Conversation;
