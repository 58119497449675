/* eslint-disable*/
import React, { ReactElement } from 'react';
import TabScrollButton from '@material-ui/core/TabScrollButton';
import { withStyles } from '@material-ui/core/styles';
import TabWrapper from './TabWrapper';
import Panel from './Panel';
import Tab from './Tab';
import { Box } from '@material-ui/core';

interface ITabsProps {
  tabsBar: string[] | React.ReactNode[];
  tabsContent: ReactElement[];
  tabsMode:
    | 'normal'
    | 'section'
    | 'other'
    | 'greenButtons'
    | 'buttons'
    | 'patient'
    | 'form'
    | 'simple';
  classNameTab?: string;
  tabClassHeader?: string;
  customValue?: number;
  setCustomValue?: (value: number) => void;
  hasNoHeight?: boolean;
  ComponentAlignedWithTabs?: ReactElement;
  isConversation?: boolean;
  componetAlignedLeftWithTabs?: ReactElement;
  componetAlignedRightWithTabs?: ReactElement;
  classRight?: string;
}

export default function Tabs({
  tabsBar,
  tabsContent,
  tabsMode = 'normal',
  classNameTab,
  setCustomValue,
  customValue = -1,
  hasNoHeight,
  ComponentAlignedWithTabs,
  componetAlignedLeftWithTabs,
  isConversation,
  tabClassHeader,
  componetAlignedRightWithTabs,
  classRight,
}: ITabsProps): ReactElement {
  const MyTabScrollButton = withStyles((theme) => ({
    root: {
      width: 28,
      overflow: 'hidden',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0,
      },
    },
  }))(TabScrollButton);

  const [value, setValue] = React.useState(0);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    customValue >= 0 ? setCustomValue(newValue) : setValue(newValue);
  };
  const tabsRender = tabsBar.map(
    (tab: string | React.ReactNode, index: number) => (
      <Tab
        key={`tab-${index}`}
        tabsMode={tabsMode}
        label={tab}
        data-cy={`tab-${index}`}
      />
    )
  );
  const tabsContentRender = tabsContent.map((content, index) => {
    const activeValue = customValue >= 0 ? customValue : value;
    return (
      <Panel
        key={index}
        value={activeValue}
        index={index}
        hasNoHeight={hasNoHeight}
        isConversation={isConversation}
      >
        {content}
      </Panel>
    );
  });
  return (
    <>
      <Box className={tabClassHeader}>
        {componetAlignedLeftWithTabs && componetAlignedLeftWithTabs}
        <TabWrapper
          selectionFollowsFocus
          variant="scrollable"
          tabsMode={tabsMode}
          value={customValue >= 0 ? customValue : value}
          ScrollButtonComponent={MyTabScrollButton}
          onChange={handleChange}
          className={classNameTab}
        >
          {tabsRender}

          {ComponentAlignedWithTabs && ComponentAlignedWithTabs}
        </TabWrapper>
        {componetAlignedRightWithTabs && componetAlignedRightWithTabs}
      </Box>
      {tabsContentRender}
    </>
  );
}
