import React from 'react';
import { InputLabel, FormHelperText, Box, Grid } from '@material-ui/core';
import useStyles from '../TabsContent/style';
import Button from 'components/ui/Button';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import { Itags } from './types';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useTag } from './useTag';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm, FormProvider } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import EmptyComponent from '../Payment/EmptyComponent';
import { ReactComponent as TagIllustration } from 'assets/emptyState/tags.svg';
import TagModal from './TagModal';
import DropDownMenu from '../Payment/DropDownMenu';
import BasicModal from 'components/ui/Modal';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import { colorsMapping } from './Constant';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { useSnackbar } from 'hooks/useSnackbar';

export const schema = yup.object().shape({
  tag: yup.string().required('Tag is a required field'),
});

const ManageTags = () => {
  const classes = useStyles();
  const { triggerToast } = useToast();
  const { t } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const columns: IColumn<Itags>[] = [
    {
      id: 'tag',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="tagName">Tag name</Text>
        </Box>
      ),
      freeze: true,
      align: 'left',
      minWidth: 170,
      format: (value) => value,
    },
    {
      id: 'color',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="tagColor">Tag color</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => {
        return (
          <Box display="flex" alignItems="center">
            <Box
              width={17}
              height={17}
              style={{
                borderRadius: '100%',
                backgroundColor:
                  value === '#BDBDBD' ? '#BDBDBD' : t(colorsMapping[value]),
                marginRight: '5px',
              }}
            />
            {value === '#BDBDBD' ? t('Default') : t(value)}
          </Box>
        );
      },
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem">Action</Box>,
      minWidth: 10,
      align: 'left',
      freeze: true,
      format: (value, row) => (
        <MoreHorizIcon
          data-cy="more-action"
          style={{ cursor: 'pointer', fontSize: '24px', color: '#425364' }}
          onClick={(e) => handleClickDrop(e, row)}
        />
      ),
    },
  ];
  const {
    dropDownEL,
    setDropDownEl,
    currentRow,
    handleClickDrop,
    editMode,
    addTag,
    createLoading,
    data,
    handleDelete,
    open,
    openDelete,
    setopenDelete,
    handleClick,
    handleClose,
    handleEdit,
    editTag,
    updateLoading,
    selectedColor,
    deleteLoading,
    setSelectedColor,
  } = useTag();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: editMode ? currentRow : {},
  });
  async function onSubmit(data: { tag: string }) {
    const { tag } = data || {};
    if (editMode) {
      triggerSnack();
      await editTag({
        variables: {
          tagId: (currentRow as { _id: string })?._id,
          tagInput: { tag, color: selectedColor },
        },
      });
      return handleClose();
    }
    triggerSnack();
    await addTag({
      variables: {
        tagInput: { tag, color: selectedColor ? selectedColor : '#BDBDBD' },
      },
    });
    handleClose();
  }
  if (data?.doctorTags?.length === 0) {
    return (
      <Grid container spacing={2} className={classes.gridContainerBilling}>
        <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
          <Box
            width="100%"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Text i18nKey="manageSessionTags" className={classes.h3}>
              Tags
            </Text>
            <Button className={classes.addBtn} onClick={handleClick}>
              <AddTaskIcon
                style={{
                  paddingRight: '6px',
                }}
              />
              <Text i18nKey="add">Add</Text>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
          <EmptyComponent
            logo={<TagIllustration />}
            labelText="No tags"
            labelDescription={'to add a tag'}
            btnLabel="Add tag"
            handleClick={handleClick}
            methods={methods}
            open={open}
            handleClose={handleClose}
            onSubmit={onSubmit}
            isSlide={true}
            height="40vh"
            modal={
              <TagModal
                currentRow={currentRow}
                editMode={editMode}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
            }
            modalTitle={'New tag'}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2} className={classes.gridContainerBilling}>
      <Grid item xs={12} lg={12} xl={12} md={12} sm={12}>
        <Box
          width="100%"
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Text i18nKey="manageSessionTags" className={classes.h3}>
            Manage your tags throughout Eggmed
          </Text>
          <Button className={classes.addBtn} onClick={handleClick}>
            <AddTaskIcon
              style={{
                paddingRight: '6px',
              }}
            />
            <Text i18nKey="add">Add</Text>
          </Button>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        lg={12}
        xl={12}
        md={12}
        sm={12}
        style={{ marginTop: '1rem' }}
      >
        <Table
          loading={false}
          page={0}
          columns={columns}
          limit={5}
          data={data?.doctorTags || []}
        />
      </Grid>
      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          divider
          title={editMode ? t('Edit Tag') : t('New Tag')}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={createLoading || updateLoading}
        >
          <Grid container style={{ marginBottom: '5vh' }}>
            <TagModal
              currentRow={currentRow}
              editMode={editMode}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </Grid>
        </BasicModal>
      </FormProvider>
      <DropDownMenu
        handleEdit={() => handleEdit()}
        anchorEl={dropDownEL}
        handleClose={() => setDropDownEl(null)}
        handleDelete={() => setopenDelete(true)}
        row={currentRow}
      />
      <BasicModal
        open={openDelete}
        onClose={() => setopenDelete(false)}
        className={classes.deleteModal}
        isRegularModal
        handleClose={() => setopenDelete(false)}
      >
        <DeleteModalContent
          text={t('tag')}
          handleClose={() => setopenDelete(false)}
          handleDelete={() => handleDelete()}
          p={4}
          loading={deleteLoading}
        />
      </BasicModal>
    </Grid>
  );
};

export default ManageTags;
