import { makeStyles, Theme } from '@material-ui/core/styles';

type IStyle = {
  patient: boolean;
  height?: number;
  isClickedBubble?: boolean;
};

const useStyles = makeStyles((theme) => ({
  placeholderMessage: {
    fontFamily: 'Inter',
    fontWeight: 400,
    margin: '10px',
    fontSize: '12px',
    color: '#344054',
    lineHeight: '24px',
  },
  customTooltip: { backgroundColor: '#101828', color: '#FFFFFF' },
  bubbleContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
  },
  topVideoContainer: {
    position: 'relative',
    display: 'flex',
    width: 'auto',
  },
  btnVideoPlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bubbleMessage: {
    margin: '2px 0px',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    '&:hover $markIcon, &:hover $moreInfo': {
      display: 'flex',
    },
  },
  moreInfo: {
    cursor: 'pointer',
    borderRadius: '50%',
    marginLeft: '10px',
    transition: 'opacity 0.3s ease',
    display: 'none',
    '&.visible': {
      display: 'flex',
    },
  },
  markIcon: {
    marginRight: '8px',
    cursor: 'pointer',
    display: 'none',
    fill: 'none',
    transition: 'fill 0.3s ease',
    '&.filled': {
      fill: '#667085',
    },
    '&.visible': {
      display: 'flex',
    },
  },
  fullName: {
    color: '#58beeb',
  },
  date: {
    fontSize: '14px',
    color: '#6c7e85',
  },
  dateOwner: {
    fontSize: '14px',
    color: '#6c7e85',
  },
  bubble: {
    padding: '10px',
    boxSizing: 'border-box',
  },
  bubbleLeft: {
    backgroundColor: '#F9FAFB',
    maxWidth: '350px',
  },
  bubbleLeftFirstGroup: {
    backgroundColor: '#F9FAFB',
    maxWidth: '350px',
    marginLeft: '45px',
  },
  bubbleRight: {
    maxWidth: '30vw',
    backgroundColor: '#CAE8FF',
  },
  bubbleText: {
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 400,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    lineHeight: '24px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#344054',
  },
  fileText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    lineHeight: '20px',
    maxWidth: '290px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#344054',
  },
  senderInfoContainerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  toolTipBubble: {
    backgroundColor: '#101828',
    color: '#FFFFFF',
  },
  senderInfoContainerLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    width: '100%',
  },
  timestamp: {
    fontSize: '12px',
    color: '#7a90ab',
  },

  textRight: {
    marginRight: theme.typography.pxToRem(5),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'right',
  },
  textLeft: {
    marginLeft: theme.typography.pxToRem(5),
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  HeadMessageList: {
    height: '70px',
  },
  HeadMessageListSenderName: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    lineHeight: '20px',
    color: '#344054',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    },
  },
  HeadMessageListSubject: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '20px',
    color: '#344054',
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px',
    },
  },
  ArchiveBtn: {
    backgroundColor: '#147AF3',
    color: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '8px',
    border: `1px solid #147AF3`,
    whiteSpace: 'nowrap',
    '.MuiButton-startIcon': {
      marginRight: '6px !important',
    },
    '&:hover': {
      backgroundColor: '#147AF3',
      border: `1px solid #147AF3`,
    },
  },
  inputMessageContainer: {
    boxSizing: 'border-box',
    boxShadow: 'none',
    border: '1px solid #EAECF0',
    borderRadius: '20px',
    backgroundColor: '#EAECF0',
    margin: '10px',
    color: '#344054',
    width: '100%',
  },
  paper: {
    boxSizing: 'border-box',
    boxShadow: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingInline: '2rem',
    transition: 'all 0.3s',
  },
  paperFlexEnd: {
    alignItems: 'flex-end',
  },
  inputMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inputMessage: {
    flex: 1,
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px',
    },
  },
  messageListScroll: {
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    height: 'calc(100% - 90px)',
    maxHeight: 'calc(100% - 90px)',
  },
  messageListContainer: {
    background: '#fff',
    borderRight: '1px solid #f0f0f0',
    borderLeft: '1px solid #f0f0f0',
    height: '100%',
    maxHeight: '100%',
    [theme.breakpoints.only('md')]: {
      borderRadius: '0px 26px 26px 0px',
    },
  },
  EmptyMessageIllustrationImage: {
    width: '100%',
    marginBottom: '20px',
  },
  EmptyMessageIllustrationTitle: {
    fontWeight: 'bold',
    fontSize: '55px',
    lineHeight: '28px',
    fill: 'solid',
    color: theme.palette.black.dark,
  },
  EmptyMessageIllustrationText: {
    marginBlock: '20px',
    fontSize: '15px',
    fill: 'solid',
    textAlign: 'center',
    color: theme.palette.black.dark,
  },
  emptyMessageIllustrationContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
  },
  addMessageBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: '20px',
    textTransform: 'capitalize',
    background: '#0265DC',
    boxShadow: 'none',
    borderRadius: ({ patient }: IStyle) => (patient ? '30px' : '4px'),
    fontSize: '18px',
    height: '40px',
    color: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '500',
      textTransform: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
      background: '#0265DC',
    },
  },
  boldText: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Inter, sans-serif',
    color: '#101828',
  },
  headerFiles: {
    paddingTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clearButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  fileItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '10px',
  },
  hover: {
    width: '100%',
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
  },
  fileNameText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#344054',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
  fileDescriptionText: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontweight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#667085',
  },
  HeadMessageListBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  RightList: {
    paddingBottom: '10px',
  },
  imageInBubble: {
    width: '100%',
  },
  fileCardContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
  },
  fileTypeIcon: {
    display: 'flex',
    marginRight: '10px',
    background: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20%',
    width: '50px',
    height: '50px',

    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
  userName: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: theme.palette.black.dark,
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  msgContentWrapper: {
    height: ({ height }: IStyle) => `calc(100% - ${height}px)`,
  },
  msgInputWrapper: { display: 'flex' },
  hidden: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  popupitems: {
    display: 'flex',
    cursor: 'pointer',
    gap: '10px',
    minHeight: '36px',
    width: theme.typography.pxToRem(125),
    alignItems: 'center',
    paddingInline: '21px',
    '&:hover': {
      background: '#F8FAFB',
    },
  },
  popupTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#425364',
  },
  returnIcon: {
    display: 'none',
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  uploadBox: {
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    height: '100%',
    marginLeft: '-10px',
    paddingInline: '10px',
    maxWidth: '150px',
  },
  uploadedFile: {
    color: '#7a90ab',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  imagePreviewContainer: {
    maxWidth: '76%',
    display: 'flex',
    padding: '0px 14px 10px 14px;',
    gap: '0.5rem',
  },
  imagePreview: {
    width: 40,
    height: 40,
    borderRadius: '4px',
  },
  imagePreviewClose: {
    position: 'absolute',
    top: 0.1,
    right: -6,
    cursor: 'pointer',
  },
  filePreview: {},
  icon: {
    width: '32px',
    height: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  dateStyle: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    textUnderlinePposition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#667085',
  },
  sendButton: {
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    margin: 0,
    padding: 0,
  },
}));

export default useStyles;
