import React, { FC, useEffect, useRef } from 'react';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import IconButton from 'components/ui/IconButton';
import { ReactComponent as PdfIcon } from 'assets/inbox/pdf.svg';
import { ReactComponent as DocIcon } from 'assets/inbox/word.svg';
import { ReactComponent as FileIcon } from 'assets/inbox/file.svg';
import { ReactComponent as Mp3Icon } from 'assets/inbox/mp3.svg';
import { ReactComponent as PptIcon } from 'assets/inbox/ppt.svg';
import { ReactComponent as XlsxIcon } from 'assets/inbox/xlsx.svg';
import { ReactComponent as ZipIcon } from 'assets/inbox/zip.svg';
import { ReactComponent as TextIcon } from 'assets/inbox/text.svg';
import { ReactComponent as DownloadSelectedIcon } from 'assets/downloadSelected.svg';
import { ReactComponent as DownloadIcon } from 'assets/downloadNotSelected.svg';
import { ReactComponent as VideoIcon } from 'assets/inbox/video.svg';

import {
  getFilesByThreadConversation_getFilesByThreadConversation,
  getFilesByThreadConversation_getFilesByThreadConversation_content_files,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getFilesByThreadConversation';
import { ReactComponent as ReturnIcon } from 'assets/closeButton.svg';
import { Tooltip } from '@material-ui/core';

import useStyles from './style';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyFiles } from 'assets/emptyState/no_files.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { _file } from './utils';
import Input from 'components/ui/Inputs';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';
import ClearIcon from '@material-ui/icons/Clear';
import { useLazyGetThreadConversationById } from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetThreadConversationById';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import concatName from 'utils/userUtils';
import { useProvider } from 'pages/InboxPage/state/Provider';

interface IFileList {
  data: getFilesByThreadConversation_getFilesByThreadConversation[];
  setFilesHidden: (value: boolean) => void;
}
interface IFileItem {
  data: any;
  index: number;
  selected: number | null;
  setSelected: (value: number | null) => void;
}
const VideoWithPlayIcon = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  return (
    <div style={{ position: 'relative', display: 'flex', width: 'auto' }}>
      <video
        ref={videoRef}
        muted
        style={{ width: '40px', height: 'auto' }}
        playsInline
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        onClick={handlePlay}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg
          style={{ width: '12px', height: '12px' }}
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="white"
        >
          <path d="M8 5v14l11-7z" />
        </svg>
      </div>
    </div>
  );
};
export const getIconByMimeTypeAndUrl = (mimeType: string, url?: string) => {
  switch (true) {
    case _file.isPdf(mimeType):
      return <PdfIcon />;
    case _file.isImage(mimeType):
      return url ? (
        <img
          src={url}
          alt="image"
          style={{ width: '32px', height: '32px', borderRadius: '4px' }}
        />
      ) : (
        <FileIcon />
      );
    case _file.isPowerPoint(mimeType):
      return <PptIcon />;

    case _file.isExcel(mimeType):
      return <XlsxIcon />;

    case _file.isMp3(mimeType):
      return <Mp3Icon />;

    case _file.isTxt(mimeType):
      return <TextIcon />;

    case _file.isZip(mimeType):
      return <ZipIcon />;

    case _file.isWord(mimeType):
      return <DocIcon />;
    case _file.isVideo(mimeType):
      return <VideoWithPlayIcon src={url} />;
    default:
      return <FileIcon />;
  }
};

const FileList: FC<IFileList> = ({ data, setFilesHidden }) => {
  const { t } = useTranslation();
  const classes = useStyles({ patient: false });

  const FilesList = (
    data: getFilesByThreadConversation_getFilesByThreadConversation[]
  ) => {
    let filesArray = [];

    data.map(
      (item: getFilesByThreadConversation_getFilesByThreadConversation) => {
        item.content.files.map(
          (
            file: getFilesByThreadConversation_getFilesByThreadConversation_content_files
          ) => {
            filesArray.push({
              ...item,
              content: {
                ...item.content,
                files: file,
              },
            });
          }
        );
      }
    );
    return filesArray;
  };

  const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const FileItem = ({ data, index, selected, setSelected }: IFileItem) => {
    const { content } = data;
    const { files } = content;
    const { fileName, mimeType, url } = files;
    return (
      <Box className={classes.hover}>
        <Box
          className={classes.fileItem}
          onClick={() => setSelected(index)}
          style={{
            backgroundColor: selected === index ? '#96CEFD' : 'transparent',
          }}
        >
          <Box className={classes.icon}>
            {getIconByMimeTypeAndUrl(mimeType, url)}
          </Box>
          <Box flex={1}>
            <Tooltip title={fileName}>
              <Typography className={classes.fileNameText}>
                {fileName}
              </Typography>
            </Tooltip>
            <Typography className={classes.fileDescriptionText}>
              9 Mb
            </Typography>
          </Box>
          <Box
            onClick={() => onDownload(url, fileName)}
            style={{ cursor: 'pointer' }}
          >
            {selected === index ? <DownloadSelectedIcon /> : <DownloadIcon />}
          </Box>
        </Box>
      </Box>
    );
  };
  const [query, setQuery] = React.useState('');
  const [selected, setSelected] = React.useState<number | null>(null);
  const { user } = useAuth();
  const { selected: selectedThread } = useProvider();

  const handleSearch = (value: string) => {
    setQuery(value);
  };
  const handleClear = () => {
    setQuery('');
  };
  const filteredFiles = FilesList(data).filter((file) =>
    file.content.files.fileName.toLowerCase().includes(query.toLowerCase())
  );
  const [getThreadById, { data: dataThread }] =
    useLazyGetThreadConversationById();
  useEffect(() => {
    if (selectedThread) {
      getThreadById({
        variables: {
          threadConversationID: selectedThread,
        },
      });
    }
  }, [selectedThread]);
  const participants = dataThread?.getThreadConvertationById?.participants;
  const users = participants
    ?.map(({ user }) => user)
    .filter((el) => el?._id !== (user?._id || user?.user?._id));
  return (
    <Box
      style={{
        height: '100%',
        maxHeight: '100%',
        boxSizing: 'border-box',
        width: '100%',
        paddingInline: '1.5rem',
      }}
      boxSizing={'border-box'}
    >
      <Box className={classes.headerFiles}>
        <Text i18nKey="Shared files" className={classes.boldText}>
          Shared files
        </Text>
        <Box
          onClick={() => setFilesHidden(true)}
          style={{
            cursor: 'pointer',
          }}
        >
          <ReturnIcon />
        </Box>
      </Box>

      {FilesList(data).length > 0 && (
        <Box py={1}>
          <Input
            type="text"
            placeholder="Search"
            variant="filled"
            style={{
              maxHeight: '40px',
            }}
            value={query}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: query ? (
                <IconButton
                  className={classes.clearButton}
                  onClick={handleClear}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <SearchIcon />
              ),
            }}
          />
        </Box>
      )}

      <List
        style={{
          maxHeight: 'calc(100% - 90px)',
          overflow: 'hidden',
          height: '70%',
          paddingTop: '25px',
          width: '100%',
        }}
      >
        {filteredFiles.length === 0 && (
          <Box style={{ height: '70vh' }} px={2}>
            <EmptyState
              icon={<EmptyFiles />}
              title={t('No files')}
              text={t('Files that you exchange will appear here', {
                name: users?.map((el) =>
                  concatName(el?.firstname, el?.middlename, el?.lastname)
                ),
              })}
              noActionText={true}
              hasAction={false}
            />
          </Box>
        )}
        {filteredFiles.map(
          (
            file: getFilesByThreadConversation_getFilesByThreadConversation,
            index
          ) => (
            <FileItem
              data={file}
              key={file._id}
              index={index}
              setSelected={setSelected}
              selected={selected}
            />
          )
        )}
      </List>
    </Box>
  );
};

export default FileList;
