import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Button from 'components/ui/Button';
import useStyles from '../styles';
import ScrollBar from 'components/ui/ScrollBar';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { CreateInvoiceForm } from './CreateInvoiceForm';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { InvoicePreview } from './InvoicePreviewStep';
import useGetInvoiceReference from '@eggmed/graphql-client/operations/appointmentsOperations/useGetInvoiceReference';
import useCreateInvoice from '@eggmed/graphql-client/operations/appointmentsOperations/useCreateInvoice';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useGetDoctor from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctor';
import { useProvider as useSchedule } from 'pages/SchedulePage/state/SchedulePageProvider';
import { IAppointmentSubmitedData } from 'pages/SchedulePage/AddEventPage/types';
import ProgressLoader from 'components/ui/ProgressLoader';
import { convertDate } from 'utils';
import { useSnackbar } from 'hooks/useSnackbar';

export const currencySymbols = {
  USD: '$',
  EUR: '€',
};

const schema = yup.object().shape({
  nameOnInvoice: yup.string().required('Name on invoice is a required field'),
  patient: yup
    .object()
    .typeError('Client is a required field')
    .required('Client is a required field'),
  sessionType: yup
    .object()
    .typeError('Session is a required field')
    .required('Session is a required field'),
  amount: yup
    .number()
    .typeError('Amount is a required field')
    .required('Amount is a required field')
    .positive('Amount must be a positive number'),
});

export const Steps = ({
  setInvoiceTitle,
  handleClose,
  page,
  filter,
  patient,
  refetch,
}) => {
  const { t } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const { doctor } = useAuth();
  const { data: _doctor } = useGetDoctor({ id: doctor._id });
  const classes = useStyles();
  const { data: invoiceRef } = useGetInvoiceReference();
  const { createInvoice, loading } = useCreateInvoice({
    page,
    filter,
    patient: patient?._id,
  });
  const [step, setStep] = useState<0 | 1>(0);
  const { handleAddAppointment, loadingAddAppointment } = useSchedule();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: patient
      ? {
          patient: {
            ...patient,
            name: patient?.firstname + ' ' + patient?.lastname,
          },
          patientEmail: patient?.email || '',
          patientPhone: patient?.phone || '',
          patientCountry: patient?.country || '',
          patientAddress: patient?.address || '',
          patientSuite: patient?.suite || '',
          patientCity: patient?.city || '',
          patientState: patient?.state || '',
          patientZipcode: patient?.zipcode || '',
        }
      : {},
  });

  const { handleSubmit, formState } = methods;
  const { isValid } = formState;

  const handlePreviewEdit = () => {
    step === 0 ? setStep(1) : setStep(0);
  };

  const handleCloseBack = () => {
    step === 0 ? handleClose() : setStep(0);
  };

  const handleCreateVirtualAppointment = async (
    patient,
    sessionType,
    sessionDate
  ) => {
    const { rate, duration, cptCode, tags, ...rest } = sessionType || {};
    const { __typename: types, ...others } = cptCode || {};

    const submitData: IAppointmentSubmitedData = {
      title: '',
      locationLink: '',
      doctor: doctor?._id,
      patient: patient._id,
      startDate: convertDate(sessionDate, _doctor?.doctor?.timeZone),
      isPrepay: false,
      isEnded: true,
      endSessionDate: new Date(),
      isStarted: true,
      sessionType: {
        ...rest,
        rate: rate?.toString(),
        duration: duration?.toString(),
        cptCode: { ...others },
        assignedRoles: rest?.assignedRoles?.map((el) => el?._id),
        assignedProviders: rest?.assignedProviders?.map((el) => el?._id),
      },
    };

    const result = await handleAddAppointment(submitData);

    return result?.data?.addAppointment?._id;
  };

  const onSubmit = async (data) => {
    const appointmentId = await handleCreateVirtualAppointment(
      data?.patient,
      data?.sessionType,
      data?.sessionDate
    );

    const { rate, duration, cptCode, tags, ...rest } = data?.sessionType || {};
    const { __typename: types, ...others } = cptCode || {};

    const doctorName = data?.nameOnInvoice.split(' ');
    const [doctorFirstName, doctorLastName] = doctorName;

    const invoiceData = {
      doctor: doctor?._id,
      patient: data?.patient?._id,
      doctorInfo: {
        firstname: doctorFirstName,
        lastname: doctorLastName,
        phone: data?.doctorPhone,
        address: data?.doctorAddress,
        suite: data?.doctorSuite,
        city: data?.doctorCity,
        zipcode: data?.doctorZipcode,
        country: data?.doctorCountry,
        state: data?.doctorState,
      },
      patientInfo: {
        firstname: data?.patient?.firstname,
        lastname: data?.patient?.lastname,
        phone: data?.patientPhone,
        address: data?.patientAddress,
        suite: data?.patientSuite,
        city: data?.patientCity,
        zipcode: data?.patientZipcode,
        country: data?.patientCountry,
        state: data?.patientState,
      },
      appointment: appointmentId,
      session: {
        ...rest,
        rate: rate?.toString(),
        duration: duration?.toString(),
        cptCode: { ...others },
        assignedRoles: (rest as any)?.assignedRoles?.map((el) => el?._id),
        assignedProviders: (rest as any)?.assignedProviders?.map(
          (el) => el?._id
        ),
      },
      amount: parseInt(data?.amount),
      amountPaid: parseInt(data?.amountPaid),
      issueDate: data?.issueDate,
      dueDate: data?.dueDate,
      invoiceRef: invoiceRef?.getInvoiceReference,
    };

    await createInvoice({
      variables: { invoiceInput: invoiceData },
    });

    triggerSnack();
    refetch();
    handleClose();
  };

  useEffect(() => {
    setInvoiceTitle(step === 0 ? t('Create invoice') : t('Invoice preview'));
  }, [step]);

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ScrollBar className={classes.scrollbar}>
            <Box px="2rem">
              <CreateInvoiceForm
                isCurrent={step === 0}
                invoiceRef={invoiceRef?.getInvoiceReference}
                methods={methods}
                patient={patient}
              />
              <InvoicePreview
                isCurrent={step === 1}
                invoiceRef={invoiceRef?.getInvoiceReference}
              />
            </Box>
          </ScrollBar>

          <Box className={classes.buttons}>
            <Box className={classes.container} px="2rem">
              {step === 0 && (
                <Button
                  className={classes.cancel}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseBack();
                  }}
                  buttonType="reset"
                >
                  Cancel
                </Button>
              )}

              <Button
                className={classes.previewBtn}
                onClick={(e) => {
                  e.preventDefault();
                  handlePreviewEdit();
                }}
                buttonType="button"
              >
                {step === 0 ? t('Preview') : t('Edit')}
              </Button>

              <Button
                variant="contained"
                className={classes.assignButton}
                buttonType="submit"
                disabled={!isValid || loading || loadingAddAppointment}
              >
                {loading || loadingAddAppointment ? (
                  <ProgressLoader width={20} height={20} />
                ) : (
                  t('send')
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
