import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Button from 'components/ui/Button';
import { useStyles } from './Payouts';
import { FormProvider } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import EmptyState from 'pages/DashboardPage/EmptyState';

interface IProps {
  labelText: string;
  labelDescription?: string;
  handleClick: () => void;
  methods: any;
  open: boolean;
  handleClose: () => void;
  onSubmit: any;
  modal: React.ReactElement;
  btnLabel: string;
  logo: React.ReactElement;
  modalTitle: string;
  isSlide?: boolean;
  height?: string;
  isForm?: boolean;
  isMargin?: boolean;
  divider?: boolean;
}
const EmptyComponent = ({
  labelText,
  labelDescription,
  handleClick,
  methods,
  open,
  handleClose,
  onSubmit,
  modal,
  btnLabel,
  logo,
  modalTitle,
  isForm = true,
  isSlide,
  height = '40vh',
  isMargin = true,
  divider = false,
}: IProps) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mt={4}
      height={height}
    >
      <Box py="5rem">
        <EmptyState
          icon={logo}
          title={labelText}
          text={labelDescription}
          onClick={handleClick}
          isText
        />
      </Box>
      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal={isForm}
          isRegularModal={!isForm}
          title={modalTitle}
          titlePadding="2rem"
          onSubmit={onSubmit}
          isSlide={isSlide}
          divider={divider}
        >
          <Grid container style={{ marginBottom: isMargin && '5vh' }}>
            {modal}
          </Grid>
        </BasicModal>
      </FormProvider>
    </Box>
  );
};

export default EmptyComponent;
