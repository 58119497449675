import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as PasswordIcon } from 'assets/passcode-lock.svg';
import { Box, Typography, Avatar, Checkbox } from '@material-ui/core';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import TrashIcon from 'assets/TrashIcon';
import { IRole } from './PermissionSettings';
import DropDownSelect from '../DropdownSelect';
import { useForm, Controller } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import { ReactComponent as CloseIconRole } from 'assets/CloseiconRoles.svg';
import useDeleteRole from '@eggmed/graphql-client/operations/staffOperations/useDeleteRole';
import { useSnackbar } from 'hooks/useSnackbar';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  role: IRole;
  teams: {
    firstname: string;
    lastname: string;
    role: string;
    picture: string;
  }[];
  roles: [string];
}

function RemoveExistingRoleModal({
  open,
  onClose,
  role,
  teams,
  roles,
}: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const { handleDeleteRole, loading: loadingDelete } = useDeleteRole();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      roles: teams?.map((el) => el.role),
      allRoles: role?.name,
      type: false,
    },
  });
  React.useEffect(() => {
    if (loading && teams && role) {
      reset({
        roles: teams?.map((el) => el.role),
        allRoles: role?.name,
        type: false,
      });
    }
  }, [loading, teams, reset, role]);
  async function onSubmit(data) {
    let roles = null;
    if (data?.type) {
      roles = teams?.map(() => data.allRoles);
    } else {
      roles = data.roles;
    }
    await handleDeleteRole(role?.name, roles);
    onClose();
    triggerSnack('Role deleted successfully');
  }
  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form className={classes.PasswordModal} onSubmit={handleSubmit(onSubmit)}>
        <CloseIconRole width="49px" height="49px" />
        <Typography className={classes.modalTitle}>
          {`Unable to delete ${role?.name} role`}
        </Typography>
        <Box width="100%" mt="10px" mb="1.5rem">
          <Typography className={classes.modalText}>
            <span style={{ fontWeight: 700 }}>{role?.name} role</span> cannot be
            deleted because it is currently assigned to the following providers.
            Please assign them a new role to continue.
          </Typography>
        </Box>
        <Box
          mt={2}
          mb={4}
          py={1}
          pl={1}
          pr={2}
          style={{
            backgroundColor: '#FEF3F2',
            border: '1px solid #B42318',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '250px',
            overflowY: 'auto',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            py={1}
            borderBottom={'0.5px solid #FECDCA'}
          >
            <Box display="flex" alignItems="center">
              <Controller
                name={'type'}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <Checkbox
                      size="medium"
                      checked={value}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      checkedIcon={<CheckedIcon width={16} height={16} />}
                      icon={<UncheckedIcon width={16} height={16} />}
                      style={{
                        backgroundColor: 'transparent',
                        marginLeft: '-5px',
                      }}
                    />
                  );
                }}
              />
              <Typography
                style={{ color: '#912018', fontWeight: 700, fontSize: '14px' }}
              >
                Assign a new role to all
              </Typography>
            </Box>
            <Box width="158px">
              <Controller
                name={`allRoles`}
                control={control}
                render={({ value, onChange }) => (
                  <DropDownSelect
                    defaultMenuName={value}
                    height="33px"
                    setValue={(selectedRole) => {
                      setLoading(false);
                      onChange(selectedRole);
                    }}
                    options={
                      roles ? roles?.filter((el) => el !== role?.name) : []
                    }
                    onClickAddOption={() => {
                      // Handle adding a new role here
                    }}
                    addOptionText="Add a new role"
                    hasAddOption={false}
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            style={{
              cursor: watch()?.type && 'not-allowed',
              opacity: watch()?.type ? 0.5 : 1,
            }}
          >
            {teams?.map((team, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={1}
                borderBottom={
                  index !== teams?.length - 1 && '0.5px solid #FECDCA'
                }
              >
                <Box display="flex" alignItems="cemter">
                  <Avatar
                    src={team.picture}
                    style={{
                      width: 24,
                      height: 24,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 12,
                      textTransform: 'capitalize',
                    }}
                  >
                    {team?.firstname && team?.firstname[0]}{' '}
                    {team?.lastname && team?.lastname[0]}
                  </Avatar>
                  <Typography
                    style={{
                      marginLeft: '5px',
                      color: '#912018',
                      fontSize: '14px',
                      fontWeight: 500,
                      textTransform: 'capitalize',
                    }}
                  >
                    {team?.firstname} {team?.lastname}
                  </Typography>{' '}
                </Box>
                <Box width="158px">
                  <Controller
                    name={`roles.${index}`}
                    control={control}
                    render={({ value, onChange }) => (
                      <DropDownSelect
                        defaultMenuName={value}
                        height="33px"
                        setValue={(selectedRole) => {
                          setLoading(false);
                          setValue(`roles.${index}`, selectedRole);
                        }}
                        disabled={watch()?.type}
                        options={
                          roles ? roles?.filter((el) => el !== role?.name) : []
                        }
                        onClickAddOption={() => {
                          // Handle adding a new role here
                        }}
                        addOptionText="Add a new role"
                        hasAddOption={false}
                      />
                    )}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.saveButtonRed}
            style={{
              opacity: watch()?.type
                ? watch()?.allRoles === role?.name
                  ? 0.5
                  : 1
                : watch()?.roles?.includes(role?.name)
                ? 0.5
                : 1,
              color: 'white',
            }}
            onClick={handleDeleteRole}
            disabled={
              watch()?.type
                ? watch()?.allRoles === role?.name
                  ? true
                  : false
                : watch()?.roles?.includes(role?.name)
                ? true
                : false
            }
          >
            {loadingDelete ? (
              <ProgressLoader width={18} height={18} />
            ) : (
              'Delete role'
            )}
          </Button>
        </Box>
      </form>
    </BasicModal>
  );
}

export default RemoveExistingRoleModal;
