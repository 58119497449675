import { gql, useMutation } from '@apollo/client';
import { OWNER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/permissionstab/PermissionSettings';
import { USER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

const ADD__ROLE = gql`
  mutation addRoleUser($name: String) {
    addRoleUser(name: $name) {
      name
    }
  }
`;

const useAddRole = () => {
  const [addRoleUser, { loading }] = useMutation(ADD__ROLE);
  async function handleAddRole(name: string) {
    try {
      return await addRoleUser({
        variables: { name },
        refetchQueries: [
          {
            query: OWNER_ROLES,
          },
          { query: USER_ROLES },
          {
            query: TEAM_MEMBERS,
            variables: { role: '', status: '' },
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleAddRole, loading };
};

export { ADD__ROLE };
export default useAddRole;
