import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { gql, useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { box } from 'tweetnacl';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Typography } from '@material-ui/core';
import { log, config } from 'config';
import { decrypt } from 'utils/tweetnacl';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useDoctorWaitingRoomActions from 'pages/DoctorMeetingWaitingPage/state/useDoctorWaitingRoomActions';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import ScrollBar from 'components/ui/ScrollBar';
import Tabs from 'components/ui/Tabs';
import { useProvider as useMeetingProvider } from 'contexts/MeetingContext';
import useStyles from './styles';
import { keyPairs } from '../../graphql/index';
import DoctorHeader from './DoctorHeader';
import { useProvider } from './state/Provider';
import DataLoader from 'components/ui/DataLoader';
import { getHourAndMinutesFromDate } from 'utils/dateUtils';
import Dayjs from 'dayjs';
import { ReactComponent as CalendarIcon } from 'assets/session/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/session/clock-session.svg';
import { ReactComponent as OnlineIcon } from 'assets/session/user.svg';
import { ReactComponent as FeeIcon } from 'assets/session/fee.svg';

import { ReactComponent as UsdIcon } from 'assets/currency--dollar.svg';
import NotesTab from './tabs/NotesTab';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
import SchedulePageProvider, {
  useProvider as useSchedule,
} from 'pages/SchedulePage/state/SchedulePageProvider';
import { useHistory } from 'react-router-dom';
import { GET_DOCTOR_APPOINTMENTS } from '@eggmed/graphql-client/queries/appointment';
import { ReactComponent as MoreIcon } from 'assets/loadmore.svg';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import Button from 'components/ui/Button';
import { ReactComponent as SchedulePlus } from 'assets/SchedulePlus.svg';
import { ReactComponent as OnlineBox } from 'assets/OnlineBox.svg';
import { ReactComponent as InPersonBox } from 'assets/SchedulePlus.svg';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import { ReactComponent as SummaryApp } from 'assets/SummaryAppt.svg';
import useGenerateAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateAppointment';
import BasicModal from 'components/ui/Modal';
import EditButtons from 'components/EditButtons';
import Radio from '@material-ui/core/Radio';
import { useProvider as useMeeting } from 'contexts/MeetingContext';
import Input from 'components/ui/Inputs';
import { useForm } from 'react-hook-form';
import { InputAdornment } from '@material-ui/core';
import useWaivePayment from '@eggmed/graphql-client/operations/appointmentsOperations/useWaivePayment';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { TranslationKeys } from 'shared/interfaces/i18';
import useGetInvoiceReference from '@eggmed/graphql-client/operations/appointmentsOperations/useGetInvoiceReference';
import useCreateInvoice from '@eggmed/graphql-client/operations/appointmentsOperations/useCreateInvoice';
import useListenVideoRoomChange from '@eggmed/graphql-client/operations/videoRoomOperations/useListenToVideoRoomMembersChanges';
import useEditSession from '@eggmed/graphql-client/operations/appointmentsOperations/useEditSession';
import { ReactComponent as ArrowBackIcon } from 'assets/session/arrow-back.svg';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import FreeTag from 'components/ui/FreeTag';

export enum templateType {
  note = 'note',
  form = 'form',
}
export enum templateFormType {
  noteTaking = 'note-taking',
}
interface ISizeState {
  width: number | string;
  height: number | string;
  x: number;
  y: number;
}

const GET_VIDEO_MEMBERS_QUERY = gql`
  query getVideoRoomMembers($roomId: String) {
    videoRoomMembers(roomId: $roomId)
  }
`;

export default function AppointmentPage(): JSX.Element {
  const { t } = useTranslation();
  const { appointmentId, patientId, loading, data, error } = useProvider();
  const history = useHistory();
  const {
    setEncryptionKey,
    setCallAvailable,
    handleJoinVideoRoomWithPatient,
    joiningVideoRoomLoading,
    setIsMobile,
  } = useMeetingProvider();
  const { endCallAction } = useMeeting();
  const { handleOpenAddModal, setPatientAdded, loadingEditAppointment } =
    useSchedule();
  const { register, control, handleSubmit } = useForm();
  const { state: routeState } = useLocation<any>();
  const [patient, setPatient] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { handleGenerate } = useGenerateAppointment();
  const { waivePayment } = useWaivePayment();

  useEffect(() => {
    if (isLoading) {
      setIsLoading(loading);
    }
  }, [loading]);
  useEffect(() => {
    if (data?.patient) {
      setPatient(data?.patient);
    }
  }, [data?.patient]);
  const count = 5;
  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(count);
  const { data: doctorAppointments, loading: loadingDoctorAppointments } =
    useQuery(GET_DOCTOR_APPOINTMENTS, {
      variables: {
        patientId,
        limit,
        start,
      },
    });
  const { data: templates, refetch } = useQuery(DOCTOR_TEMPLATES, {
    variables: {
      type: templateType.note,
      formType: templateFormType.noteTaking,
      page: 1,
      limit: 10,
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  const { appointments: pastConsultations, count: consultationCount } =
    doctorAppointments?.doctorAppointments || {};
  const [constultationState, setConsultationState] = useState([]);
  useEffect(() => {
    if (pastConsultations) {
      setConsultationState(pastConsultations);
    }
  }, [pastConsultations]);
  const {
    doctor: { _id: doctorId, username },
  } = useAuth();
  const {
    appointment,
    loading: loadingQuery,
    refetch: refetchQuery,
  } = useMeetingProvider();

  const { roomId } = useDoctorWaitingRoomActions({
    doctorId,
    roomSlug: username,
    handleSetWaitingMembers: (patients, isMobile) => {
      setIsMobile(isMobile);
    },
  });
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Appointments' });
  }, [headerTitleDispatcher]);
  const classes = useStyles({});

  const { data: videoRoomMembers, refetch: refetchRoomMembers } = useQuery(
    GET_VIDEO_MEMBERS_QUERY,
    {
      variables: { roomId },
      fetchPolicy: 'no-cache',
    }
  );

  const patientInCall = useMemo(
    () =>
      videoRoomMembers?.videoRoomMembers &&
      videoRoomMembers?.videoRoomMembers.length > 0 &&
      videoRoomMembers?.videoRoomMembers.some((member) => {
        const patientUser = member.split(':')[0];
        return patientUser === appointment?.patient?.user;
      }),
    [videoRoomMembers?.videoRoomMembers, appointment?.patient?.user]
  );

  useListenVideoRoomChange({
    roomId,
    handleAction: () => {
      refetchRoomMembers({
        roomId,
      });
    },
  });

  const joinWithPatientAction = useCallback(async () => {
    const result = await handleJoinVideoRoomWithPatient(
      patientId,
      appointmentId,
      (e: any) => log('error', e)
    );
    const encrypted = (result as any)?.data?.joinVideoRoomWithPatient;
    const serverEncryptionKey = localStorage.getItem('serverPublicKey');
    const sharedB = box.before(
      new Uint8Array(Object.values(JSON.parse(serverEncryptionKey || ''))),
      keyPairs.secretKey
    );

    const decrypted = decrypt(sharedB, encrypted);
    setEncryptionKey(decrypted);
    setCallAvailable(true);
  }, [handleJoinVideoRoomWithPatient, patientId, doctorId]);
  React.useEffect(() => {
    if (!appointment?.appointment?.isEnded) {
      setCallAvailable(true);
    }
  }, [appointment?.appointment?.isEnded]);
  useEffect(() => {
    if (routeState && routeState.isStarting) {
      joinWithPatientAction();
    }
  }, [routeState]);
  const [open, setOpen] = React.useState(false);
  const { data: session } = useQuery(SELECTED_RATE, {
    variables: {
      doctor: doctorId,
    },
  });
  const { selectedRate } = session || {};
  const onlineSessions = selectedRate?.concat([
    {
      session: t('Waive payment'),
      rate: t('Free'),
      currency: 'USD',
      duration: undefined,
    },
  ]);
  const inPersonSessions = selectedRate?.concat([
    {
      session: t('Cash'),
      rate: '',
      currency: 'USD',
      duration: undefined,
    },
    {
      session: t('Waive payment'),
      rate: t('Free'),
      currency: 'USD',
      duration: undefined,
    },
  ]);

  const AllDefinedSession = inPersonSessions;
  const [checked, setChecked] = React.useState<{
    session: string;
    rate: string;
    currency: string;
    duration: string;
  }>(null);
  React.useEffect(() => {
    if (appointment?.appointment?.sessionType?._id) {
      setChecked(appointment?.appointment?.sessionType);
    }
  }, [appointment?.appointment?.sessionType?._id]);
  function handleChange(rate) {
    setChecked(rate);
  }
  const { updateSession, loading: loadingMutation } = useEditSession();
  async function handleEndCall(data) {
    let option = {
      isPaid: undefined,
    };
    const { startDate, endDate, sessionType } = appointment?.appointment || {};
    const session = {
      session: data?.cash ? sessionType?.session : checked?.session,
      currency: data?.cash ? sessionType?.currency : checked?.currency,
      duration: data?.cash
        ? sessionType?.duration
        : checked?.duration.toString(),
      rate: data?.cash ?? checked?.rate.toString(),
    };
    if (data?.cash) {
      option = {
        isPaid: 'paid',
      };
    }
    await updateSession(appointmentId, {
      sessionType: session,
      isEnded: true,
      endSessionDate: new Date(),
      ...option,
    });
    refetchQuery();
    endCallAction();
    setOpen(false);
    localStorage.removeItem('timer');
    localStorage.removeItem(`Appointment_${appointment?.appointment?._id}`);
  }
  const { data: invoiceRef } = useGetInvoiceReference();
  const { createInvoice } = useCreateInvoice({});
  const { clearBlock } = useEditorContext();
  const handleCreateInvoice = async () => {
    const sessionData = appointment?.appointment;
    const { rate, duration, _id, __typename, tags, ...rest } =
      appointment?.appointment?.sessionType || {};

    const invoiceData = {
      doctor: sessionData?.doctor?._id,
      patient: sessionData?.patient?._id,
      doctorInfo: {
        firstname: sessionData?.doctor?.firstname,
        lastname: sessionData?.doctor?.lastname,
        phone: sessionData?.doctor?.phone,
        address: sessionData?.doctor?.address,
        suite: sessionData?.doctor?.suite,
        city: sessionData?.doctor?.city,
        zipcode: sessionData?.doctor?.zipcode,
        country: sessionData?.doctor?.country,
        state: sessionData?.doctor?.state,
      },
      patientInfo: {
        firstname: sessionData?.patient?.firstname,
        lastname: sessionData?.patient?.lastname,
        phone: sessionData?.patient?.phone,
        address: sessionData?.patient?.address,
        suite: sessionData?.patient?.suite,
        city: sessionData?.patient?.city,
        zipcode: sessionData?.patient?.zipcode,
        country: sessionData?.patient?.country,
        state: sessionData?.patient?.state,
      },
      appointment: sessionData?._id,
      session: {
        rate: rate?.toString(),
        duration: duration?.toString(),
        ...rest,
      },
      amount: parseInt(rate?.toString()),
      amountPaid:
        sessionData?.isPaid === 'paid' && sessionData?.isPrepay
          ? parseInt(rate?.toString())
          : 0,
      issueDate: new Date(sessionData?.startDate),
      dueDate: new Date(
        new Date(sessionData?.startDate).setDate(
          new Date(sessionData?.startDate).getDate() + 30
        )
      ),
      invoiceRef: invoiceRef?.getInvoiceReference,
    };
    await createInvoice({
      variables: { invoiceInput: invoiceData },
    });
  };
  async function onSubmit(data) {
    if (checked?.session === 'Waive payment') {
      const { startDate, endDate } = appointment?.appointment || {};

      await waivePayment({
        variables: {
          appointmentId: appointmentId,
        },
      });
      await updateSession(appointmentId, {
        isEnded: true,
        endSessionDate: new Date(),
      });
      refetchQuery();
      endCallAction();
      setOpen(false);
      localStorage.removeItem('timer');
      localStorage.removeItem(`Appointment_${appointment?.appointment?._id}`);
    } else {
      handleEndCall(data);
    }
    await handleCreateInvoice();
  }

  return (
    <DataLoader error={error} data={data}>
      <Box>
        <Grid container spacing={4}>
          <Grid
            className={clsx(classes.cardMaxHeight, classes.overflowHidden)}
            item
            xl={3}
            lg={4}
            md={12}
            sm={12}
            xs={12}
          >
            <Link
              to={`/doctor/patient/${patientId}?tab=2`}
              className={classes.backToSessions}
            >
              <ArrowBackIcon />
              <Typography className={classes.backToSessionsText}>
                {t('backToSessions')}
              </Typography>
            </Link>
            <SchedulePageProvider>
              <InboxPageProvider>
                <DoctorHeader
                  doctorId={doctorId}
                  patientId={patientId}
                  handleAction={joinWithPatientAction}
                  className={classes.doctorHeader}
                  alignItems="center"
                  isLoading={isLoading}
                  loading={loading}
                  appointment={appointment?.appointment}
                  actionLoading={joiningVideoRoomLoading}
                  currentTime={getHourAndMinutesFromDate(
                    appointment?.appointment?.startDate
                  )}
                  active={patientInCall && !joiningVideoRoomLoading}
                  callIsRunning={false}
                  setOpen={setOpen}
                  {...patient}
                  refetchQuery={refetchQuery}
                  handleCreateInvoice={handleCreateInvoice}
                />
              </InboxPageProvider>
            </SchedulePageProvider>
            <Box className={classes.consultationHistory} px={2} py={3}>
              <Box className={classes.historyTitleBox} mb={2}>
                <Text i18nKey="sessionHistory" className={classes.historyTitle}>
                  Session history
                </Text>
                <Button
                  className={classes.schedule}
                  onClick={() => {
                    setPatientAdded(data?.patient);
                    handleOpenAddModal();
                  }}
                >
                  <SchedulePlus style={{ marginRight: '5px' }} />
                  <Text i18nKey="scheduleSession">Schedule session</Text>
                </Button>
              </Box>
              <ScrollBar
                style={{
                  minHeight: '60vh',
                  maxHeight: '60vh',
                  width: '100%',
                  overflowY: 'auto',
                }}
              >
                <Box className={classes.historyTableBox} mt={1}>
                  {constultationState &&
                    constultationState?.map((e) => (
                      <Box>
                        <Box
                          className={classes.consultation}
                          onClick={() => {
                            if (appointmentId !== e?._id) {
                              history.push(
                                `/doctor/patient/${patientId}?event=${e?._id}&tab=2`
                              );
                              clearBlock();
                            }
                          }}
                        >
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            style={{ gap: '8px' }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              gridGap="5px"
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                gridGap="0.5rem"
                              >
                                <CalendarIcon />
                                <Typography
                                  className={classes.consultationDate}
                                >
                                  {Dayjs(e?.startDate).format('MMM DD, YYYY')}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                alignItems="center"
                                gridGap="0.5rem"
                              >
                                <ClockIcon />
                                <Typography
                                  className={classes.consultationDate}
                                >
                                  {Dayjs(e?.startDate)
                                    .tz(e?.doctor?.timeZone)
                                    .format('h:mm A')}{' '}
                                  {'-'}
                                  {Dayjs(e?.endDate)
                                    .tz(e?.doctor?.timeZone)
                                    .format('h:mm A')}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gridGap="0.5rem"
                                >
                                  <OnlineIcon />
                                  <Typography
                                    className={classes.consultationType}
                                  >
                                    {e?.locationLink
                                      ? t('Online')
                                      : t('In-person')}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gridGap="0.3rem"
                                  flexWrap="no-wrap"
                                  ml={1.5}
                                >
                                  <UsdIcon />
                                  {!(parseInt(e?.sessionType?.rate) === 0) && (
                                    <Typography
                                      className={classes.consultationType}
                                    >
                                      {e?.sessionType?.rate}{' '}
                                      {e?.sessionType?.currency}
                                    </Typography>
                                  )}
                                </Box>
                                {parseInt(e?.sessionType?.rate) === 0 ? (
                                  <FreeTag />
                                ) : (
                                  <Box
                                    style={{
                                      borderRadius: '16px',
                                      background:
                                        chipColor[e?.isPaid]?.background,
                                      border: chipColor[e?.isPaid]?.border,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '4px 8px',
                                    }}
                                    ml={1}
                                  >
                                    <Typography
                                      style={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: chipColor[e?.isPaid]?.color,
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      {t(`${e?.isPaid as TranslationKeys}`)}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleGenerate(e?._id);
                            }}
                            className={classes.summary}
                          >
                            <SummaryApp
                              style={{
                                marginRight: '5px',
                              }}
                            />
                            <Text
                              i18nKey="summary"
                              className={classes.summaryAppt}
                            >
                              Summary
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          width="100%"
                          paddingX="2rem"
                          boxSizing="border-box"
                        >
                          <Divider style={{ width: '100%' }} />
                        </Box>
                      </Box>
                    ))}
                  {consultationCount > count &&
                    consultationCount > limit &&
                    constultationState.length > 0 && (
                      <Box
                        className={classes.consultation}
                        onClick={() => setLimit((limit) => limit + count)}
                      >
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          style={{ gap: '8px' }}
                        >
                          <MoreIcon />
                          <Text
                            i18nKey="seeMore"
                            className={classes.consultationDate}
                          >
                            See more
                          </Text>
                        </Box>
                      </Box>
                    )}
                </Box>
              </ScrollBar>
            </Box>
          </Grid>
          <Grid
            className={clsx(classes.cardMaxHeight, classes.overflowHidden)}
            item
            xl={9}
            lg={8}
            md={12}
            sm={12}
            xs={12}
          >
            <Box className={classes.rightSection} px={2} py={3}>
              <NotesTab
                patientId={patientId}
                appointmentId={appointment?.appointment?._id}
                doctorTemplates={templates?.doctorTemplateByTypeForm?.result}
                notes={appointment?.notesByAppointment}
                loadingQuery={loadingQuery}
              />
            </Box>
          </Grid>
        </Grid>
        <BasicModal
          onClose={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          open={open}
          isRegularModal
          isSlide
          divider
          title={t('Complete your session')}
          titlePadding="2.5rem"
          titleClass={classes.titleModal}
        >
          <Box height="100vh">
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                flex: 1,
                height: 'calc(100vh - 50px)',
                overflow: 'scroll',
              }}
            >
              <Box className={classes.modalPay}>
                <Box display="flex" style={{ gap: '8px' }} py={2}>
                  <FeeIcon />
                  <Text
                    i18nKey="Please confirm the applicable fees to finalize the session"
                    className={classes.sectiontitle}
                  >
                    Please confirm the applicable fees to finalize the session
                  </Text>
                </Box>
                <Box mt={2}>
                  {AllDefinedSession?.map((rate) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom={
                        rate?.session === checked?.session
                          ? '1px solid #0265DC'
                          : '1px solid #EAECF0'
                      }
                      borderTop={
                        rate?.session === checked?.session
                          ? '1px solid #0265DC'
                          : '1px solid #EAECF0'
                      }
                      style={{
                        backgroundColor:
                          rate?.session === checked?.session && '#96CEFD',
                      }}
                      className={classes.linePayment}
                      py={2}
                      pr={1}
                      onClick={() => handleChange(rate) as any}
                    >
                      <Box display="flex" alignItems="center">
                        <Radio
                          className={classes.rootRadio}
                          color="default"
                          disableRipple
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.iconRadio,
                                classes.checkedIconRadio
                              )}
                            />
                          }
                          icon={<span className={classes.iconRadio} />}
                          checked={rate?.session === checked?.session}
                          onChange={() => handleChange(rate) as any}
                          value="event"
                          data-cy="male-input"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                        <Typography
                          style={{
                            fontWeight:
                              rate?.session === checked?.session && 700,
                          }}
                        >
                          {rate?.duration} {rate?.duration && 'min'}{' '}
                          {t(`${rate?.session as TranslationKeys}`)}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        {rate?.session === 'Cash' ? (
                          rate?.session === checked?.session ? (
                            <Input
                              inputRef={register}
                              control={control}
                              name="cash"
                              variant="filled"
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography>USD</Typography>
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="end">
                                    <Typography>$</Typography>
                                  </InputAdornment>
                                ),
                              }}
                              style={{
                                width: '170px',
                                fontWeight:
                                  rate?.session === checked?.session && 700,
                              }}
                            />
                          ) : null
                        ) : (
                          <Typography
                            style={{
                              fontWeight:
                                rate?.session === checked?.session ||
                                rate?.rate === 'Free'
                                  ? 700
                                  : 400,
                            }}
                          >
                            {rate?.duration && '$'}
                            {rate?.rate}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box>
                <Divider />
                <Box
                  height="76px"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  borderTop="1px solid #EAECF0"
                  style={{
                    borderBottomLeftRadius: '16px',
                    borderBottomRightRadius: '16px',
                    marginRight: '3.5rem',
                    boxSizing: 'border-box',
                  }}
                >
                  <EditButtons
                    type="submit"
                    submitText="Finish"
                    submitWidth="3vw"
                    handleOpen={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                    isSlide
                    editable
                    loading={loadingEditAppointment || loadingMutation}
                    // handleEdit={(e) => {
                    //   handleEndCall();
                    // }}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </BasicModal>
      </Box>
    </DataLoader>
  );
}
export const SELECTED_RATE = gql`
  query selectedRate($doctor: ID) {
    selectedRate(doctor: $doctor) {
      _id
      session
      duration
      rate
      currency
    }
  }
`;

export const GET_PATIENT = gql`
  query PatientAppointmentData($patientId: ID!, $appointmentId: ID!) {
    patient(patientId: $patientId) {
      _id
      completed
      bloodType
      birthday
      picture
      firstname
      middlename
      lastname
      user
      email
      phone
    }

    notesByAppointment(appointmentId: $appointmentId) {
      _id
      title
      blocks
      isSaved
      isSigned
    }
    appointment(appointmentId: $appointmentId) {
      isPaid
      startDate
      _id
      description
      numberOfRepeat
      repeatEvery
      endOn
      repeatOn
      paymentIntentId
      patient {
        _id
        firstname
        middlename
        lastname
        picture
        email
        phone
        address
        suite
        city
        zipcode
        country
        state
      }
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
        email
        phone
        timeZone
        address
        suite
        city
        zipcode
        country
        state
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        tag
        color
      }
      members
      patientGoing
      doctorGoing
      invitation {
        invitation_id
      }
      sessionType {
        _id
        session
        duration
        rate
        currency
        location
        locationPlace
        externalUrl
        locationId {
          _id
          name
        }
      }
      isPrepay
      isEnded
      isStarted
    }
  }
`;
