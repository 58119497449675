import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as AddIcon } from 'assets/plus-square.svg';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useForm } from 'react-hook-form';
import useAddRole from '@eggmed/graphql-client/operations/staffOperations/useAddRole';
import { useSnackbar } from 'hooks/useSnackbar';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: () => void;
}
const schema = yup.object().shape({
  role: yup.string().required('Role is a required field'),
});
function AddRoleModal({ open, onClose }: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const { control, register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const common = { control, register, errors };
  const { handleAddRole, loading } = useAddRole();
  const [error, setError] = React.useState('');
  React.useEffect(() => {
    if (open) {
      setError('');
    }
  }, [open]);
  async function onSubmit(data) {
    const result = await handleAddRole(data?.role);
    if (result instanceof Error) {
      setError(result?.message);
      return;
    }
    setError('');
    onClose();
    triggerSnack('Role added successfully');
  }
  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form className={classes.PasswordModal} onSubmit={handleSubmit(onSubmit)}>
        <AddIcon />
        <Typography className={classes.modalTitle}>Add a new role</Typography>
        <Typography className={classes.modalText}>
          Enter a name for this role.
        </Typography>
        <Box width="100%" my="1.5rem">
          <InputBlock
            label=""
            type="text"
            placeholder={`Role name`}
            name="role"
            {...common}
          />
          {error && (
            <Typography style={{ color: 'red', marginTop: '4px' }}>
              {error}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButton}>
            {loading ? <ProgressLoader width={18} height={18} /> : 'Add'}
          </Button>
        </Box>
      </form>
    </BasicModal>
  );
}

export default AddRoleModal;
