import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Typography,
  BoxProps,
  makeStyles,
} from '@material-ui/core';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIcon.svg';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';

import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  deleteModal: {
    [theme.breakpoints.only('xl')]: {
      width: '25vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '30vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '50vw',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
  },
  deleteButton: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    minWidth: '80px',
    minHeight: '40px',
    width: '50%',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#C81C10',
      boxShadow: 'unset',
    },
  },
  deleteButtonArchive: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    minWidth: '80px',
    minHeight: '40px',
    width: '50%',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: '#0265DC',
      boxShadow: 'unset',
    },
  },
  cancelButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 600,
    marginRight: theme.spacing(2),
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid #D0D5DD',
    color: '#344054',
    backgroundColor: 'transparent',
    width: '50%',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #D0D5DD',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#182230',
    marginTop: '20px',
  },
  description: {
    fontFamilty: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#667085',
  },
  taskName: {
    color: '#667085',
    fontFamily: 'Inter',
    fontWeight: 700,
    // marginLeft: '10px',
    fontSize: '14px',
    lineHeight: '22px',
  },
}));
interface IDeleteModalContentProps extends BoxProps {
  handleDelete: () => void;
  handleClose: () => void;
  text?: string;
  isPatient?: boolean;
  message?: string;
  loading?: boolean;
  archive?: boolean;
  isArchived?: boolean;
  name?: string;
}
export default function DeleteModalContent({
  handleDelete,
  handleClose,
  text,
  isPatient = false,
  message = 'remove',
  loading = false,
  name,
  archive = false,
  isArchived = false,
  ...props
}: IDeleteModalContentProps): ReactElement {
  const classes = useStyles({ isPatient });
  const { t } = useTranslation();
  return (
    <Box className={classes.deleteModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        style={{
          padding: '0px 2rem 2rem 2rem',
          boxSizing: 'border-box',
        }}
      >
        {!archive ? <DeleteIcon /> : <ArchiveIcon />}
        <Typography className={classes.title}>
          {!archive
            ? `${t('delete')} ${t(text)}`
            : isArchived
            ? 'Unarchive this conversation?'
            : 'Archive this conversation?'}
        </Typography>
        {!archive ? (
          <Typography className={classes.description}>
            Are you sure you want to delete{' '}
            <span className={classes.taskName}>{name}</span>? This action cannot
            be undone.
          </Typography>
        ) : isArchived ? (
          <Typography className={classes.description}>
            Once unarchived, you can find it under the active tab.
          </Typography>
        ) : (
          <Typography className={classes.description}>
            Once archived, you can find it under the Archived tab.
          </Typography>
        )}

        <Box
          height="8vh"
          display="flex"
          alignItems="center"
          width="100%"
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            paddingTop: '20px',
          }}
        >
          <Box className={classes.buttonsContainer}>
            <Button className={classes.cancelButton} onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button
              className={
                !archive ? classes.deleteButton : classes.deleteButtonArchive
              }
              onClick={handleDelete}
              disabled={loading}
            >
              {loading ? (
                <ProgressLoader width={20} height={20} />
              ) : !archive ? (
                t('Delete')
              ) : isArchived ? (
                'Unarchive'
              ) : (
                'Archive'
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
