import { Box, BoxProps, makeStyles, Avatar } from '@material-ui/core';
import { Option } from 'components/ui/Inputs/AutoCompleteAdd';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';
import React, { useState } from 'react';

interface SearchBarProps extends BoxProps {
  options: Option[];
  filterOptions?: (
    options: Option[],
    params: { inputValue: string; getOptionLabel: (option: Option) => string }
  ) => Option[];
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  setValue: (value: string) => void;
  handleChangePage: (e, page: number) => void;
  placeholder?: string;
}

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 400,
    fontSize: '15px',
    color: 'dark',
  },
  wrapper: {
    '& > .MuiAvatar-root': {
      width: 40,
      height: 40,
      margin: 0,
    },
  },
  searchBar: {
    display: 'flex',
    transition: 'width 0.3s ease',
    width: '280px',
  },

  input: {
    width: '100%',
    '& .MuiFilledInput-root': {
      border: '1px solid #D0D5DD',
      backgroundColor: '#F7F7F7',
      borderRadius: '8px',
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px',
      },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },
    '& .Mui-focused': {
      border: '1px solid #082CAF',
      borderRadius: '8px',
    },
    '& .Mui-error': {
      border: '1px solid red',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
      {
        padding: '8px 8px 16px 12px',
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '8px',
    },
    '& .MuiFilledInput-input': {
      padding: '12px',
      borderRadius: 'inherit',
    },
    '& .MuiFilledInput-underline:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #3f50b5',
    },
    '& .MuiFilledInput-underline:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '0px solid #3f50b5',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(0),
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

export const SearchClients = ({
  options,
  filterOptions,
  setSearchQuery,
  setValue,
  value,
  handleChangePage,
  placeholder = 'Search clients',
  ...props
}: SearchBarProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue === '') {
      setValue('');
      setSearchQuery('');
    }
  };
  return (
    <Box display="flex" className={classes.searchBar} flexDirection="column">
      <AutoCompleteAddNew
        noOptionsText="No matching clients"
        data-cy="auto-complete-add"
        options={options}
        inputValue={inputValue}
        filterOptions={filterOptions}
        renderOption={(option) => {
          const { name } = option;
          return (
            <Box
              display="flex"
              alignItems="center"
              gridGap="1rem"
              overflow="hidden"
              className={classes.wrapper}
            >
              <Avatar src={option.picture}>{option.name[0]}</Avatar>
              <Box>{name}</Box>
            </Box>
          );
        }}
        getOptionSelected={(option: any, value: any) => {
          return option?._id === value?._id;
        }}
        onInputChange={handleInputChange}
        defaultValue={value}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue?.name);
          setSearchQuery(newValue?._id);
          handleChangePage(null, 1);
        }}
        getOptionLabel={(option: any) => option?.name || value}
        {...props}
      />
    </Box>
  );
};
