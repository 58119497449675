import React from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  Divider,
} from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { dayNames } from 'pages/DashboardPage/DashboardPage';
import dayjs from 'dayjs';
import EggmedPagination from 'components/ui/Pagination';
import Skeleton from 'pages/PatientGeneralInfo/../../components/ui/Skeleton';

import {
  getNotificationFeed,
  getNotificationFeedMessageDoctor,
  splitText,
} from './utils';
import ActivityTypeContent from './ActivityTypeContent';
import BasicModal from 'components/ui/Modal';
import ActivityModal from 'pages/PatientActivities/commun/ActivityModal';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import AddEventPage from 'pages/SchedulePage/AddEventPage/index';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import { useProvider as useTaskPageProvider } from 'pages/Taskpage/state/Provider';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyFeed } from 'assets/emptyState/feed.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import ResultModal from 'pages/PatientActivities/commun/ResultModal';
import {
  emojis,
  handleEmoji,
  typeMapping,
} from 'pages/PatientActivities/RecentActivityCard';
import useHandlePatientActivities from 'pages/PatientActivities/commun/useHandlePatientActivities';
import { useHistory } from 'react-router-dom';
import TaskPreview from 'pages/Taskpage/TaskPreview';

export enum TypeEnum {
  MOOD = 'Mood',
  SLEEP = 'Sleep',
  REFLECTION = 'reflection',
}
export const useStyles = makeStyles((theme) => ({
  container: {},
  notes: {
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#475467',
  },
  title: {
    fontSize: '30px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#101828',
  },
  question: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#475467',
    fontFamily: 'Inter',
  },
  response: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#344054',
    fontFamily: 'Inter',
    marginLeft: '15px',
  },
  responseBox: {
    backgroundColor: 'white',
    borderRadius: '50px',
    border: '1px solid #EAECF0',
    padding: '6px 20px 6px 6px ',
  },
  desriptionBox: {
    padding: '10px 20px',
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
  },
  description: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#475467',
  },
  feed: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    padding: '15px',
  },
  divider: {
    flex: 1,
  },
}));
export const Feed = ({
  patientId,
  doctorUrl,
  isPatient = false,
}: {
  patientId: string;
  doctorUrl?: string;
  isPatient?: boolean;
}) => {
  const { handleViewEventFeed } = useProvider();
  const {
    modalIsOpen,
    closeModal,
    handleViewTask,
    selectedTask,
    handleOpenEditTask,
    handleOpenDeleteModal,
    handleDeleteTaskAttachment,
    isAppt,
    dataList,
  } = useTaskPageProvider();
  const count = 10;

  const taskList = isAppt ? dataList?.tasksListPatient : dataList?.tasksList;
  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(count);
  const [page, setPage] = React.useState(1);
  const { doctor } = useAuth();
  const history = useHistory();
  function getList(id: string, data) {
    const getList = data?.find((el) => {
      return el?.tasks?.map((tasks) => tasks._id).includes(id);
    });
    return getList;
  }
  const { data, loading } = useQuery(NOTIFICATION_DOCTOR_PATIENT, {
    variables: {
      start,
      limit,
      doctor: doctorUrl ? doctorUrl : doctor?._id,
      patient: patientId,
    },
  });
  React.useEffect(() => {
    setPage(1);
    setStart(0);
    setLimit(count);
  }, []);
  const handleClick = (_id) => {
    window.open(`/doctor/inbox?messageId=${_id}`, '_blank');
  };
  const feedData = data?.notificationDoctorPatient?.notifications;
  const counting = data?.notificationDoctorPatient?.count;

  function mappedData() {
    const data = {};

    feedData?.forEach((item) => {
      const dayOfWeek = new Date(item?.date).getDay();
      const dayName = dayNames[dayOfWeek];

      if (!data[dayName]) {
        data[dayName] = [];
      }

      data[dayName].push(item);
    });
    // Sort the days based on the earliest appointment date within each day
    const sortedData = {};
    Object.keys(data)
      .sort((a, b) => {
        const earliestDateA =
          data[a].length > 0 ? new Date(data[a][0].startDate) : null;
        const earliestDateB =
          data[b].length > 0 ? new Date(data[b][0].startDate) : null;
        return (earliestDateA as any) - (earliestDateB as any);
      })
      .forEach((dayName) => {
        sortedData[dayName] = data[dayName];
      });

    return sortedData;
  }
  const dataFeed = mappedData();
  const upcomingKeys = Object.keys(dataFeed);
  const upcomingValues = Object.values(dataFeed) as any[];
  const classes = useStyles();
  function handlePagination(e: unknown, page: number) {
    setPage(page);
    setStart(count * (page - 1));
    setLimit(page * count);
  }
  const { data: PatientData } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });
  const [open, setOpen] = React.useState(false);
  const [defaultValues, setDefault] = React.useState(null);
  const types = {
    sleepCheckin: TypeEnum.SLEEP,
    moodCheckin: TypeEnum.MOOD,
    reflection: TypeEnum.REFLECTION,
  };
  const { handleBookMark } = useHandlePatientActivities({
    defaultValues,
    type: types[defaultValues?.type],
    doctorAndPatientId: { doctor: doctor?._id, patient: patientId },
    handleClose: () => {},
  });
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Skeleton loading={loading}>
        <Text i18nKey="feed" className={classes.title}>
          Feed
        </Text>
      </Skeleton>
      <Skeleton loading={loading}>
        <Text
          i18nKey="Stay in the loop with all the latest updates related to your"
          className={classes.description}
        >
          Stay in the loop with all the latest updates related to your{' '}
          {doctorUrl ? 'doctor' : 'client'}
        </Text>
      </Skeleton>
      <Box className={classes.feed} mt={2}>
        {!loading && upcomingKeys?.length === 0 && (
          <Box
            minHeight="40vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <EmptyState
              icon={<EmptyFeed />}
              title={t('No activities yet')}
              hasAction={false}
            />
          </Box>
        )}
        {upcomingKeys?.map((element, index) => (
          <Box>
            <Box display="flex" alignItems="center">
              <Skeleton loading={loading}>
                <Divider className={classes.divider} />
                <Typography
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#101828',
                    textAlign: 'center',
                    backgroundColor: '#EAECF0',
                    padding: '10px',
                    borderRadius: '8px',
                    border: '1px solid #F2F4F7',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginInline: '10px',
                  }}
                >
                  {element === dayNames[new Date().getDay()]
                    ? t('Today')
                    : dayjs(upcomingValues[index][0]?.date).format('D MMM')}
                </Typography>{' '}
                <Divider className={classes.divider} />
              </Skeleton>
            </Box>
            {upcomingValues[index]?.map((el, key) => (
              <Box
                py={2}
                borderBottom={
                  key !== upcomingValues[index]?.length - 1 &&
                  '1px solid #EAECF0'
                }
              >
                <Box
                  display="flex"
                  alignItems={!el?.description && 'center'}
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems={!el?.description && 'center'}>
                    <Skeleton loading={loading}>
                      <Avatar
                        src={
                          el?.doctorSender
                            ? el?.doctor?.picture
                            : el?.patient?.picture
                        }
                        style={{ fontSize: '14px' }}
                      >
                        {`${
                          el?.patient?.firstname &&
                          el?.patient?.firstname[0].toUpperCase()
                        }${
                          el?.patient?.lastname &&
                          el?.patient?.lastname[0].toUpperCase()
                        }`}
                      </Avatar>
                    </Skeleton>
                    <Box
                      ml={1}
                      display="flex"
                      flexDirection="column"
                      flexGrow={1}
                    >
                      <Skeleton loading={loading}>
                        <Typography
                          style={{
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#101828',
                          }}
                        >
                          {el?.type === 'new_ActivityResponse' ||
                          el?.type === 'update_Appointment' ||
                          el?.type === 'new_Appointment' ||
                          el?.type === 'addTask' ||
                          'createThread' ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: splitText(
                                  el.doctorSender
                                    ? getNotificationFeedMessageDoctor(
                                        el.event,
                                        {
                                          notificationDate: el.notificationDate,
                                          notifOldDate: el.notifOldDate,
                                        }
                                      )
                                    : getNotificationFeed(el.event, {
                                        ...el,
                                      })
                                ),
                              }}
                              onClick={() => {
                                if (el?.type === 'new_ActivityResponse') {
                                  setDefault(el?.eventId);
                                  setOpen(true);
                                }
                                if (
                                  el?.type === 'update_Appointment' ||
                                  el?.type === 'new_Appointment'
                                ) {
                                  handleViewEventFeed(el?.appointmentId);
                                }
                                if (el?.type === 'createThread') {
                                  handleClick(el?.messageId);
                                }
                                if (el?.type === 'addTask' && !isPatient) {
                                  handleViewTask(el?.task);
                                }
                              }}
                            />
                          ) : (
                            <span>
                              {el.doctorSender
                                ? getNotificationFeedMessageDoctor(el.event, {
                                    notificationDate: el.notificationDate,
                                    notifOldDate: el.notifOldDate,
                                  })
                                : getNotificationFeed(el.event, { ...el })}
                            </span>
                          )}
                        </Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                  <Skeleton loading={loading}>
                    <Typography
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#101828',
                      }}
                    >
                      {dayjs(el?.date).format('D MMM | HH:mm')}
                    </Typography>
                  </Skeleton>
                </Box>
                {el?.description && <ActivityTypeContent el={el} />}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      {!loading && (
        <Box display="flex" justifyContent="center" width="100%" my={2}>
          <EggmedPagination
            onChange={handlePagination}
            page={page}
            count={Math.ceil(counting / count)}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
      <BasicModal
        isRegularModal
        isSlide
        open={open}
        onClose={() => setOpen(false)}
        handleClose={() => setOpen(false)}
      >
        <ResultModal
          activities={[{ ...defaultValues }]}
          activityType={types[defaultValues?.type]}
          selectedDay={defaultValues?.frequency?.startDate}
          handleEmoji={handleEmoji}
          emojis={emojis}
          currentRowId={defaultValues?._id}
          onClose={() => setOpen(false)}
          doctorAndPatientId={{ doctor: doctor?._id, patient: patientId }}
          handleBookMark={handleBookMark}
          isFeed
        />
      </BasicModal>
      <BasicModal
        onClose={closeModal}
        open={modalIsOpen}
        isRegularModal
        titlePadding={'0rem'}
        handleClose={closeModal}
        isSlide
        divider
      >
        <TaskPreview
          closeModal={closeModal}
          data-cy="task-view-modal"
          openEditModal={handleOpenEditTask}
          handleDelete={handleOpenDeleteModal}
          getList={getList}
          handleDeleteTaskAttachment={handleDeleteTaskAttachment}
          {...selectedTask!}
          taskList={taskList}
          isFeed
        />
      </BasicModal>
    </Box>
  );
};

export const NOTIFICATION_DOCTOR_PATIENT = gql`
  query notificationDoctorPatient(
    $start: Int
    $limit: Int
    $doctor: ID
    $patient: ID
  ) {
    notificationDoctorPatient(
      start: $start
      limit: $limit
      doctor: $doctor
      patient: $patient
    ) {
      notifications {
        doctor {
          _id
          firstname
          picture
          lastname
        }
        patient {
          _id
          firstname
          picture
          lastname
        }
        type
        title
        content
        event
        notificationDate
        notifOldDate
        messageId
        task {
          _id
          title
          description
          status
          dueDate
          priority
          attachments {
            fileName
            createdAt
            url
            fileType
          }
          patients {
            _id
            firstname
            lastname
            picture
            email
          }
          assignedTo {
            _id
            firstname
            lastname
            picture
            email
          }
          createdAt
          updatedAt
        }
        patientownerFirstname
        messageSenderFirstName
        activityType
        date
        appointmentId {
          _id
          description
          numberOfRepeat
          repeatEvery
          endOn
          repeatOn
          patient {
            _id
            firstname
            middlename
            lastname
            picture
            email
            phone
          }
          doctor {
            _id
            firstname
            middlename
            lastname
            picture
            email
            phone
          }
          startDate
          endDate
          location
          locationLink
          occurence
          conditionType {
            _id
            tag
            color
          }
          members
          patientGoing
          doctorGoing
          invitation {
            invitation_id
          }
          sessionType {
            session
            duration
            rate
            currency
            color
            location
            isPrepayment
            locationPlace
            externalUrl
            locationId {
              _id
              name
            }
          }
          isPrepay
        }
        doctorSender
        feed
        eventId {
          _id
          reflectionType
          question
          options
          type
          notes
          sleep
          photo
          assignedAt
          activityUrl
          isEdited
          bookmarked
          patient {
            _id
            firstname
            lastname
          }
          doctor {
            _id
          }
          frequency {
            startDate
            endDate
            frequencyType
            repetition {
              value
              unit
            }
            repeatOn
          }
          patientResponse {
            _id
            question
            response
            image
            sleepHours
            wakeHours
            notes
            createdAt
          }
        }
        description {
          response
          notes
          question
          sleepHours
          wakeHours
        }
      }
      count
    }
  }
`;
