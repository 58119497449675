import React from 'react';
import { Box, Typography, Checkbox, CheckboxProps } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';

interface Props extends CheckboxProps {
  name: string;
  text: string;
  width?: number;
  height?: number;
}
const CheckboxBlock = ({
  name,
  text,
  checked,
  onChange,
  width = 16,
  height = 16,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        name={name}
        size="small"
        checkedIcon={<CheckedIcon width={width} height={height} />}
        icon={<UncheckedIcon width={width} height={height} />}
        style={{ backgroundColor: 'transparent' }}
        checked={checked}
        onChange={onChange}
      />
      <Typography className={classes.radioText}>{text}</Typography>
    </Box>
  );
};

export default CheckboxBlock;
