import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import Table from 'components/ui/Table';
import { IRole } from './PermissionSettings';
import { ReactComponent as DropDownComponent } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useStyles } from '../styles';
import Text from 'Text';
import DropDownSelect from '../DropdownSelect';
import { ReactComponent as ClientIcon } from 'assets/roles/clients.svg';
import { ReactComponent as ScheduleIcon } from 'assets/roles/calendar.svg';
import { ReactComponent as TasksIcon } from 'assets/roles/tasks.svg';
import { ReactComponent as FinancesIcon } from 'assets/roles/finances.svg';
import { ReactComponent as FilesIcon } from 'assets/roles/files.svg';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import TrashIcon from 'assets/TrashIcon';
import useUpdateStaffPermission from '@eggmed/graphql-client/operations/staffOperations/useUpdateStaffPermission';
import { useSnackbar } from 'hooks/useSnackbar';
import Switch from 'components/ui/SwitchNew';

interface ITableInterface {
  role: any;
  isSelected: boolean;
  handleSelect: () => void;
}

function TopSection({ role, isSelected, handleSelect }: ITableInterface) {
  const classes = useStyles();
  const { triggerSnack } = useSnackbar();
  const [tableKey, setTableKey] = useState(0);
  const { handleUpdateUserPermission, loading, handleUpdateAddPermission } =
    useUpdateStaffPermission();
  // Initialize state to store current permissions
  const [currentPermissions, setCurrentPermissions] = useState(
    role?.permissions
  );

  useEffect(() => {
    setTableKey((prevKey) => prevKey + 1);
    setCurrentPermissions(role?.permissions); // Update currentPermissions on role change
  }, [role?.permissions]);

  // Update permission values in state
  const updatePermission = async (
    role: string,
    feature: string,
    action: string,
    actionIndex: number
  ) => {
    await handleUpdateUserPermission(role, feature, action, actionIndex);
    triggerSnack('Permission updated successfully');
    return;
  };
  const updatePermissionAdd = async (
    role: string,
    feature: string,
    isAdd: boolean,
    index: number
  ) => {
    const previousPermissions = [...currentPermissions];
    const updatedPermissions = [...currentPermissions];
    updatedPermissions[index] = {
      ...currentPermissions[index],
      isAdd,
    };
    setCurrentPermissions(updatedPermissions);

    try {
      await handleUpdateAddPermission(role, feature, isAdd);
      triggerSnack('Permission updated successfully');
    } catch (error) {
      console.error('Error updating permission:', error);
      setCurrentPermissions(previousPermissions);
      triggerSnack('Failed to update permission');
    }
  };
  const indexes = {
    Clients: { index: 0, text: 'clients' },
    Schedule: { index: 1, text: 'sessions' },
    Tasks: { index: 2, text: 'tasks' },
    Finances: { index: 3, text: 'bills' },
  };

  const getPermissionIcon = (permission: string) => {
    switch (permission) {
      case 'Clients':
        return <ClientIcon />;
      case 'Schedule':
        return <ScheduleIcon />;
      case 'Tasks':
        return <TasksIcon />;
      case 'Finances':
        return <FinancesIcon />;
      case 'files':
        return <FilesIcon />;
      default:
        return <ClientIcon />;
    }
  };

  const getPermissionDesc = (permission: string) => {
    switch (permission) {
      case 'Clients':
        return 'Client list and records';
      case 'Schedule':
        return 'Client sessions and events';
      case 'Tasks':
        return 'Client-focused or general tasks';
      case 'Finances':
        return 'Invoices, payments, and reports';
      case 'files':
        return 'Documents, images and videos';
      default:
        return 'Client list and records';
    }
  };
  const convertOptions = {
    'Assigned only': 'assigned',
    'All clients': 'all',
    None: 'none',
    // Everything: 'all',
    'All tasks': 'all',
  };
  const convertValuesACtions = {
    v_assigned: 'Assigned only',
    // v_owned: 'Owned only',
    v_none: 'None',
    e_assigned: 'Assigned only',
    // e_owned: 'Owned only',
    e_none: 'None',
    d_assigned: 'Assigned only',
    // d_owned: 'Owned only',
    d_none: 'None',
    v_all: 'All clients',
    e_all: 'All clients',
    d_all: 'All clients',
  };

  const columns = [
    {
      id: 'feature',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Permissions" style={{ whiteSpace: 'noWrap' }}>
            Permissions
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 10,
      format: (value) => {
        return (
          <Box display="flex" alignItems="flex-start">
            {getPermissionIcon(value)}
            <Box display="flex" flexDirection="column" ml="1rem">
              <Typography
                style={{
                  fontWeight: 700,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '15px',
                  fontFamily: 'Inter',
                  textTransform: 'capitalize',
                }}
              >
                {value}
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '13px',
                  fontFamily: 'Inter',
                  marginTop: '5px',
                  color: '#667085',
                }}
              >
                {getPermissionDesc(value)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'view',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <Eye />
          <Text
            i18nKey="View"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            View
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { feature, actions, isAdd }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`view-${tableKey}`}
              defaultMenuName={convertValuesACtions[actions[0]]}
              disabled={role?.name === 'Admin'}
              setValue={(newValue) => {
                updatePermission(
                  role?.name,
                  feature,
                  'v' + '_' + convertOptions[newValue],
                  0
                );
                if (newValue === 'None') {
                  ['e', 'd']?.map((el, index) =>
                    updatePermission(
                      role?.name,
                      feature,
                      el + '_' + convertOptions[newValue],
                      index + 1
                    )
                  );
                }
              }}
              options={
                feature === 'Tasks'
                  ? ['Assigned only', 'All tasks']
                  : ['None', 'All clients', 'Assigned only']
              }
              onClickAddOption={() => {}}
            />
            <Box mt={1} display="flex" alignItems="CENTER">
              <Switch
                checked={role?.name === 'Admin' ? true : !!isAdd}
                onClick={(e) => e.stopPropagation()}
                onChange={(e, valor) => {
                  updatePermissionAdd(
                    role?.name,
                    feature,
                    valor,
                    indexes[feature]?.index
                  );
                }}
                disabled={role?.name === 'Admin' || actions[0] === 'v_none'}
              />
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: '#344054',
                  marginLeft: '5px',
                  opacity:
                    role?.name === 'Admin' || actions[0] === 'v_none' ? 0.4 : 1,
                }}
              >
                Add {indexes[feature]?.text}{' '}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'edit',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <EditIcon />
          <Text
            i18nKey="Edit"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            Edit
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { feature, actions }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`edit-${tableKey}`}
              defaultMenuName={convertValuesACtions[actions[1]]}
              disabled={
                role?.name === 'Admin' || actions[0] === 'v_none'
                // ||
                // currentPermissions.find((p) => p.category === category)
                //   ?.view === 'None'
              }
              setValue={(newValue) =>
                updatePermission(
                  role?.name,
                  feature,
                  'e' + '_' + convertOptions[newValue],
                  1
                )
              }
              options={
                actions[0] === 'v_assigned'
                  ? ['None', 'Assigned only']
                  : ['None', 'All clients', 'Assigned only']
              }
              onClickAddOption={() => {}}
            />
            <Box mt={1} style={{ visibility: 'hidden' }}>
              <Switch
                defaultValue={''}
                defaultChecked={false}
                onClick={(e) => e.stopPropagation()}
                onChange={async (e, valor) => {}}
              />
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'delete',
      minWidth: 10,
      label: (
        <Box fontSize="1rem" display="flex" alignItems="center">
          <TrashIcon width="18px" height="18px" fill="#475467" />
          <Text
            i18nKey="Delete"
            style={{ whiteSpace: 'noWrap', marginLeft: '10px' }}
          >
            Delete
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { feature, actions }) => {
        return (
          <Box width="158px">
            <DropDownSelect
              key={`delete-${tableKey}`}
              defaultMenuName={convertValuesACtions[actions[2]]}
              disabled={
                role?.name === 'Admin' || actions[0] === 'v_none'
                //  ||
                // currentPermissions.find((p) => p.category === category)
                //   ?.view === 'None'
              }
              setValue={(newValue) =>
                updatePermission(
                  role?.name,
                  feature,
                  'd' + '_' + convertOptions[newValue],
                  2
                )
              }
              options={
                actions[0] === 'v_assigned'
                  ? ['None', 'Assigned only']
                  : ['None', 'All clients', 'Assigned only']
              }
              onClickAddOption={() => {}}
            />
            <Box mt={1} style={{ visibility: 'hidden' }}>
              <Switch
                defaultValue={''}
                defaultChecked={false}
                onClick={(e) => e.stopPropagation()}
                onChange={async (e, valor) => {}}
              />
            </Box>
          </Box>
        );
      },
    },
  ];

  return (
    <Box width="100%" mt="2rem">
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        onClick={handleSelect}
        style={{
          cursor: 'pointer',
          borderBottom: !isSelected && '1px solid #D0D5DD',
          paddingBottom: !isSelected && '10px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {isSelected ? (
            <ChevronUp />
          ) : (
            <DropDownComponent style={{ transform: 'rotate(-90deg)' }} />
          )}
        </Box>
        <Typography className={classes.sectionTitle}>
          Provider permissions for the{' '}
          <span style={{ color: '#0054B6' }}>{role?.name}</span> role
        </Typography>
      </Box>
      {isSelected && (
        <Box width="100%" mt="1rem">
          <Table
            loading={false}
            page={1}
            columns={columns as any}
            limit={10}
            fullData={false}
            handlePagination={() => {}}
            data={currentPermissions || []}
            pagination
            sorting={false}
          />
        </Box>
      )}
    </Box>
  );
}

export default TopSection;
