import { useMutation, gql, ApolloError } from '@apollo/client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

export const UPDATE_STAFF_MEMBER = gql`
  mutation updatedStaffOrg($id: ID, $staffInput: StaffMemberInput) {
    updatedStaffOrg(id: $id, staffInput: $staffInput)
  }
`;

export default function useUpdateTeamMember() {
  const [updatedStaffOrg, result] = useMutation(UPDATE_STAFF_MEMBER, {
    refetchQueries: [
      {
        query: TEAM_MEMBERS,
        variables: { role: '', status: '' },
      },
    ],
  });

  return { updatedStaffOrg, ...result };
}
