import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Typography,
  BoxProps,
  makeStyles,
} from '@material-ui/core';

import { ReactComponent as UnassignIcon } from 'assets/UnassignIcon.svg';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';
import { IProviderData } from './AssignProvider';
import { IPatientData } from 'pages/SchedulePage/state/types';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#475467',
    fontWeight: 400,
    fontSize: '14px',
  },
  confirmButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 20px',
    gap: '10px',
    backgroundColor: '#D92D20',
    borderRadius: '8px',
    flex: 'none',
    order: 1,
    flexGrow: 1,
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#D92D20',
    },
  },
  cancelButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 20px',
    gap: '10px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(222, 222, 222, 1)',
      border: '1px solid #828282',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
  },
  modalBox: {
    flexDirection: 'column',
    paddingInline: '2rem',
    paddingBottom: '2rem',
    width: '25vw',
    display: 'flex',
    padding: '0 36px 36px 36px',
    gap: '36px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
  },
}));
interface IDeleteModalContentProps extends BoxProps {
  handleDelete: any;
  handleClose: () => void;
  text?: string;
  isPatient?: boolean;
  message?: string;
  label?: string;
  description?: string;
  deleteBtn?: string;
  loading?: boolean;
  selectedProvider?: IProviderData;
  patient?: IPatientData;
}
export default function UnassignProvider({
  selectedProvider,
  patient,
  handleDelete,
  handleClose,
  text,
  isPatient = false,
  label,
  description,
  deleteBtn,
  loading,
  ...props
}: IDeleteModalContentProps): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  const formattedLabel = label
    ? label.replace(
        `${selectedProvider?.firstname} ${selectedProvider?.lastname}`,
        `<strong>${capitalizeFirstLetter(
          selectedProvider?.firstname
        )} ${capitalizeFirstLetter(selectedProvider?.lastname)}</strong>`
      )
    : '';

  const formattedDescription = description
    ? description
        .replace(
          `${selectedProvider?.firstname} ${selectedProvider?.lastname}`,
          `<strong>${capitalizeFirstLetter(
            selectedProvider?.firstname
          )} ${capitalizeFirstLetter(selectedProvider?.lastname)}</strong>`
        )
        .replace(
          `${patient?.firstname} ${patient?.lastname}`,
          `<strong>${capitalizeFirstLetter(
            patient?.firstname
          )} ${capitalizeFirstLetter(patient?.lastname)}</strong>`
        )
    : '';
  return (
    <Box className={classes.modalBox} {...props}>
      <Box display="flex" flexDirection={'column'} style={{ gap: '12px' }}>
        <UnassignIcon />
        <Box>
          <Typography
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: t(formattedLabel) }}
          />
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: t(formattedDescription) }}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '18px',
          flex: 'none',
          order: 1,
          alignSelf: 'stretch',
          flexGrow: 0,
        }}
      >
        <Button className={classes.cancelButton} onClick={handleClose}>
          {t('Cancel')}
        </Button>
        <Button
          className={classes.confirmButton}
          data-cy="delete-confirm"
          onClick={() => handleDelete(selectedProvider)}
        >
          {t(deleteBtn)}{' '}
        </Button>
      </Box>
    </Box>
  );
}
