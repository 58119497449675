import React from 'react';
import {
  makeStyles,
  Box,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  BoxProps,
  Typography,
  Avatar,
  Chip,
} from '@material-ui/core';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as EditIcon } from 'assets/EditThick.svg';
import { ReactComponent as DeleteIcon } from 'assets/deleteIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/Vector.svg';
import {
  getTasks_tasks,
  getTasks_tasks_attachments,
} from './__generated__/getTasks';
import { formatDatesFullMonth } from 'utils';
import { formatDates } from 'utils';
import PriorityChip from './components/PriorityChip';
import { ReactComponent as MarkComplete } from 'assets/icons/check-square-broken.svg';
import {
  editorStateFromStringifiedRaw,
  limitTextFromState,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { Editor } from 'draft-js';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import AttachmentPreview from './components/AttachmentPreview';
import { convertString } from './List';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Download } from 'assets/icons/download-01.svg';
import { ReactComponent as View } from 'assets/icons/eye.svg';
import StatusChip from './components/StatusChip';
import { config } from 'config';
import UploadFilePreview from './components/AttachmentItemPreview';
import AttachmentItemPreview from './components/AttachmentItemPreview';
import TagList from 'pages/AdminPage/TabsContent/templatesTabs/TagsList';
import { useProvider } from './state/Provider';
import { editTaskInput } from '../../../__generated__/globalTypes';
import { useSnackbar } from 'hooks/useSnackbar';
import useCheckPermission from 'contexts/PermissionContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    overflow: 'hidden',
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '55vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  scrollBar: {
    maxWidth: '100%',
    width: '100%',
    overflowX: 'hidden',
    maxHeight: '79vh',
  },
  ScrollBox: {
    boxSizing: 'border-box',
    maxWidth: '100%',
    paddingLeft: '2rem',
    paddingTop: '1rem',
    paddingBottom: '3rem',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21.78px',
    paddingBottom: '20px',
    color: '#344054',
    marginTop: '1rem',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '18px',
    color: '#344054',
  },
  avatar: {
    maxWidth: '25px',
    maxHeight: '25px',
    marginRight: '8px',
  },
  username: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'rgba(130, 130, 130, 1)',
    whiteSpace: 'nowrap',
  },
  chip: {
    //  color: '#AF77E0',
    fontSize: '13px',
    height: '28px',
    fontWeight: 500,
    // backgroundColor: 'rgba(249, 105, 153, 0.2)',
    width: 'fit-content',
    [theme.breakpoints.only('lg')]: {
      height: '28px',
      fontSize: '12px',
      width: 'fit-content',
    },
  },
  topHeader: {
    borderBottom: '1px solid rgb(215, 227, 241)',
    alignItems: 'center',
    display: 'flex',
    height: '54px',
  },
  titleText: {
    paddingInline: '2rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '20px',
    color: '#344054',
    lineHeight: '30px',
  },
  assigneeWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  priorityChip: {
    height: '28px',
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  actionIcons: {
    height: '21px',
    width: '21px',
  },
  closeIcon: {
    height: '15px',
    width: '15px',
  },
  buttonMark: {
    borderRadius: '10px',
    marginRight: '12px',
    border: '1px solid #4CA30D',
    width: '100%',
    padding: '8px 12px',
    display: 'flex',
    color: '#4CA30D',
    alignItems: 'center',
    gap: '4px',
    cursor: ({ isEdit }: { isEdit: boolean }) =>
      isEdit ? 'pointer' : 'default',
  },
  valueStyle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
  },
}));

interface ItaskPreviewProps extends getTasks_tasks, Omit<BoxProps, 'title'> {
  closeModal?: () => void;
  openEditModal?: (task: getTasks_tasks) => void;
  handleDelete?: () => void;
  setSelectedTask?: (id: string) => void;
  handleDeleteTaskAttachment?: (
    taskId: string,
    attachmentId: string,
    closeModal: () => void
  ) => void;
  taskList?: Task[];
  changeTaskPosition?: any;
  getList?: any;
  isFeed?: boolean;
}
interface Task {
  tasks: TaskValue[];
  name: string;
}
interface TaskValue {
  _id: string;
}
interface MenuItem {
  url: string;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

const TaskPreview = ({
  closeModal,
  openEditModal,
  title,
  _id,
  description,
  status,
  dueDate,
  priority,
  attachments,
  createdAt,
  updatedAt,
  assignedTo,
  patients,
  handleDelete,
  setSelectedTask,
  handleDeleteTaskAttachment,
  taskList,
  changeTaskPosition,
  getList,
  isFeed = false,
}: ItaskPreviewProps) => {
  const list = getList(_id, taskList)?.name;
  const { t } = useTranslation();
  const task: getTasks_tasks = {
    __typename: 'Task',
    _id,
    title,
    description,
    status,
    dueDate,
    priority,
    attachments,
    assignedTo,
    createdAt,
    updatedAt,
    patients,
  };
  const { isEnabled: isEdit, loading: loadingEdit } = useCheckPermission(
    'Tasks',
    'edit',
    _id
  );
  const { isEnabled: isDelete, loading: loadingDelete } = useCheckPermission(
    'Tasks',
    'delete',
    _id
  );

  const statut = React.useMemo(
    () => taskList?.find((el) => el.tasks.some((t) => t._id === _id))?.name,
    [taskList]
  );
  const { triggerSnack } = useSnackbar();

  const classes = useStyles({ isEdit });
  const { doctor } = useAuth();
  const { handleEditTask } = useProvider();
  const handleOpenEditModal = () => {
    closeModal();
    openEditModal(task);
  };
  const handleOpenDeleteModal = () => {
    setSelectedTask(_id);
    closeModal();
    handleDelete();
  };
  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
  const getMenuItems = (
    url: string,
    index: number
  ): {
    url: string;
    avatar: React.ReactNode;
    name: string;
    onClick: () => void;
  }[] => [
    {
      url,
      avatar: <View style={{ marginRight: '8px' }} />,
      name: 'View',
      onClick: () => handleDownload(url, 'file.pdf'),
    },
    {
      url,
      avatar: <Download style={{ marginRight: '8px' }} />,
      name: 'Download',
      onClick: () => handleDownload(url, 'file.pdf'),
    },
  ];
  function handleUpdateList() {
    const sourceList = getList(_id, taskList);
    const destinationList: any = taskList?.find(
      (el) => el.name === 'completed'
    );
    if (destinationList?.name !== sourceList?.name)
      changeTaskPosition(
        destinationList?.tasks?.length,
        sourceList._id,
        destinationList?._id,
        taskList,
        _id
      );
    closeModal();
  }
  const handleMarkComplete = () => {
    if (!isEdit) return;
    handleEditTask(_id, { status: 'done' });
    handleUpdateList();
    triggerSnack();
    closeModal();
  };

  const actions: any[] = [
    {
      label: t('Close'),
      value: 'close',
      icon: <CloseIcon className={classes.closeIcon} />,
      action: closeModal,
    },
    {
      label: t('Delete'),
      value: 'delete',
      icon: <DeleteIcon className={classes.actionIcons} />,
      action: handleOpenDeleteModal,
    },
    {
      label: t('Edit'),
      value: 'edit',
      icon: <EditIcon className={classes.actionIcons} />,
      action: handleOpenEditModal,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box
        width={'100%'}
        justifyContent="space-between"
        className={classes.topHeader}
      >
        <b className={classes.titleText}> Task Preview</b>
        <Box
          display="flex"
          flexDirection="row-reverse"
          alignContent="center"
          maxWidth="100%"
          style={{
            paddingRight: '1rem',
            paddingLeft: '1rem',
            alignItems: 'center',
          }}
        >
          {actions.map((actionOption, index) => {
            return (
              <Tooltip key={actionOption.value} title={actionOption.label}>
                <IconButton
                  onClick={actionOption.action && actionOption.action}
                  disabled={
                    index === 1
                      ? isFeed || !isDelete
                      : index === 2
                      ? isFeed || !isEdit
                      : false
                  }
                  style={{
                    opacity: isFeed
                      ? 0.5
                      : index === 1
                      ? isDelete
                        ? 1
                        : 0.5
                      : index === 2
                      ? isEdit
                        ? 1
                        : 0.5
                      : 1,
                  }}
                >
                  {actionOption.icon}
                </IconButton>
              </Tooltip>
            );
          })}
          {list !== 'completed' && (
            <span
              className={classes.buttonMark}
              onClick={handleMarkComplete}
              style={{ opacity: isEdit && !isFeed ? 1 : 0.5 }}
            >
              <MarkComplete /> Mark as done
            </span>
          )}
        </Box>
      </Box>
      <ScrollBar
        style={{ maxHeight: '100vh', height: '100vh' }}
        className={classes.scrollBar}
      >
        <Box width="100%" className={classes.ScrollBox}>
          <Typography className={classes.title}>
            {title &&
              title[0]?.toUpperCase() +
                title?.slice(1, title.length)?.toLowerCase()}
          </Typography>
          <PriorityChip
            style={{ marginBottom: '20px' }}
            className={classes.priorityChip}
            priority={list}
          />
          <Box
            display="flex"
            alignContent="center"
            style={{
              marginTop: '1.25rem',
              height: '30px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Box minWidth="6rem" display="flex" alignItems="center">
              <Text i18nKey="assignee(s)" className={classes.subTitle}>
                Assignee(s)
              </Text>
            </Box>
            <TagList
              visibleAssignees={assignedTo?.slice(0, 2)}
              hiddenAssignees={assignedTo?.slice(2)}
            />
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{
              marginTop: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="dueDate" className={classes.subTitle}>
                Due Date
              </Text>
            </Box>
            {/*<Chip className={classes.chip} label={formatDates(dueDate)} />*/}
            <Typography
              style={{
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '16px',
                color: '#344054',
              }}
            >
              {formatDatesFullMonth(dueDate)}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{
              marginTop: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="clients" className={classes.subTitle}>
                client(s)
              </Text>
            </Box>
            {patients?.length > 0 ? (
              <TagList
                visibleAssignees={patients?.slice(0, 2)}
                hiddenAssignees={patients?.slice(2)}
              />
            ) : (
              <Typography>___</Typography>
            )}
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{
              marginTop: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Box minWidth="6rem">
              <Text i18nKey="priority" className={classes.subTitle}>
                Priority
              </Text>
            </Box>
            <PriorityChip
              className={classes.priorityChip}
              priority={priority}
            />
          </Box>
          <Box
            display="flex"
            alignContent="center"
            style={{
              marginTop: '1.3rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '15px',
            }}
          >
            <Box minWidth="6rem" display="flex" alignItems="center">
              <Text i18nKey="Description" className={classes.subTitle}>
                Description
              </Text>
            </Box>
            {description && (
              <Typography style={{ lineHeight: '18px' }}>
                {description?.substr(0, 50)}
              </Typography>
            )}
          </Box>
          <Box mt={6}>
            <Box mt={2} display="flex" flexDirection="column">
              {attachments && attachments?.length > 0 ? (
                attachments?.map((attachment, index) => {
                  const { url, fileType, fileName } = attachment || {};
                  const { firstname, lastname, picture } = doctor || {};
                  return (
                    <AttachmentItemPreview
                      fileType={fileType}
                      file={attachment as any}
                      menuItems={getMenuItems(url, index)}
                      isEdit={true}
                      url={url || ''}
                      fileName={fileName}
                    />
                  );
                })
              ) : (
                <Text i18nKey="noAttachement">No attachment</Text>
              )}
            </Box>
          </Box>
        </Box>
      </ScrollBar>
    </Box>
  );
};
export default TaskPreview;
