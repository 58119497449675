import React from 'react';
import { useProvider } from '../../state/Provider';
import Button from 'components/ui/Button';
import { ReactComponent as PaperClipWhite } from 'assets/PaperClipWhite.svg';

import useStyles from './style';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyInbox } from 'assets/emptyState/empty_inbox.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  isEmpty?: boolean;
}

const EmptyMessageIllustration = ({ isEmpty = false }: IProps) => {
  const { t } = useTranslation();
  const { patient } = useAuth();
  const isPatient = !!patient;
  const classes = useStyles({ patient: isPatient });
  const { handleOpenCreateNewThreadModal } = useProvider();
  const handleOpenNewThreadModal = () => {
    handleOpenCreateNewThreadModal();
  };
  return (
    // <EmptyState
    //   icon={<EmptyInbox />}
    //   title="No messages"
    //   text="to compose a thread"
    //   onClick={handleOpenNewThreadModal}
    //   isText
    // />
    <EmptyState
      icon={<EmptyInbox />}
      title={
        isEmpty ? t('Inbox empty') : t('Select a conversation to get started')
      }
      text={isEmpty && t('to compose a thread')}
      onClick={handleOpenNewThreadModal}
      isText={false}
      noActionText={isEmpty ? false : true}
      hasAction={true}
      btnIcon={<MessageIcon />}
      buttonText={t('newMessage')}
    />
  );
};

export default EmptyMessageIllustration;
