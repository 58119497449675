import { gql, useQuery } from '@apollo/client';

export const LOCATION_BY_DOCTOR = gql`
  query locationsByDoctor {
    locationsByDoctor {
      name
      _id
      updatedAt
    }
  }
`;


export const useGetLocationsByDoctor = () => {
  const { data, error, loading } = useQuery(LOCATION_BY_DOCTOR);
  return { data, error, loading};
};