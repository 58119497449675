import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as AddIcon } from 'assets/ShareIconAct.svg';
import {
  Box,
  Typography,
  Avatar,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import Button from 'components/ui/Button';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import { useSnackbar } from 'hooks/useSnackbar';

interface IEditProps {
  open: boolean;
  onClose: any;
  role: string;
  teams: any;
  setSelectedProviders: any;
  setOpenAdd: (state: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  tabForm: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    '& .MuiTabs-root': {
      width: '100%',
      transform: 'translateX(-2rem)',
    },
    '& .MuiBox-root-922': {
      width: '100%',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
    },
    '&.MuiTab-wrapper': {
      padding: '10px 0',
      height: '100%',
    },
  },
  permissionsTab: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    '& .MuiTabs-root': {
      width: '100%',
    },
    '& .MuiBox-root-922': {
      width: '100%',
    },
    '& .MuiTab-root': {
      maxWidth: '100% !important',
      [theme.breakpoints.down('xl')]: {
        minWidth: '1px',
      },
    },
    '&.MuiTab-wrapper': {
      padding: '10px 0',
      height: '100%',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingInline: '3rem',
    paddingBlock: '0.5rem',
  },
  permission: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingBlock: '0.5rem',
  },
  tabText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
  },
  errorIcon: { fontSize: 14, marginRight: '5px' },
  clearButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  addBtnText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
  actions: {
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionsVisible: {
    opacity: 1,
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#182230',
  },
  popup: {
    padding: '8px 12px',
    backgroundColor: '#101828',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14.4px',
    textAlign: 'left',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#D0D5DD',
    marginTop: '1rem',
    marginBottom: '10px',
    marginInline: '10px',
  },
  modalTitle: {
    marginTop: '1rem',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24.4px',
    textAlign: 'left',
    color: '#182230',
  },
  modalText: {
    marginTop: '5px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#667085',
  },
  PasswordModal: {
    width: theme.typography.pxToRem(457),
    boxSizing: 'border-box',
    backgroundColor: 'white',
    padding: '2rem',
    borderRadius: '8px',
  },

  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#182230',
    textTransform: 'none',
  },
  saveButton: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0265DC',
    },
    '&:disabled': {
      color: 'white',
      opacity: 0.5,
    },
  },
  saveButtonRed: {
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    borderRadius: '8px',
    height: '44px',
    maxHeight: '44px',
    width: '100%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#FFFFFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#D92D20',
    },
  },
  CustomTab: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: '20px',
    color: '#344054',
    marginLeft: '1rem',
  },
  type: {
    backgroundColor: '#EFF8FF',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #B2DDFF',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#175CD3',
  },
}));

function ShareActivityModal({
  open,
  onClose,
  role,
  teams,
  setSelectedProviders,
  setOpenAdd,
}: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const { control, watch, register, errors, setValue, handleSubmit } =
    useFormContext();
  const [isUpdated, setIsUpdated] = React.useState(true);
  const isType = watch('type') ;
  const isTyping = watch('typing') ;
  function getSelectedUserIds(typingArray, teamSettings) {
    if (!Array.isArray(typingArray) || !Array.isArray(teamSettings)) {
      return [];
    }

    return teamSettings.filter((_, index) => typingArray[index]);
  }
  async function onSubmit(data, event) {
    event.stopPropagation();

    const selectedUser = getSelectedUserIds(data?.typing, teams);
    setSelectedProviders(selectedUser);

    setOpenAdd(false);
    // triggerSnack('Service assigned successfully');
  }

  React.useEffect(() => {
    if (teams?.length > 0 && isUpdated) {
      teams?.forEach((_, idx) => {
        setValue(`typing.${idx}`, isType);
      });
    }
  }, [teams?.length, setValue, isType, isUpdated]);

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
          e.stopPropagation();
        }}
      >
        <Box className={classes.PasswordModal}>
          <AddIcon />
          <Typography className={classes.modalTitle}>
            Share this {role}
          </Typography>
          <Typography className={classes.modalText}>
            Select which provider(s) you would like to share this{' '}
            <span style={{ fontWeight: '700' }}>{role}</span> with.
          </Typography>
          <Box
            mt={2}
            mb={4}
            py={1}
            pl={1}
            pr={2}
            style={{
              backgroundColor: '#F5FBFF',
              border: '1px solid #147AF3',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              py={1}
              borderBottom={'0.5px solid #CAE8FF'}
            >
              <Box display="flex" alignItems="center">
                <Controller
                  name={'type'}
                  control={control}
                  render={({ onChange, value, ref }) => {
                    return (
                      <Checkbox
                        size="medium"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setIsUpdated(true);
                        }}
                        checkedIcon={<CheckedIcon width={16} height={16} />}
                        icon={<UncheckedIcon width={16} height={16} />}
                        style={{
                          backgroundColor: 'transparent',
                          marginLeft: '-5px',
                        }}
                      />
                    );
                  }}
                />
                <Typography
                  style={{
                    color: '#344054',
                    fontWeight: 700,
                    fontSize: '14px',
                  }}
                >
                  Share with all providers
                </Typography>
              </Box>
            </Box>
            <Box>
              {teams
                ?.filter((el) => el?.role !== 'owner')
                ?.map((team, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={1}
                    borderBottom={
                      index !== teams?.length - 1 && '0.5px solid #CAE8FF'
                    }
                  >
                    <Box display="flex" alignItems="cemter">
                      <Box mt={-0.5}>
                        <Controller
                          name={`typing.${index}`}
                          defaultValue={false}
                          control={control}
                          render={({ onChange, value, ref }) => {
                            return (
                              <Checkbox
                                size="medium"
                                checked={isTyping[index] || false}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  if (!e.target.value) {
                                    setIsUpdated(false);
                                    setValue('type', false);
                                  } else {
                                    setIsUpdated(true);
                                  }
                                }}
                                checkedIcon={
                                  <CheckedIcon width={16} height={16} />
                                }
                                icon={<UncheckedIcon width={16} height={16} />}
                                style={{
                                  backgroundColor: 'transparent',
                                  marginLeft: '-5px',
                                }}
                              />
                            );
                          }}
                        />
                      </Box>
                      <Avatar
                        src={team.picture}
                        style={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 10,
                          textTransform: 'capitalize',
                        }}
                      >
                        {team?.firstname && team?.firstname[0]}{' '}
                        {team?.lastname && team?.lastname[0]}
                      </Avatar>
                      <Typography
                        style={{
                          marginLeft: '5px',
                          color: '#344054',
                          fontSize: '14px',
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          marginTop: '2px',
                        }}
                      >
                        {team?.firstname} {team?.lastname}
                      </Typography>{' '}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ gap: '1rem' }}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedProviders([]);
                onClose();
              }}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.saveButton}
              disabled={!isTyping?.some((state) => state) && !isType}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </form>
    </BasicModal>
  );
}

export default ShareActivityModal;
