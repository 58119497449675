import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Tabs from 'components/ui/Tabs';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID } from 'pages/PatientActivities';
import { gql, useQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import clsx from 'clsx';
import BasicModal from 'components/ui/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { CreateEditForm } from './CreateEditForm';
import useLogActivity from '@eggmed/graphql-client/operations/activityOperations/useLogActivity';
import { getCheckedTags } from './utils';
import { moodTags, sleepTags } from './CreateEditForm/tags';
import useUpdatePatientActivity from '@eggmed/graphql-client/operations/patientActivitiesOperations/useUpdatePatientActivity';
import useEditQuestionnaire from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditQuestionnaire';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import { useSnackbar } from 'hooks/useSnackbar';
import { ReactComponent as MoodSel } from 'assets/MoodSel.svg';
import { ReactComponent as ReflectionSel } from 'assets/ReflectionSel.svg';
import { ReactComponent as SleepSel } from 'assets/SleepSel.svg';
import { ReactComponent as FormsSel } from 'assets/FormsSel.svg';
import { ReactComponent as MoodUnsel } from 'assets/MoodUnsel.svg';
import { ReactComponent as ReflectionUnsel } from 'assets/ReflectionUnsel.svg';
import { ReactComponent as SleepUnsel } from 'assets/SleepUnsel.svg';
import { ReactComponent as FormsUnsel } from 'assets/FormsUnsel.svg';
import { ReactComponent as AllActv } from 'assets/AllActv.svg';
import Activity, {
  TypeEnum as activityType,
  GET_PATIENT_ACTIVITIES_BY_FILTER,
} from 'pages/PatientActivities/commun/Activity';
import { TypeEnum } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import {
  Moods,
  Sleep as sleepEmojies,
} from 'pages/PatientActivities/commun/constants';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import InboxProvider from 'pages/InboxPage/state/Provider';
import ActivityProvider from 'pages/PatientActivities/Questionaires/ActivityProvider';
import { useEditorContext } from 'components/Editorjs/EditorContextProvider';
import Questionaires, {
  QUESTIONNAIRE_BY_FILTER,
} from 'pages/PatientActivities/Questionaires/Questionaires';
import { AssignedActivities } from './AssignedActivities';
import { RecentActivities } from './RecentActivities';
import useGetDoctors from '@eggmed/graphql-client/operations/doctorOperations/useGetDoctors';

export enum IActivitySource {
  ASSIGN = 'Assign',
  LOG = 'Log',
  EDIT = 'Edit',
}

export type IActivityToOpen = {
  source: IActivitySource;
  type: ActivitiesTypes;
  assignedAt?: any;
  activity?: any;
};

export const schema = yup.object().shape({});

const reflectionSchema = yup.object().shape({
  notes: yup.string().required(),
});

function PatientActivities() {
  
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const { patient } = useAuth();
  const patientId = patient?._id;
  const { data: doctors } = useGetDoctors();
  const { logActivity, loading: loadingLog } = useLogActivity();
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  const [customValue, setCustomValue] = React.useState(
    // tabSelected ? Number(tabSelected) : 0
    0
  );
  const [openAssign, setOpenAssign] = React.useState(false);
  const [openActivity, setOpenActivity] = React.useState<activityType | null>(
    null
  );

  const { data: PatientData } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });

  const { data: assignedAct, loading: loadingAssigned } = useQuery(
    GET_PATIENT_ACTIVITY,
    {
      variables: { patientId, isPending: true },
    }
  );

  const [createEditOpen, setCreateEditOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<ActivitiesTypes[]>([]);
  const [activityToOpen, setActivityToOpen] = useState<IActivityToOpen>();
  const [formResponse, setFormResponse] = useState(activityToOpen?.activity);
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    setFormResponse(activityToOpen?.activity);
  }, [activityToOpen]);

  const isEditMode = activityToOpen?.source === IActivitySource.EDIT;

  const { data, loading: loadingRecent } = useQuery(
    GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
    {
      variables: {
        patientId,
        doctorId: doctors && doctors?.doctors[0]?._id,
        page,
        limit: 20,
        types: selectedValues,
      },
    }
  );

  const [handleUpdatePatientActivity, { loading: updateLoading }] =
    useUpdatePatientActivity();
  const [handleEditQuestionnaire, { loading: formLoading }] =
    useEditQuestionnaire();

  const handleOpenCreateEdit = () => {
    setCreateEditOpen(true);
  };

  const handleCloseCreateEdit = () => {
    setCreateEditOpen(false);
    toggleSignature(false);
    setError('');
  };

  const doctorAndPatientId = {
    doctor: doctors && doctors?.doctors[0]?._id,
    patient: patientId,
  };

  const classes = useStyles();

  const schemaSelector = (activityToOpen: ActivitiesTypes) => {
    switch (activityToOpen) {
      case ActivitiesTypes.MOOD:
        return schema;
      case ActivitiesTypes.SLEEP:
        return schema;
      case ActivitiesTypes.REFLECTION:
        return reflectionSchema;
      default:
        return schema;
    }
  };

  const methods = useForm({
    resolver: yupResolver(schemaSelector(activityToOpen?.type)),
    defaultValues: {
      selectedEmoji: 'Okay',
      submitDate: new Date(),
    },
  });

  async function handleLogActivity(data: any) {
    const { patientActivitiesInput, currentDate } = data;
    await logActivity({
      variables: {
        patientActivitiesInput,
        currentDate,
      },
      refetchQueries: [
        GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
        GET_PATIENT_ACTIVITIES_BY_FILTER,
      ],
    });
    triggerSnack();
    handleCloseCreateEdit();
  }

  useEffect(() => {
    if (isEditMode) {
      methods.reset({
        selectedEmoji: activityToOpen?.activity?.patientResponse[0]?.response,
      });
    } else {
      methods.reset({
        selectedEmoji: 'Okay',
      });
    }
  }, [activityToOpen]);
  const [selectedProviders, setSelectedProviders] = React.useState<any>([]);

  const moodSubmit = async (data: any) => {
    const { notes, selectedEmoji, files, submitDate } = data;

    const patientResponse = [
      {
        question: t('How are you feeling?'),
        response: selectedEmoji,
        image: files && files[0],
        notes: notes,
        tags: getCheckedTags(moodTags, selectedEmoji, data),
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: t('How are you feeling?'),
        type: 'moodCheckin',
        assignedAt: new Date(),
        patientResponse,
        isShared: data?.isShared,
        doctors: selectedProviders?.map((el) => el?._id),
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };
      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [
          GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
          GET_PATIENT_ACTIVITIES_BY_FILTER,
          GET_PATIENT_ACTIVITY,
        ],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };

  const sleepSubmit = async (data: any) => {
    const { notes, selectedEmoji, bedtime, wakeup, files, submitDate } = data;

    const patientResponse = [
      {
        question: t(
          'How would you describe the quality of your sleep last night?'
        ),
        response: selectedEmoji,
        sleepHours: bedtime,
        wakeHours: wakeup,
        image: files && files[0],
        notes: notes,
        tags: getCheckedTags(sleepTags, selectedEmoji, data),
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: t(
          'How would you describe the quality of your sleep last night?'
        ),
        type: 'sleepCheckin',
        assignedAt: new Date(),
        patientResponse,
        isShared: data?.isShared,
        doctors: selectedProviders?.map((el) => el?._id),
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };

      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [
          GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
          GET_PATIENT_ACTIVITIES_BY_FILTER,
          GET_PATIENT_ACTIVITY,
        ],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };

  const reflectionSubmit = async (data: any) => {
    const { entry, notes, files, submitDate } = data;

    const patientResponse = [
      {
        question: entry,
        response: notes,
        image: files && files[0],
        notes: notes,
        createdAt: new Date(),
      },
    ];

    const loggedData = {
      patientActivitiesInput: {
        question: entry,
        type: 'reflection',
        reflectionType: 'free',
        assignedAt: new Date(),
        patientResponse,
        isShared: data?.isShared,
        doctors: selectedProviders?.map((el) => el?._id),
      },
      currentDate:
        activityToOpen?.source === IActivitySource.ASSIGN
          ? new Date(activityToOpen?.assignedAt)
          : submitDate ?? new Date(),
    };

    if (isEditMode) {
      const patientActivitiesInput = {
        patientResponse: {
          ...patientResponse[0],
          image: activityToOpen?.activity?.patientResponse[0]?.image,
        },
        doctor: activityToOpen?.activity?.doctor._id,
        patient: activityToOpen?.activity?.patient._id,
      };

      await handleUpdatePatientActivity({
        variables: {
          id: activityToOpen?.activity?._id,
          patientActivitiesInput,
        },
        refetchQueries: [
          GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
          GET_PATIENT_ACTIVITIES_BY_FILTER,
          GET_PATIENT_ACTIVITY,
        ],
      });
      triggerSnack();
      handleCloseCreateEdit();
    } else {
      await handleLogActivity(loggedData);
    }
  };
  const { data: patientSignature, loading: loadingSignature } = useQuery(
    GET_PATIENT,
    {
      variables: { patientId: patient?._id },
    }
  );
  const { open, toggleSignature } = useEditorContext();
  const [error, setError] = React.useState('');
  const formSubmit = async (data) => {
    // const { ...rest } = formResponse;
    // const questions = rest.questions.map(({ ...rest }) => rest);
    const { data: dataSign } =
      JSON.parse(formResponse.blocks).find((el) => el.type === 'Signature') ||
      {};
    const isSign =
      !!dataSign &&
      // open &&
      patientSignature?.patient?.signature?.picture &&
      !loadingSignature;
    const payload = {
      title: formResponse.title,
      description: formResponse.description,
      patientResponseDate: new Date(),
      blocks: formResponse.blocks,
      assignedAt: formResponse.assignedAt,
      doctor: formResponse.doctor._id,
      patient: patientId,
      isPatientSigned: isSign,
    };
    if (!!!dataSign) {
      await handleEditQuestionnaire({
        variables: { id: formResponse._id, questionnaireInput: payload },
        refetchQueries: [
          { query: QUESTIONNAIRE_BY_PATIENT_ID, variables: { patientId } },
          GET_PATIENT_ACTIVITY,
          GET_PATIENT_ACTIVITIES_BY_FILTER,
          QUESTIONNAIRE_BY_FILTER,
          GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
        ],
      });
      setError('');
    } else {
      if (isSign) {
        await handleEditQuestionnaire({
          variables: { id: formResponse._id, questionnaireInput: payload },
          refetchQueries: [
            { query: QUESTIONNAIRE_BY_PATIENT_ID, variables: { patientId } },
            GET_PATIENT_ACTIVITY,
            GET_PATIENT_ACTIVITIES_BY_FILTER,
            QUESTIONNAIRE_BY_FILTER,
            GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
          ],
        });
        setError('');
      } else {
        setError('Please add your signature');
        return;
      }
    }

    triggerSnack();
    handleCloseCreateEdit();
  };

  const onSubmit = async (data: any) => {
    switch (activityToOpen.type) {
      case ActivitiesTypes.MOOD:
        return await moodSubmit(data);
      case ActivitiesTypes.SLEEP:
        return await sleepSubmit(data);
      case ActivitiesTypes.REFLECTION:
        return await reflectionSubmit(data);
      case ActivitiesTypes.FORM:
        return await formSubmit(data);
      default:
        return;
    }
  };

  const recentActivity =
    data?.patientActivitiesAndFormsByPatientDoctorId?.result;

  return (
    <InboxProvider>
      <Box display="flex" alignItems="flex-start" gridGap="1rem">
        <Box
          className={clsx(classes.background, classes.assignedWrapper)}
          flex={1}
        >
          <AssignedActivities
            assignedAct={assignedAct}
            loadingAssigned={loadingAssigned}
            handleOpenCreateEdit={handleOpenCreateEdit}
            setActivityToOpen={setActivityToOpen}
          />
        </Box>

        <Box className={clsx(classes.background, classes.recentWrapper)}>
          <RecentActivities
            recentActivity={recentActivity}
            loadingRecent={loadingRecent}
            doctorAndPatientId={doctorAndPatientId}
            setActivityToOpen={setActivityToOpen}
            handleOpenCreateEdit={handleOpenCreateEdit}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </Box>
      </Box>
      <ActivityProvider>
        <Box className={classes.background} mt={'1rem'} mb={'1rem'}>
          <Tabs
            tabsBar={[
              <Box
                className={classes.tabHeader}
                display="flex"
                alignItems="center"
              >
                {customValue === 0 ? <MoodSel /> : <MoodUnsel />}
                <Text i18nKey="moodCheckIns">Mood check-ins</Text>
                {/* <Typography className={classes.unreadRed}>2</Typography> */}
              </Box>,
              <Box
                className={classes.tabHeader}
                display="flex"
                alignItems="center"
              >
                {customValue === 1 ? <ReflectionSel /> : <ReflectionUnsel />}
                <Text i18nKey="reflections">Reflections</Text>
              </Box>,
              <Box
                className={classes.tabHeader}
                display="flex"
                alignItems="center"
              >
                {customValue === 2 ? <SleepSel /> : <SleepUnsel />}
                <Text i18nKey="sleepCheckIns">Sleep check-ins</Text>
              </Box>,
              <Box
                className={classes.tabHeader}
                display="flex"
                alignItems="center"
              >
                {customValue === 3 ? <FormsSel /> : <FormsUnsel />}
                <Text i18nKey="forms">Forms</Text>
              </Box>,
            ]}
            tabsContent={[
              <Activity
                patient={PatientData?.patient}
                doctorAndPatientId={doctorAndPatientId}
                type={activityType.MOOD}
                activityType={TypeEnum.Mood}
                emojis={Moods}
                isFromPatient={true}
                setActivityToOpen={setActivityToOpen}
                handleOpenCreateEdit={handleOpenCreateEdit}
              />,
              <Activity
                patient={PatientData?.patient}
                doctorAndPatientId={doctorAndPatientId}
                type={activityType.REFLECTION}
                activityType={TypeEnum.Reflection}
                openAssign={openAssign}
                setOpenAssign={setOpenAssign}
                openActivity={openActivity}
                setOpenActivity={setOpenActivity}
                isFromPatient={true}
                setActivityToOpen={setActivityToOpen}
                handleOpenCreateEdit={handleOpenCreateEdit}
              />,
              <Activity
                patient={PatientData?.patient}
                doctorAndPatientId={doctorAndPatientId}
                type={activityType.SLEEP}
                activityType={TypeEnum.Sleep}
                emojis={sleepEmojies}
                isFromPatient={true}
                setActivityToOpen={setActivityToOpen}
                handleOpenCreateEdit={handleOpenCreateEdit}
              />,
              <Questionaires
                patient={PatientData?.patient}
                doctorAndPatientId={doctorAndPatientId}
                isPatient={true}
              />,
            ]}
            tabsMode="greenButtons"
            setCustomValue={setCustomValue}
            customValue={customValue}
            classNameTab={classes.tab}
            tabClassHeader={classes.headerTab}
            componetAlignedLeftWithTabs={
              <Box display="flex" alignItems="center">
                <AllActv style={{ marginRight: '10px' }} />
                <Text
                  i18nKey="allActivities"
                  style={{
                    fontSize: '30px',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    color: '#101828',
                    marginRight: '1rem',
                  }}
                >
                  All activities
                </Text>
              </Box>
            }
          />
        </Box>
      </ActivityProvider>
      <FormProvider {...methods}>
        <BasicModal
          open={createEditOpen}
          onClose={handleCloseCreateEdit}
          handleClose={handleCloseCreateEdit}
          isFromModal
          divider
          onSubmit={onSubmit}
          loading={loadingLog || updateLoading || formLoading}
          hasTitle={false}
          btnsPadding="2.5rem"
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '50vw',
                xl: '35vw',
              },
              height: '70vh',
            }}
          >
            <CreateEditForm
              activityType={activityToOpen}
              isEditMode={isEditMode}
              formResponse={formResponse}
              setFormResponse={setFormResponse}
              error={error}
              selectedProviders={selectedProviders}
              setSelectedProviders={setSelectedProviders}
              doctors={doctors}
            />
          </Box>
        </BasicModal>
      </FormProvider>
    </InboxProvider>
  );
}

export default PatientActivities;

export const GET_PATIENT_ACTIVITY = gql`
  query patientActivityByFilter(
    $patientId: ID!
    $doctorId: ID
    $type: String
    $date: Date
    $startDate: Date
    $endDate: Date
    $isCompleted: Boolean
    $isPending: Boolean
  ) {
    patientActivityByFilter(
      patientId: $patientId
      doctorId: $doctorId
      type: $type
      date: $date
      startDate: $startDate
      endDate: $endDate
      isCompleted: $isCompleted
      isPending: $isPending
    ) {
      title
      _id
      question
      options
      type
      notes
      photo
      sleep
      frequency {
        startDate
        endDate
        repetition {
          value
          unit
        }
        repeatOn
        frequencyType
      }
      assignedAt
      patientResponse {
        _id
        question
        response
        image
        sleepHours
        wakeHours
        notes
        createdAt
        tags
      }
      doctor {
        _id
        firstname
        middlename
        lastname
        picture
      }
      patient {
        _id
        firstname
        middlename
        lastname
        picture
      }
      activityUrl
      bookmarked
      formType
      blocks
    }
  }
`;

export const QUESTIONNAIRE_BY_PATIENT_ID = gql`
  query questionnairesByPatientId($patientId: ID) {
    questionnairesByPatientId(patientId: $patientId) {
      _id
      title
      description
      assignedAt
      patientResponseDate
      doctor {
        _id
        firstname
        lastname
      }
      patient {
        _id
      }
    }
  }
`;
