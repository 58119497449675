import Dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
// import i18next from 'i18next';

Dayjs.extend(utc);
Dayjs.extend(timezone);

// const Language = i18next.language || 'en'
const language = localStorage.getItem('i18nextLng') || 'en';
dayjs.locale(language === 'fr' ? 'fr' : 'en');

export function calculateAge(dateOnMS: string) {
  const age = Dayjs().diff(new Date(parseInt(dateOnMS, 10)), 'year');
  return age === 0 || age == undefined || age == null || isNaN(age) ? '-' : age;
}
export function hourFormat(_isodate: string | Date): string {
  return Dayjs(_isodate).format('HH:mm A');
}

export function dateFormatDependancy(
  date: string,
  format: string,
  timeZone?: string
) {
  if (format === '12-hour format') {
    return Dayjs(date).tz(timeZone).format('h:mm A');
  }
  return Dayjs(date).tz(timeZone).format('HH:mm');
}

export function convertMsToDate(dateOnMS: string) {
  const date = new Date(parseInt(dateOnMS, 10));
  const result = Dayjs(date).format('DD/MM/YYYY');
  return result;
}
export function convertMsToDateMonth(dateOnMS: string) {
  const date = new Date(parseInt(dateOnMS, 10));
  const result = Dayjs(date).format('MMMM YYYY');
  return result;
}

export function convertDateLikeMeet(dateNow: number) {
  const minutes = new Date(dateNow).getMinutes();
  if (minutes < 30) return new Date(new Date(dateNow).setMinutes(30));
  return new Date(
    new Date(new Date(dateNow).setMinutes(0)).setHours(
      new Date().getHours() + 1
    )
  );
}
export function stringToDate(dateOnMS: string) {
  return new Date(parseInt(dateOnMS, 10));
}
export function minimumDate(arrayOfDate: string[]) {
  Dayjs.extend(minMax);
  return Dayjs.min(arrayOfDate?.map((el) => Dayjs(el)))?.format('DD/MM/YYYY');
}
export function DisableMinuteWhenEmpty(minute: number) {
  if (minute > 0) return minute;
  return '00';
}
export function changeHour(hour: number) {
  if (hour.toString().length > 1) return hour;
  return '0' + hour.toString();
}
export function convertTime(
  dateWithOutTime: string,
  time: string,
  timeZone?: string
) {
  const [hours, minutes] = time.split(':').map((num) => parseInt(num, 10));
  let dateTime = Dayjs(dateWithOutTime)
    .set('hour', hours)
    .set('minute', minutes);
  if (timeZone) {
    dateTime = Dayjs.tz(dateTime.format('YYYY-MM-DD HH:mm:ss'), timeZone);
  }
  const timeZoneFormatted = dateTime.format('YYYY-MM-DD HH:mm:ss Z');
  return timeZoneFormatted;
}

export function convertDate(date: Date, timeZone?: string) {
  let _date = Dayjs(date);
  if (timeZone) {
    _date = Dayjs.tz(_date.format('YYYY-MM-DD HH:mm:ss'), timeZone);
  }
  const timeZoneFormatted = _date.format('YYYY-MM-DD HH:mm:ss Z');
  return timeZoneFormatted;
}

export function formatDate(date: string): string {
  return Dayjs(date).format('DD/MM/YYYY');
}
export function formatDatesFullMonth(date: string): string {
  return Dayjs(date).format('MMMM DD, YYYY');
}
export function formatDateMessage(date: string): string {
  const now = Dayjs();
  const inputDate = Dayjs(date);
  const today = now.startOf('day');
  const yesterday = today.subtract(1, 'day');
  const diffDays = today.diff(inputDate.startOf('day'), 'day');

  if (inputDate.isSame(today, 'day')) {
    return inputDate.format('HH:mm');
  } else if (inputDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (diffDays < 7) {
    return inputDate.format('dddd');
  } else {
    return inputDate.format('DD/MM/YY');
  }
}
export function fromNow(date: string): string {
  Dayjs.extend(relativeTime);
  return Dayjs(date).fromNow();
}

export function formatToFullDate(date: string): string {
  return Dayjs(date).format('MMMM D, YYYY h:mm A');
}
export function formatDates(date: string): string {
  return Dayjs(date).format('MMM DD, YYYY');
}
export function formatTime(date: string, doctor: { timeZone: string }): string {
  return Dayjs(date).format('h:mm A');
}
export function formatFullDate(date: string): string {
  return Dayjs(date).format('h:mm A');
}
export function formatToFullDateWithoutTime(date: string): string {
  return Dayjs(date).format('MMMM D, YYYY');
}

export function getHourAndMinutesFromDate(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return Dayjs(date).format(language === 'fr' ? 'HH:mm' : 'h:mm A');
}
export function getFullTimeFromDate(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return Dayjs(date).format('h:mm:ss');
}
export function getMonthAndDayNumber(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return Dayjs(date).format('MMM D');
}
export function getDayLetter(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return Dayjs(date).format('dddd');
}

export function formatToMonthAndYear(dateOnMS: string): string {
  return Dayjs(new Date(dateOnMS)).format('MMMM YYYY');
}
export function formatMonthYear(dateOnMS: string): string {
  return Dayjs(new Date(dateOnMS)).format('MMMM, YYYY');
}
export function getTimeDate(
  _date: { startDate: string; endDate: string },
  isTwelveFormat = false
) {
  return isTwelveFormat
    ? [
        Dayjs(_date?.startDate).format('h:mm A'),
        Dayjs(_date?.endDate).format('h:mm A'),
      ]
    : [
        Dayjs(_date?.startDate).format('HH:mm'),
        Dayjs(_date?.endDate).format('HH:mm'),
      ];
}
export function addStart(_date: { startDate?: string; endDate?: string }) {
  return Dayjs(_date?.startDate).format('HH:mm');
}
export function sortDays(days) {
  const map = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };
  return days.sort((a, b) => {
    return map[a] - map[b];
  });
}

export function differenceBetweenTwoDatesByHour(
  date1: string,
  date2: string
): string {
  const diff = Dayjs(date2).diff(Dayjs(date1), 'hour', true);
  const diffHours = Math.floor(diff);
  const diffMinutes = Math.round((diff - diffHours) * 60);
  const hours = `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
  const minutes = `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
  return `${diffHours ? hours : ''} ${diffMinutes ? minutes : ''}`;
}
export function convertdateNumberToMonth(date: string) {
  switch (date) {
    case '01':
      return 'Jan';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Apr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Aug';
    case '09':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return date;
  }
}
export function convertdateNumberToWeek(date: string) {
  switch (date) {
    case '1':
      return 'Mon';
    case '2':
      return 'Tue';
    case '3':
      return 'Wed';
    case '4':
      return 'Thu';
    case '5':
      return 'Fri';
    case '6':
      return 'Sat';
    case '7':
      return 'Sun';
    default:
      return date;
  }
}
export function dateIsExpired(date: string | Date) {
  return Dayjs(date).isBefore(Dayjs());
}
export function getTimeDifference(inputString) {
  const times = inputString.split(' - ');

  if (times.length !== 2) {
    console.log(
      'Invalid input format. Please use "start time - end time" format.'
    );
  }

  const startTime = times[0];
  const endTime = times[1];

  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);

  const totalStartMinutes = startHours * 60 + startMinutes;
  const totalEndMinutes = endHours * 60 + endMinutes;

  const timeDifference = totalEndMinutes - totalStartMinutes;

  return timeDifference;
}
export function getDayName(dateString: number | string | Date) {
  const date = Dayjs(dateString);
  return date.format('dddd').toLowerCase();
}

export function combineDates(date1: Date, date2: Date) {
  const year = date1.getFullYear();
  const month = date1.getMonth();
  const day = date1.getDate();
  const hours = date2.getHours();
  const minutes = date2.getMinutes();
  const seconds = date2.getSeconds();
  return new Date(year, month, day, hours, minutes, seconds);
}
export function generateTextFromAppointment(appointment) {
  if (!!!appointment.endOn && !!!appointment.occurrence) {
    return null;
  }

  let text = '';
  if (appointment.numberOfRepeat === 1) {
    if (appointment.repeatEvery === 'day') {
      text = 'Daily';
    } else if (appointment.repeatEvery === 'week') {
      text = 'Weekly';
    } else if (appointment.repeatEvery === 'month') {
      text = 'Monthly';
    }
  } else {
    text =
      'Every ' +
      appointment.numberOfRepeat +
      ' ' +
      appointment.repeatEvery +
      's';
  }
  if (
    appointment.repeatOn &&
    appointment.repeatOn.length === 7 &&
    appointment.repeatEvery !== 'day'
  ) {
    text += ' Daily';
  } else if (
    appointment.repeatOn &&
    appointment.repeatOn.length > 0 &&
    appointment.repeatEvery !== 'day'
  ) {
    text += ' on ' + appointment.repeatOn.join(', ');
  }
  if (appointment.occurence) {
    text += ', until ' + appointment.occurence + ' occurrences';
  } else if (appointment.endOn) {
    text += ', until ' + dayjs(appointment.endOn).format('MMMM DD, YYYY');
  }
  return text;
}

export function transformArrayTimeZone(appointments, timeZone) {
  return appointments?.map((appointment) => {
    return {
      ...appointment,
      startDate: Dayjs(
        Dayjs(appointment?.startDate).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss')
      )
        .toDate()
        .getTime(),
      endDate: Dayjs(
        Dayjs(appointment?.endDate).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss')
      )
        .toDate()
        .getTime(),
    };
  });
}
export function transformObjectTimeZone(appointment, timeZone) {
  return {
    ...appointment,
    startDate: Dayjs(
      Dayjs(appointment?.startDate).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss')
    )
      .toDate()
      .getTime(),
    endDate: Dayjs(
      Dayjs(appointment?.endDate).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss')
    )
      .toDate()
      .getTime(),
  };
}
export function convertObjTimes(obj: any) {
  const startingTime = new Date();
  const offsetMinutesStart = -startingTime.getTimezoneOffset() / 60;
  if (offsetMinutesStart > 0) {
    return {
      ...obj,
      startDate: Dayjs(obj.startDate)
        .subtract(offsetMinutesStart, 'hour')
        .toDate()
        .getTime(),
      endDate: Dayjs(obj.endDate)
        .subtract(offsetMinutesStart, 'hour')
        .toDate()
        .getTime(),
    };
  }
  return {
    ...obj,
    startDate: Dayjs(obj.startDate)
      .add(Math.abs(offsetMinutesStart), 'hour')
      .toDate()
      .getTime(),
    endDate: Dayjs(obj.endDate)
      .add(Math.abs(offsetMinutesStart), 'hour')
      .toDate()
      .getTime(),
  };
}
export const formatInboxDate = (date: Date | null | undefined) => {
  const currentDate = dayjs();
  const inputDate = dayjs(date);

  if (inputDate.isSame(currentDate, 'day')) {
    return inputDate.format('HH:mm');
  }

  if (inputDate.isSame(currentDate, 'week')) {
    return inputDate.format('dddd');
  }

  return inputDate.format('D/M/YY');
};
