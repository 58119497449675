import { Box, FormLabel } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import AssignedTo from 'components/ui/AssignedTo';
import InboxInput from '../components/input/input';
import { useProvider } from '../state/Provider';
import useStyles from './style';
import {
  getContact_getStaffsByDoctor,
  getContact_getStaffsByDoctor_user,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getContact';
import useGetContact from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetContact';
import Input from 'components/ui/Inputs';
import { enumToArray } from 'utils/enumToArry';
import { removeEmptyFields } from 'utils/removeEmptyFields';
import { typeConversationEnum } from '@eggmed/graphql-client/__generated__/globalTypes';
import BasicModal from 'components/ui/Modal';
import { useForm, FormProvider } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import { useLocation } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import ScrollBar from 'components/ui/ScrollBar';
import TaskInput from 'pages/Taskpage/TaskAdd/TaskInput';
import AttachmentItemPreview from 'pages/Taskpage/components/AttachmentItemPreview';
import { MAX_SIZE } from 'components/ui/UploadFileUi/AnimatedProgressBar/AnimatedProgressBar';
import UploadFilePreview from 'components/ui/UploadFileUi/UploadFilePreview';
import { ReactComponent as PdfIcon } from 'assets/icons/PdfIconFile.svg';
import { ReactComponent as Download } from 'assets/icons/download-01.svg';
import { ReactComponent as Delete } from 'assets/icons/trash-01.svg';
import AttachmentInputFile from 'pages/Taskpage/components/AttachementInputFile';
export interface IContactProps {
  firstname: string;
  middlename: string;
  lastname: string;
  _id: string;
  email: string;
  picture: string;
}

interface MenuItem {
  url: string;
  avatar: React.ReactNode;
  name: string;
  onClick: () => void;
}

const schema = yup.object().shape({
  subject: yup
    .string()
    .min(2, 'Title must be at least 2 characters')
    .required('Title is a required field'),
  contact: yup.array().required(),
  message: yup
    .string()
    .min(2, 'Message must be at least 2 characters')
    .required('Message is a required field'),
  files: yup.array(),
});

const CreateNewThreadModals = ({
  isPatient,
  ...props
}: {
  isPatient?: boolean;
  contact?: IContactProps[];
  subject?: string;
  message?: string;
}) => {
  const { t } = useTranslation();
  const { contact, subject, message } = props || {};
  const [fetchUserByDoctor, { data }] = useLazyQuery(USER_BY_DOCTOR);
  React.useEffect(() => {
    if (contact && contact?.length > 0) {
      fetchUserByDoctor({
        variables: {
          id: contact && contact[0]?._id,
        },
      });
    }
  }, [contact]);
  const [currentFile, setCurrentFile] = React.useState();
  const [files, setFiles] = React.useState<any>([]);
  const contactUser = data?.userById;
  const { triggerToast } = useToast();
  const { doctor } = useAuth();
  const classes = useStyles();
  const {
    handleCloseCreateNewThreadModal,
    handelCreateThreadConversation,
    modal,
    modalType,
    loading,
    handleSelectedThead,
  } = useProvider();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject,
      contact: contactUser
        ? convertObjectsFormat([contactUser] || [], 'Doctors')
        : [],
      message,
      files: [],
      type: null,
    },
  });
  const { triggerSnack } = useSnackbar();
  useEffect(() => {
    !modal && setFiles([]);
  }, [modal]);
  useEffect(() => {
    if (contactUser) {
      methods.reset({
        ...methods.watch(),
        contact: convertObjectsFormat([contactUser], 'Doctors'),
        subject,
      });
    }
  }, [contactUser, subject]);

  const { register, errors, control, reset } = methods;
  const { data: contactsList } = useGetContact('owner');

  const staffArray = useMemo(
    () =>
      convertObjectsFormat(
        contactsList?.getStaffsByDoctor || [],
        'Team members'
      ),
    [contactsList]
  );
  const patientArray = useMemo(
    () => convertObjectsFormat(contactsList?.patientUser || [], 'Clients'),
    [contactsList]
  );
  const doctorsArray = useMemo(
    () => convertObjectsFormat(contactsList?.getUserByRole || [], 'Providers'),
    [contactsList]
  );

  const handleDelete = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };
  const handleDownload = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
  const options = isPatient
    ? [...doctorsArray]
    : [...staffArray, ...doctorsArray, ...patientArray];
  const commonProps = { register, control, errors, variant: 'filled' };
  const getMenuItems = (url: string, index: number): MenuItem[] => [
    /* {
      url,
      avatar: <Rename style={{ marginRight: '8px' }} />,
      name: 'Rename',
      onClick: () => console.log(`Rename file at ${url}`),
    },*/
    {
      url,
      avatar: <Download style={{ marginRight: '8px' }} />,
      name: 'Download',
      onClick: () => handleDownload(url, 'file.pdf'),
    },
    {
      url,
      avatar: <Delete style={{ marginRight: '8px' }} />,
      name: 'Delete',
      onClick: () => handleDelete(index),
    },
  ];
  const onSubmit = async (form: any) => {
    const getIdContact = form.contact.map(
      (item: getContact_getStaffsByDoctor_user) => {
        return item._id;
      }
    );
    const thread = await handelCreateThreadConversation({
      variables: {
        ...removeEmptyFields(form),
        message: { text: form.message, files: form.files },
        contact: props?.contact?.[0]?._id ? contactUser?._id : getIdContact,
      },
    });
    handleSelectedThead(thread?.data?.createThreadConvertation?._id);
    triggerSnack();
    setTimeout(() => handleCloseCreateNewThreadModal(), 1000);
  };

  return (
    <FormProvider {...methods}>
      <BasicModal
        open={modal}
        onClose={handleCloseCreateNewThreadModal}
        handleClose={handleCloseCreateNewThreadModal}
        className={classes.newThreadModal}
        onSubmit={onSubmit}
        title={t('newMessage')}
        isRegularModal={true}
        titlePadding="2rem"
        isFromModal
        divider
        isPatient={isPatient}
        isSlide={true}
        submitText={'send'}
        loading={loading}
      >
        <Box {...props} className={classes.responsiveMobile}>
          <ScrollBar className={classes.scroll}>
            <Box px="2rem">
              <Box mt={-2}>
                <Box className={classes.filed}>
                  <FormLabel className={clsx(classes.label)}>
                    <Text className={clsx(classes.label)} i18nKey="Subject">
                      Subject
                    </Text>
                  </FormLabel>
                  <Box className={classes.inputBox}>
                    <Controller
                      data-cy="subject"
                      control={control}
                      name="subject"
                      render={({ onChange, value }) => (
                        <TaskInput
                          {...commonProps}
                          type="text"
                          data-cy="inbox-subject"
                          name="subject"
                          onChange={onChange}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              {/* <Box className={classes.filed}>
            <FormLabel className={clsx(classes.label)}>Type</FormLabel>
            <Box className={classes.inputBox}>
              <Input
                type="select"
                name="type"
                register={register}
                control={control}
                variant="filled" 
                options={enumToArray(typeConversationEnum)}
              />
            </Box>
          </Box> */}

              <Box className={classes.filed}>
                <FormLabel className={clsx(classes.label)}>
                  <Text className={clsx(classes.label)} i18nKey="recipient">
                    Recipient
                  </Text>
                </FormLabel>
                <Box className={classes.inputBox}>
                  <Controller
                    data-cy="add-contact"
                    control={control}
                    name="contact"
                    render={({ onChange, value }) => (
                      <Symptoms
                        options={options}
                        values={value}
                        setValues={onChange}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box className={classes.filed}>
                <FormLabel className={clsx(classes.label)}>
                  <Text className={clsx(classes.label)} i18nKey="Message">
                    Message
                  </Text>
                </FormLabel>
                <Box className={classes.inputBox}>
                  <Controller
                    data-cy="message"
                    control={control}
                    name="message"
                    render={({ onChange, value }) => (
                      <InboxInput
                        {...commonProps}
                        type="area"
                        name="message"
                        fullWidth
                        multiline
                        onChange={onChange}
                        InputProps={{
                          rows: 15,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Controller
                data-cy="files"
                control={control}
                name="files"
                render={({ onChange, value }) => (
                  <AttachmentInputFile
                    data-cy="file-add"
                    my={2}
                    namePart1={t('ClickUpload')}
                    namePart2={t('Drag&Drop')}
                    max_size={t('MaxSize')}
                    isActionable
                    time={files?.length > 0}
                    createdAt={new Date()}
                    handleActionDragAndDrop={(event: any) => {
                      const newFiles = event.target.files
                        ? Array.from(event.target.files)
                        : [];
                      setCurrentFile(null);
                      if (event?.dataTransfer?.files[0].size < MAX_SIZE) {
                        const newList = [
                          ...files,
                          event.dataTransfer?.files[0],
                        ];
                        setFiles(newList);
                      }
                      setCurrentFile(event.dataTransfer?.files[0]);
                      onChange([...(value || []), ...newFiles]);
                    }}
                    handleAction={(event: any) => {
                      const newFiles = event.target.files
                        ? Array.from(event.target.files)
                        : [];
                      setCurrentFile(null);
                      if (event?.target?.files[0].size < MAX_SIZE) {
                        const newList = [...files, event.target?.files[0]];
                        setFiles(newList);
                      }
                      setCurrentFile(event.target?.files[0]);
                      onChange([...(value || []), ...newFiles]);
                    }}
                  />
                )}
              />
              {currentFile && (
                <UploadFilePreview
                  file={currentFile}
                  url={''}
                  isEdit={false}
                  icon={<PdfIcon style={{ width: 'auto', height: 'auto' }} />}
                  progress={100}
                  setCurrentFile={setCurrentFile}
                  show={true}
                  onClose={() => {}}
                />
              )}

              {files &&
                files.map((file, index) => (
                  <AttachmentItemPreview
                    fileType={file.type}
                    fileSize={file.size}
                    file={file}
                    isEdit={false}
                    url={URL.createObjectURL(file)}
                    fileName={file.name}
                    menuItems={getMenuItems(URL.createObjectURL(file), index)}
                  />
                ))}
            </Box>

            {/*  <Box className={classes.filed}>
                <FormLabel className={clsx(classes.label)}>
                  <Text className={clsx(classes.label)} i18nKey="Files">
                    Files
                  </Text>
                </FormLabel>
                <Controller
                  data-cy="files"
                  control={control}
                  name="files"
                  render={({ onChange, value }) => (
                    <InboxInput
                      type="uploadFiles"
                      name="files"
                      files={value || []}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const newFiles = event.target.files
                          ? Array.from(event.target.files)
                          : [];
                        onChange([...(value || []), ...newFiles]);
                      }}
                      isActionable
                      className={classes.inputBox}
                    />
                  )}
                />
              </Box>
            </Box>*/}
          </ScrollBar>
        </Box>
      </BasicModal>
    </FormProvider>
  );
};

export default CreateNewThreadModals;

export const USER_BY_DOCTOR = gql`
  query getUserByDoctor($id: ID!) {
    userById(id: $id) {
      _id
      firstname
      lastname
      middlename
      email
      picture
    }
  }
`;
