import React, { FC, useEffect, useRef, useMemo } from 'react';
import ScrollBar from 'components/ui/ScrollBar';
import { Box, Divider, Typography } from '@material-ui/core';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useStyles from './style';
import MessageBubble from './MessageBubble ';
import HeadMessageList from './HeadMessageList';
import { GetThreadMessages_getThreadMessages } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import { useProvider } from 'pages/InboxPage/state/Provider';
import { formatInboxDate } from 'utils/dateUtils';

interface IMessagesListProps {
  data: GetThreadMessages_getThreadMessages[];
  patient?: boolean;
  setFilesHidden: (value: boolean) => void;
}

interface IUserProps {
  _id: string;
  user?: { _id: string };
}

const MessagesList: FC<IMessagesListProps> = ({
  data,
  setFilesHidden,
  patient,
}) => {
  const classes = useStyles({ patient });
  const { user } = useAuth();
  const { _id } = user;
  const messagesEndRef = useRef<any>();
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView();
  };

  function isTimeGapSignificant(currentMessage, previousMessage) {
    const timeDifference =
      currentMessage?.createdAt - previousMessage?.createdAt;
    return timeDifference > 5 * 60 * 1000;
  }

  useEffect(scrollToBottom, [data]);
  const MessagesMap = useMemo(() => {
    return data
      .map((item, index) => {
        const isSameUser =
          index > 0 && data[index - 1]?.user?._id === item.user?._id;
        const isRecent =
          index === 0 || isTimeGapSignificant(item, data[index - 1]);
        const isLastInGroup =
          index === data.length - 1 ||
          data[index + 1]?.user?._id !== item.user?._id;
        const isFirstInGroup = index === 0 || !isSameUser || isRecent;

        return (
          <React.Fragment key={item._id}>
            <MessageBubble
              isFirstInGroup={isFirstInGroup}
              isLastInGroup={isLastInGroup}
              data={item}
              isOwner={
                _id === item.user?._id ||
                (user as IUserProps)?.user?._id === item.user?._id
              }
            />
            {isRecent && (
              <Typography align="center" className={classes.dateStyle}>
                {formatInboxDate(new Date(item.createdAt))}
              </Typography>
            )}
          </React.Fragment>
        );
      })
      .reverse();
  }, [data]);

  return (
    <Box
      className={classes.fullHeight}
      style={{
        overflow: 'hidden',
      }}
    >
      <HeadMessageList setFilesHidden={setFilesHidden} patient={patient} />
      <Divider style={{ marginInline: '2rem' }} />
      <ScrollBar className={classes.messageListScroll}>
        <div ref={messagesEndRef} />
        {data && MessagesMap}
      </ScrollBar>
    </Box>
  );
};

export default MessagesList;
