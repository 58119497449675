import { gql, useMutation } from '@apollo/client';

export const DELETE_RATE = gql`
  mutation deleteRate($rateId: ID) {
    deleteRate(rateId: $rateId) {
      session
    }
  }
`;

export default function useDeleteRate() {
  const [deleteRate, result] = useMutation(DELETE_RATE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          rates() {
            return data?.deleteRate;
          },
          rateByRole() {
            return data?.deleteRate;
          },
        },
      });
    },
  });
  return { deleteRate, ...result };
}
