import { Grid } from '@material-ui/core';
import React from 'react';
import TopSection from './TopSection';
import BottomSection from './BottomSection';

function SettingsTab({ role }) {
  const [topSection, setTopSection] = React.useState(true);
  const [bottomSection, setBottomSection] = React.useState(true);

  return (
    <Grid container>
      <TopSection
        role={role}
        isSelected={topSection}
        handleSelect={() => setTopSection((prev) => !prev)}
      />
      <BottomSection
        role={role}
        isSelected={bottomSection}
        handleSelect={() => setBottomSection((prev) => !prev)}
      />
    </Grid>
  );
}

export default SettingsTab;
