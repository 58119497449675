import { gql, useMutation } from '@apollo/client';
import { ME_QUERY } from 'contexts/AuthContext';

const UPDATE_PATIENT_ACTIVITY = gql`
  mutation updatePatientActivities(
    $id: ID!
    $patientActivitiesInput: PatientEditActivitiesInput
  ) {
    updatePatientActivities(
      id: $id
      patientActivitiesInput: $patientActivitiesInput
    ) {
      _id
    }
  }
`;

const useUpdatePatientActivity = () => {
  return useMutation(UPDATE_PATIENT_ACTIVITY);
};

const UPDATE_PATIENT_TIMEZONE = gql`
  mutation updatePatientTimezone($timeZone: String!) {
    updatePatientTimeZone(timeZone: $timeZone) {
      _id
      timeZone
    }
  }
`;

export const useUpdatePatientTimezone = () => {
  return useMutation(UPDATE_PATIENT_TIMEZONE, {
    update: (cache, { data: { timeZone } }) => {
      cache.modify({
        fields: {
          patient() {
            return timeZone;
          },
        },
      });
    },
    refetchQueries: [{ query: ME_QUERY }],
    awaitRefetchQueries: true,
  });
};

export default useUpdatePatientActivity;
