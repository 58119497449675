import { gql, useMutation } from '@apollo/client';
import { OWNER_ROLES } from 'pages/AdminPage/TabsContent/TeamSetting/permissionstab/PermissionSettings';

const UPDATE_STAFF_PERMISSION = gql`
  mutation updateStaffPermission(
    $role: String
    $feature: String
    $action: String
    $actionIndex: Int
  ) {
    updateStaffPermission(
      role: $role
      feature: $feature
      action: $action
      actionIndex: $actionIndex
    )
  }
`;
const UPDATE_STAFF_ADD_PERMISSION = gql`
  mutation updateIsAddPermission(
    $role: String
    $feature: String
    $isAdd: Boolean
  ) {
    updateIsAddPermission(role: $role, feature: $feature, isAdd: $isAdd)
  }
`;

const useUpdateStaffPermission = () => {
  const [updateStaffPermission, { loading }] = useMutation(
    UPDATE_STAFF_PERMISSION
  );
  const [updateIsAddPermission, { loading: loadingAdd }] = useMutation(
    UPDATE_STAFF_ADD_PERMISSION
  );
  async function handleUpdateUserPermission(
    role: string,
    feature: string,
    action: string,
    actionIndex: number
  ) {
    try {
      return await updateStaffPermission({
        variables: { role, feature, action, actionIndex },
        refetchQueries: [
          {
            query: OWNER_ROLES,
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }

  async function handleUpdateAddPermission(
    role: string,
    feature: string,
    isAdd: boolean
  ) {
    try {
      return await updateIsAddPermission({
        variables: { role, feature, isAdd },
        refetchQueries: [
          {
            query: OWNER_ROLES,
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return {
    handleUpdateUserPermission,
    loading,
    handleUpdateAddPermission,
    loadingAdd,
  };
};

export { UPDATE_STAFF_PERMISSION };
export default useUpdateStaffPermission;
