import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Tooltip } from '@material-ui/core';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';

import { makeStyles } from '@material-ui/core/styles';
import { useParams, Redirect } from 'react-router-dom';
import { Editor } from 'draft-js';
import * as yup from 'yup';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import BasicModal from 'components/ui/Modal';
import useConditions from 'graphql/operations/patientOperations/useConditions';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  convertStringToEditorState,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertToRawAndStringify,
  convertToPlainText,
} from '@eggmed/common/utils/DraftUtils';
import { formatDate, changeKeyValue } from 'utils';
import { EditorState } from 'draft-js';
import { MedicationConditionsForm } from './ConditionForm';
import Button from 'components/ui/Button';
import IconButton from 'components/ui/IconButton';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import Menu from 'components/ui/Menu';
import DataLoader from 'components/ui/DataLoader';
import { IPatientProps } from 'components/PatientProfile/PatientTabs/PatientTabsContent';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import TrashIcon from 'assets/TrashIcon';
import Skeleton from 'components/ui/Skeleton';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyCondition } from 'assets/emptyState/conditions.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import ConditionModal from './ConditionModal';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSnackbar } from 'hooks/useSnackbar';
import { ReactComponent as TooltipIcon } from 'assets/TooltipIcon.svg';
import useCheckPermission from 'contexts/PermissionContext';

export const formSchema = {
  dateAdded: yup
    .string()
    .required('Date added field required')
    .when('type', {
      is: (type) => !type,
      then: yup
        .date()
        .max(yup.ref('dateEnded'), "Date can't be after date ended"),
    }),
  dateEnded: yup.string().when('type', {
    is: (type) => !type,
    then: yup.date().required('Date ended is a required field'),
  }),
};

export const diagnosisSchema = yup.object().shape({
  dateAdded: yup.string().required('Date added field required'),
  // .when('type', {
  //   is: (type) => !type,
  //   then: yup
  //     .string()
  //     .max(yup.ref('dateEnded'), "Date added can't be after date ended"),
  // }),
  dateEnded: yup.string().when('type', {
    is: (type) => !type,
    then: yup.string().required('Date ended is a required field'),
  }),
  diagnosisCode: yup.object().required('Code is required'),
});
export const fieldsSchema = yup.object().shape({
  array: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});

const useStyles = makeStyles((theme) => ({
  bigTitle: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: '#425364',
    marginTop: '0.5em',
  },
  type: {
    backgroundColor: '#F9FAFB',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #EAECF0',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  typeCurrent: {
    backgroundColor: '#EFF8FF',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #B2DDFF',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#175CD3',
  },
  tableHeaderText: {
    fontSize: '22px',
    lineHeight: '25px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: '#425364',
  },
  tableColumnLabel: {
    fontSize: '20px',
    lineHeight: '25px',
    color: '#425364',
  },
  secondary: {
    color: '#425364',
  },
  modal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xs')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: '70vh',
    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: '70vh',
    },
  },
  editButton: {
    display: 'flex',
    border: '1px solid #425364',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: '6px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '16px',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    color: '#425364',
    padding: '5.5px 16.5px',
    '&:hover ': {
      border: '1px solid White',
      backgroundColor: '#2DC76D',
      color: 'white',
      '& svg': {
        '& path': {
          stroke: 'white',
        },
      },
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
  },
  editIcon: {
    width: '15px',
    height: '25px',
    paddingRight: '5px',
    paddingBottom: '2px',
  },
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: ({ isPatient }: { isPatient: boolean }) =>
      isPatient ? '25px' : '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  treatmentToDate: {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '250px',
    overflow: 'hidden',
  },
  conditionstModal: {
    width: '50vw',
    [theme.breakpoints.up('lg')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '37vw',
    },
  },
}));

export default function PatientConditions({
  patientId,
  isPatient = false,
}: IPatientProps): JSX.Element {
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Clients',
    'edit',
    patientId
  );
  const { isEnabled: isEnabledDelete } = useCheckPermission(
    'Clients',
    'delete',
    patientId
  );
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ isPatient });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = React.useState(1);
  const [fetchDiadgnosis, { data: diagnosisCode }] =
    useLazyQuery(DIAGNOSIS_CODES);
  const [editMode, setEditMode] = React.useState(false);

  function handlePagination(e, _page) {
    setPage(_page);
  }
  const methods = useForm({
    defaultValues: editMode
      ? {
          ...selectedRow,
          description: selectedRow?.diagnosisCode?.description,
          type: selectedRow?.type === 'Current' ? true : false,
          treatment:
            selectedRow?.treatments &&
            editorStateFromStringifiedRaw(selectedRow?.treatments, false),
        }
      : {
          isDisplayed: false,
          isPrimary: false,
          type: true,
        },
    resolver: yupResolver(diagnosisSchema),
  });

  React.useEffect(() => {
    if (editMode) {
      methods.reset({
        ...selectedRow,
        type: selectedRow?.type === 'Current' ? true : false,
        description: selectedRow?.diagnosisCode?.description,
        treatment:
          selectedRow?.treatments &&
          editorStateFromStringifiedRaw(selectedRow?.treatments, false),
      });
    }
  }, [editMode, methods.reset, selectedRow]);
  async function onSubmit(data) {
    const { treatment, description, ...rest } = data || {};
    if (editMode) {
      await handleEditCondition(
        {
          ...rest,
          type: rest?.type ? 'Current' : 'Past',
          diagnosisCode: {
            code: rest?.diagnosisCode?.code,
            description: data?.description,
          },
          _id: selectedRow?._id,
          dateEnded: rest?.type ? '' : rest?.dateEnded,
        },
        data?.treatment
      );
    } else {
      await handleAddCondition(
        {
          ...rest,
          type: rest?.type ? 'Current' : 'Past',
          diagnosisCode: {
            code: rest?.diagnosisCode?.code,
            description: data?.description,
          },
          dateEnded: rest?.type ? '' : rest?.dateEnded,
        },
        data?.treatment
      );
    }

    closeEditModal();
    triggerSnack(`Diagnosis ${editMode ? 'updated' : 'added'} successfully `);
  }
  const {
    handleAddCondition,
    handleDeleteCondition,
    handleEditCondition,
    data: PatientConditionsData,
    error: ConditionsError,
    loading: ConditionsLoading,
    loadingAddMutation,
    loadingEditMutation,
    loadingDelete,
  } = useConditions({ patientId });

  function closeEditModal() {
    setOpen(false);
    setSelectedRow(null);
    setEditMode(false);
  }
  function openModal() {
    setSelectedRow(null);
    setOpen(true);
    fetchDiadgnosis();
    methods?.reset({
      isDisplayed: false,
      isPrimary: false,
      type: true,
    });
  }
  function openEditModal() {
    setEditMode(true);
    handleCloseMenu();
    setOpen(true);
    fetchDiadgnosis();
  }

  const handelDeleteCondition = async (condition: string) => {
    await handleDeleteCondition(condition);
    setOpenDeleteModal(false);
    handleCloseMenu();
    triggerSnack('Diagnosis removed successfully');
  };
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const MenuElementsData = [
    {
      cy: 'edit-condition',
      avatar: <EditIcon />,
      name: t('Edit'),
      onClick: () => (isEnabled ? openEditModal() : undefined),
      isDisabled: !isEnabled,
    },
    {
      cy: 'delete-condition',
      avatar: <TrashIcon width="20px" height="20px" fill="#B42318" />,
      name: t('Delete'),
      isRed: true,
      onClick: () => (isEnabledDelete ? handleOpenDeleteModal() : undefined),
      isDisabled: !isEnabledDelete,
    },
  ];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };
  const columns: IColumn<any>[] = [
    {
      id: 'diagnosisCode',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Typography>ICD-10 code</Typography>
        </Box>
      ),
      align: 'left',
      freeze: true,
      format: (value, { isPrimary, type }) => (
        <Box display="flex" alignItems="center" style={{ gap: '5px' }}>
          <Typography>{value?.code}</Typography>
          {isPrimary && (
            <Tooltip title="Primary diagnosis">
              <Typography variant="caption" className={classes.type}>
                Primary
              </Typography>
            </Tooltip>
          )}
          {type === 'Current' && (
            <Tooltip title="Primary diagnosis">
              <Typography variant="caption" className={classes.typeCurrent}>
                Current
              </Typography>
            </Tooltip>
          )}
        </Box>
      ),
    },
    {
      id: 'diagnosisCode',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Typography>Diagnosis</Typography>
        </Box>
      ),
      align: 'left',
      format: (value) => <Typography>{value?.description}</Typography>,
    },
    {
      id: 'type',
      label: (
        <Box
          className={classes.tableColumnLabel}
          display="flex"
          alignItems="center"
          style={{ gap: '5px' }}
        >
          <Typography>Period</Typography>
          <Tooltip
            title={
              <Typography style={{ fontSize: '14px' }}>
                Only current diagnosis will be included on claims
              </Typography>
            }
            placement="top-start"
          >
            <TooltipIcon />
          </Tooltip>
        </Box>
      ),
      align: 'left',
      freeze: true,
      format: (value, { dateAdded, dateEnded }) => (
        <Typography>
          {formatDate(dateAdded)} -{' '}
          {value === 'Current' ? 'Present' : formatDate(dateEnded)}
        </Typography>
      ),
    },
  ];
  if (isEnabled || isEnabledDelete) {
    columns.push({
      id: '_id',
      label: (
        <Box className={classes.tableColumnLabel}>
          <Text i18nKey="action">Action</Text>
        </Box>
      ),
      align: 'left',
      format: (value, row) => (
        <MoreIcon
          style={{
            cursor: 'pointer',
            fontSize: '24px',
            color: '#425364',
            padding: '10px',
          }}
          onClick={(e: any) => onOpenMenu(e, row)}
        />
      ),
    });
  }

  return (
    <DataLoader
      loading={ConditionsLoading}
      error={ConditionsError}
      data={PatientConditionsData}
    >
      <>
        <Box display="flex" pb="15px" alignItems="center" pt="13px">
          <Skeleton loading={ConditionsLoading}>
            <Typography className={classes.title}>Diagnosis</Typography>
          </Skeleton>
          {!!PatientConditionsData?.conditions?.length && (
            <Box
              display="flex"
              justifyContent={'flex-end'}
              alignContent={'center'}
              alignItems={'center'}
              width="100%"
            >
              <Tooltip
                title={
                  !isEnabled
                    ? 'This action is disabled because your role does not have the required permissions'
                    : ''
                }
                placement="bottom-end"
              >
                <Button
                  className={classes.addTask}
                  style={{ opacity: !isEnabled && '0.4' }}
                  onClick={isEnabled ? openModal : undefined}
                  data-cy="add-condition"
                >
                  <AddTaskIcon
                    style={{ paddingRight: '6px', width: '1.3rem' }}
                  />
                  <Text i18nKey="add">Add</Text>
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>

        <>
          <Table<any>
            loading={ConditionsLoading}
            pagination
            limit={5}
            page={page}
            columns={columns}
            EmptyState={
              <>
                {isEnabled && (
                  <Box py="5rem">
                    <EmptyState
                      icon={<EmptyCondition />}
                      title={t('No diagnosis')}
                      text={t('to add a diagnosis')}
                      onClick={openModal}
                      isText
                      isClickLeft
                    />
                  </Box>
                )}
              </>
            }
            handlePagination={handlePagination}
            data={
              PatientConditionsData ? PatientConditionsData?.conditions : []
            }
          />
          <Menu<any>
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={!!anchorEl}
            handleMenuClose={handleCloseMenu}
            MenuElements={MenuElementsData}
          />
        </>
        <ModalDelete
          text="diagnosis"
          open={openDeleteModal}
          onClose={() => handleCloseDeleteModal()}
          onDelete={() => handelDeleteCondition(selectedRow?._id)}
          isPatient={isPatient}
          loading={loadingDelete}
        />
        <FormProvider {...methods}>
          <BasicModal
            isSlide
            open={open}
            onClose={closeEditModal}
            isFromModal
            title={editMode ? t('Edit diagnosis') : t('Add a diagnosis')}
            titlePadding="2rem"
            handleClose={closeEditModal}
            className={classes.conditionstModal}
            onSubmit={onSubmit}
            loading={loadingAddMutation || loadingEditMutation}
          >
            <ConditionModal diagnosisCode={diagnosisCode?.diagnosisCodes} />
          </BasicModal>
        </FormProvider>
      </>
    </DataLoader>
  );
}
export const DIAGNOSIS_CODES = gql`
  query diagnosisCodes {
    diagnosisCodes {
      code
      description
    }
  }
`;
