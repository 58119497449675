import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
   locationItem: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover $hoverButton': {
      opacity: 1, 
    },
  },
   locationItemCreate: {
     display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 4px 4px 10px',
    gap: '8px',
    background: 'white',
    borderRadius: '4px',
    order: 2,
    flexGrow: 0,
    '&:hover': {
      background: '#F2F9FF',
    },
    position: 'relative',
    cursor: 'pointer',
    borderBottom: ' 1px solid #D0D5DD',
    '&:hover $hoverButton': {
      opacity: 1, 
    },
  },
  hoverButton: {
    opacity: 0, 
  },
  emptyLocationText:{
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px', 
      color: '#98A2B3', 
  },
  emptyLocation: {
  boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 8px',
      gap: '14px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D0D5DD',
      boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      borderRadius: '8px',
       width: '100%',
  },
  header: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#475467',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    marginBottom: '1rem',
  },
  assign: {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#FFFFFF',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    marginLeft: '2px',
  },
  assignButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '2px',
    backgroundColor: '#0265DC',
    borderRadius: '4px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    width: theme.typography.pxToRem(76),
    '&:hover': {
      backgroundColor: '#0265DC',
    },
  },
  unassignButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 4px',
    gap: '2px',
    backgroundColor: '#D92D20',
    borderRadius: '4px',
    flex: 'none',
    order: 1,
    width: theme.typography.pxToRem(76),
    flexGrow: 0,
    '&:hover': {
      backgroundColor: '#D92D20',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    gap: '6px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    cursor: 'pointer',
  },
  titleAction: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#D92D20',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  customTooltip: {
    backgroundColor: '#FFFFFF',
    color: '#475467',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '10px',
    display: 'flex',
  },
  customTooltipOneItem: {
    color: '#475467',
    display: 'flex',
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
  type: {
    backgroundColor: '#F9FAFB',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #EAECF0',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  actions: {
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionsVisible: {
    opacity: 1,
  },
  seeMoreChip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D5DD',
    color: '#475467',
    marginRight: '0.5rem',
    borderRadius: '6px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    width: 'auto',
    minWidth: '300px',
  },
  hiddenTagMeasure: {
    position: 'absolute',
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    height: 0,
    overflow: 'hidden',
  },
  chip: {
    marginRight: theme.spacing(0.5),
    borderRadius: '6px',
    whiteSpace: 'nowrap',
  },
  tooltipChip: {
    borderRadius: 6,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  tooltipChipSpacing: {
    margin: theme.spacing(0.25),
  },
  dropdown: {
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    zIndex: 1000,
    borderRadius: theme.shape.borderRadius,
    padding: '10px 8px',
    boxSizing: 'border-box',
    gap: '14px'
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  templateItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 4px 4px 10px',
    gap: '8px',
    background: 'white',
    borderRadius: '4px',
    order: 2,
    flexGrow: 0,
    '&:hover': {
      background: '#F2F9FF',
    },
  },
  tagItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  tag: {
    display: 'inline-block',
    padding: '2px 6px',
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    fontSize: '12px',
  },
  clearButton: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  titleBox: {
    backgroundColor: '#EAECF0',
    width: '100%',
    padding: '4px',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    maxHeight: '26px',
  },
  countBox: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    color: '#182230',
    fontSize: '10px',
    fontFamily: 'Inter',
    fontWeight: 700,
  },

  locationTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px', 
      textAlign: 'center',
      color: '#182230',
  },
  templateTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#475467',
  },
  templateType: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#98A2B3',
  },
  tagCountBox: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5px',
    color: '#182230',
    fontSize: '10px',
    fontFamily: 'Inter',
    fontWeight: 700,
  },
  addButton: {
    backgroundColor: '#147AF3',
    borderRadius: '4px',
    height: '22px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: '4px',
    paddingBlock: '2px',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontWeight: 500,
    textDecoration: 'none',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#147AF3',
    },
  },
  tagListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBlock: '5px',
  },
  assignTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#344054',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  free: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 8px',
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
    borderRadius: '16px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    color: '#344054',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
  },
  root: {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #EAECF0',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: '24px 18px',
  },
  icon: {
    '& path': {
      stroke: '#147AF3',
    },
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 25,
    fontWeight: 600,
    color: '#101828',
  },
  btnSettings: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  providerAvatar: {
    width: 38,
    height: 38,
  },
  providerName: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
    paddingLeft: 16,
    textTransform: 'capitalize',
  },
  rowText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
  },
  paid: {
    border: '1px solid #6FCF97',
    color: '#127850',
    backgroundColor: '#DCFFEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  overdue: {
    border: '1px solid #FECDCA',
    color: '#B42318',
    backgroundColor: '#FEF3F2',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  waived: {
    border: '1px solid #D5D9EB',
    color: '#363F72',
    backgroundColor: '#F8F9FC',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    backgroundColor: '#F4F3FF',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  pending: {
    border: '1px solid #FEDF89',
    color: '#B54708',
    backgroundColor: '#FFFAEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  lastCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // gap: '1rem',
  },
  btnPay: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: 'transparent',
    border: '1px solid #147AF3',
    borderRadius: 8,
    padding: '4px 10px',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
      '& svg path': {
        stroke: 'white',
      },
      '& $payBtnText': {
        color: 'white',
      },
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  invoiceText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    textDecoration: 'underline',
    color: '#147AF3',
    cursor: 'pointer',
  },
  dots: {
    cursor: 'pointer',
  },
  lastColbox: {
    width: '120px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  payBtnText: {
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
    },
  },
}));
export default useStyles;
