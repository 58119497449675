import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as PasswordIcon } from 'assets/passcode-lock.svg';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import { ReactComponent as RemoveIcon } from 'assets/minus-square.svg';
import { IRole } from './PermissionSettings';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  service: any;
  role: IRole;
  onSubmit: () => void;
  removeRoleLoading: boolean;
}

function RemoveServiceModal({
  open,
  onClose,
  service,
  role,
  onSubmit,
  removeRoleLoading,
}: IEditProps) {
  const classes = useStyles();
  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <Box className={classes.PasswordModal}>
        <RemoveIcon />
        <Typography className={classes.modalTitle}>
          {`Unassign ${service?.session}?`}
        </Typography>
        <Typography className={classes.modalText}>
          Are you sure you want to unassign the{' '}
          <span style={{ fontWeight: 700 }}>{service?.session}</span> from the{' '}
          <span style={{ fontWeight: 700 }}>{role?.name}</span> role?
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
          mt="1.5rem"
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.saveButtonRed}
            onClick={onSubmit}
          >
            {removeRoleLoading ? (
              <ProgressLoader width={18} height={18} />
            ) : (
              'Unassign'
            )}
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
}

export default RemoveServiceModal;
