import { gql, useMutation } from '@apollo/client';
import { OWNER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/permissionstab/PermissionSettings';
import { USER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

const RENAME__ROLE = gql`
  mutation renameRole($id: ID, $role: String) {
    renameRole(id: $id, role: $role)
  }
`;

const useRenameRole = () => {
  const [renameRole, { loading }] = useMutation(RENAME__ROLE);
  async function handleRenameRole(id: string, role: string) {
    try {
      return await renameRole({
        variables: { id, role },
        refetchQueries: [
          {
            query: OWNER_ROLES,
          },
          { quer: USER_ROLES },
          {
            query: TEAM_MEMBERS,
            variables: { role: '', status: '' },
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleRenameRole, loading };
};

export { RENAME__ROLE };
export default useRenameRole;
