import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Delete, MoreVertOutlined } from '@material-ui/icons';
import { ReactComponent as PdfIcon } from 'assets/icons/PdfIconFile.svg';
import { ReactComponent as ViewIcon } from 'assets/eye-view.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import ExpandedItems from 'components/ui/ExpandedItems';
import { ReactComponent as Show } from 'assets/eyeIcon.svg';
import { ReactComponent as Download } from 'assets/download.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash-01.svg';
import Menu from 'components/ui/Menu';
import ThreeDotsIcon from 'assets/ThreeDotsIcon';
interface AttachementPreviewProps {
  noBorder?: boolean;
  url: string;
  fileSize?: number;
  file?: File;
  fileType?: string;
  fileName: string;
  menuItems: {
    url: string;
    avatar: React.ReactNode;
    name: string;
    onClick: () => void;
  }[];
  isEdit?: boolean;
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 12px 12px 0px',
    borderBottom: ({ noBorder }: { noBorder: boolean }) =>
      !noBorder && '1px solid #ddd',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  fileIcon: {
    fontSize: '24px',
    marginRight: '12px',
  },
  fileName: {
    fontWeight: 500,
    color: '#333',
  },
  fileSize: {
    fontSize: '12px',
    color: '#888',
  },
  menuButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#888',
  },
});
const AttachmentItemPreview = ({
  url,
  fileSize,
  file,
  isEdit,
  fileName,
  fileType,
  menuItems,
  noBorder = false,
}: AttachementPreviewProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles({ noBorder });
  const iconMedia = () => {
    switch (isEdit ? fileType : file.type) {
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return (
          <img
            src={url}
            width="40"
            height="auto"
            alt="image user"
            style={{ borderRadius: '3px' }}
          />
        );

      case 'application/pdf':
        return <PdfIcon />;
      case 'video/mp4':
        return <video width="40" height="auto" src={url} />;
    }
  };
  return (
    <Box className={classes.row}>
      <Box className={classes.fileInfo}>
        {iconMedia()}
        <Box ml={1}>
          <Box className={classes.fileName}>{fileName}</Box>
          {fileSize && <Box className={classes.fileSize}>{fileSize}</Box>}
        </Box>
      </Box>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <ThreeDotsIcon
          fill={'#667085'}
          width="20px"
          height="20px"
          style={{ cursor: 'pointer' }}
        />
      </Box>
      {menuItems?.length > 0 && (
        <Menu<any>
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          handleMenuClose={() => setAnchorEl(null)}
          MenuElements={menuItems}
          style={{ width: '100%' }}
        />
      )}
    </Box>
  );
};
export default AttachmentItemPreview;
