import { Box, makeStyles, BoxProps } from '@material-ui/core';
import React, { ReactElement } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  scrollBar: ({ table, isSlide }: { table: boolean; isSlide: boolean }) => ({
    ...(table
      ? {
          overflowX: isSlide ? 'auto' : 'scroll',
          overflowY: 'hidden',
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '0.5rem',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d7e0eb',
            borderRadius: '20px',
            height: '8px',
          },
          '&::-webkit-resizer': {
            backgroundColor: 'black',
          },
        }
      : {
          overflowY: isSlide ? 'auto' : 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: '0.5rem',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#d7e0eb',
            borderRadius: '20px',
          },
          '&::-webkit-resizer': {
            backgroundColor: 'black',
          },
        }),
  }),
}));

interface ScrollBarProps extends BoxProps {
  handleScrollBottom?: () => void;
  isSlide?: boolean;
  isClass?: boolean;
  table?: boolean;
}
export default function ScrollBar({
  className,
  children,
  handleScrollBottom,
  isSlide = false,
  isClass = true,
  table = false,
  ...props
}: ScrollBarProps): ReactElement {
  const classes = useStyles({ isSlide, table });

  return (
    <>
      {handleScrollBottom ? (
        <div
          {...props}
          onScroll={(e: any) => {
            if (
              e.target.scrollHeight - e.target.scrollTop ===
              e.target.clientHeight
            ) {
              handleScrollBottom && handleScrollBottom();
            }
          }}
          className={clsx(className, classes.scrollBar)}
        >
          {children}
        </div>
      ) : (
        <Box
          {...props}
          className={isClass && clsx(className, classes.scrollBar)}
        >
          {children}
        </Box>
      )}
    </>
  );
}
