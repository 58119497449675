export const _file = {
  isPdf: (str: string) => {
    return str === 'application/pdf';
  },
  isImage: (str: string) => {
    return str.includes('image');
  },
  isPowerPoint: (str: string) => {
    return (
      str === 'application/vnd.ms-powerpoint' ||
      str ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
  },
  isExcel: (str: string) => {
    return (
      str === 'application/vnd.ms-excel' ||
      str ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  },
  isMp3: (str: string) => {
    return str === 'audio/mpeg';
  },
  isTxt: (str: string) => {
    return str === 'text/plain';
  },
  isZip: (str: string) => {
    const zipMimeTypes = [
      'application/zip',
      'application/x-zip-compressed',
      'application/x-zip',
      'application/x-compressed',
      'multipart/x-zip',
      'application/x-tar',
      'application/x-gzip',
      'application/x-7z-compressed',
      'application/x-rar-compressed',
    ];
    return zipMimeTypes.includes(str);
  },
  isWord: (str: string) => {
    return (
      str === 'application/msword' ||
      str ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  },
  isVideo: (str: string) => {
    return str === 'video/mp4';
  },
};
