import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as AddIcon } from 'assets/RenameRole.svg';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import { useForm } from 'react-hook-form';
import useRenameRole from '@eggmed/graphql-client/operations/staffOperations/useRenameRole';
import { useSnackbar } from 'hooks/useSnackbar';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: () => void;
  role: { name: string; _id: string };
}
const schema = yup.object().shape({
  role: yup.string().required('Role is a required field'),
});

function RenameRoleModal({ open, onClose, role }: IEditProps) {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const { control, register, errors, handleSubmit, reset } = useForm({
    defaultValues: { role: role?.name },
    resolver: yupResolver(schema),
  });
  const common = { control, register, errors };
  const { handleRenameRole, loading } = useRenameRole();
  React.useEffect(() => {
    if (role?.name) {
      reset({ role: role?.name });
    }
  }, [reset, role?.name]);
  async function onSubmit(data) {
    await handleRenameRole(role?._id, data?.role);
    onClose();
    triggerSnack('Role renamed successfully');
  }
  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form className={classes.PasswordModal} onSubmit={handleSubmit(onSubmit)}>
        <AddIcon />
        <Typography className={classes.modalTitle}>Rename role</Typography>
        <Typography className={classes.modalText}>
          Update the name of this role. Permissions and settings will remain
          unchanged.{' '}
        </Typography>
        <Box width="100%" my="1.5rem">
          <InputBlock
            label=""
            type="text"
            placeholder={`Role name`}
            name="role"
            {...common}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '1rem' }}
        >
          <Button onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button type="submit" className={classes.saveButton}>
            {loading ? <ProgressLoader width={18} height={18} /> : 'Rename'}
          </Button>
        </Box>
      </form>
    </BasicModal>
  );
}

export default RenameRoleModal;
