import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { ApolloError, gql, useLazyQuery, useQuery } from '@apollo/client';
import { GET_STAFF_BY_USER_ID } from '@eggmed/graphql-client/operations/staffOperations/useGetStaffByUserId';
import useGenerateTokenStaffByDoctor from '@eggmed/graphql-client/operations/staffOperations/useGenerateTokenStaffByDoctor';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Intercom from '@intercom/messenger-js-sdk';
import { ReactComponent as Eggm } from 'assets/LogoEggm.svg';
import {
  Box,
  LinearProgress,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import DefaultMobileScreen from 'pages/DefaultMobilePage';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';

const leng = {
  English: 'en',
  French: 'fr',
};

export interface subscriptionProps {
  subscription: string;
  endDate: Date | number | string;
  type: string;
  cost: string | number;
  status: string;
  cancelAtPeriodEnd: Date | number | string;
}
export interface IUserAuthReturn {
  refetch: any;
  ownerUser: {
    _id: string;
    firstname: string;
    lastname: string;
    picture: string;
    doctorId:string
  };
  user: {
    isRelease: boolean;
    _id: string;
    onboardingStep;
    organizationId?: string | undefined;
    firstname: string;
    lastname: string;
    doctorId: string;
    role: string;
    email: string;
    timeZone: string;
    picture: string;
    agreement: {
      isAgreement: boolean;
    };
    isAuthCalendar: boolean;
    patient?: {
      _id: string;
      firstname: string;
      lastname: string;
      middlename: string;
      timeZone: string;
      username: string;
      email: string;
      picture: string;
    };
    user?: {
      _id: string;
      firstname: string;
      lastname: string;
      role: string;
    };
  };
  publicKey: string;
  role: 'doctor' | 'patient' | 'staff';
  doctor: {
    _id: string;
    noUsedFeatures: string[];
    calendarNameDisplayed: string;
    firstname: string;
    lastname: string;
    middlename: string;
    username: string;
    email: string;
    picture: string;
    isDemo: boolean;
    demoClientId: string;
    timeZone: string;
    location: string;
    locationPlace: string;
    locationId: {
      name: string;
      _id: string;
    };
    externalUrl: string;
    organization: string;
  };
  staff?: {
    organization: string;
    practice: string;
    staffOf: [any];
    user: string;
    _id: string;
  };
  patient?: {
    _id: string;
    firstname: string;
    lastname: string;
    middlename: string;
    username: string;
    email: string;
    picture: string;
    createdAt: string;
    phone: string;
    timeZone: string;
    birthday: string;
    address: string;
    suite: string;
    gender: string;
    country: string;
    zipcode: string;
    city: string;
    height?: {
      type: string;
      value: string;
    };
    weight?: {
      type: string;
      value: string;
    };
    bloodType?: string;
    description?: string;
    formatDate?: string;
  };

  loading: boolean;
  handleChangeSelectDoctor: (doctorId: string) => void;
  error: ApolloError | undefined;
  dataSubscription: any;
  loadingSubscription: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const Context = React.createContext<IUserAuthReturn | undefined>(
  undefined
);
const useStyles = makeStyles({
  root: {
    width: '30%',
    height: '10px',
    backgroundColor: 'white',
    marginTop: '3%',
    borderRadius: '50px',
  },
  barColorPrimary: {
    backgroundImage: 'linear-gradient(to right, #39A0FF, #8FFF85)',
    borderRadius: '50px',
  },
});

export const ME_QUERY = gql`
  query me($doctors: [ID]) {
    me {
      user {
        isRelease
        _id
        organizationId
        firstname
        lastname
        doctorId
        picture
        role
        email
        language
        onboardingStep
        agreement {
          isAgreement
        }
        isAuthCalendar
      }
      patient {
        _id
        pronouns
        formatDate
        employmentStatus
        religion
        preferredLanguage
        raceAndEthnicity
        relationshipStatus
        emergencyName
        emergencyEmail
        emergencyPhone
        relationship
        firstname
        lastname
        middlename
        username
        email
        picture
        createdAt
        country
        phone
        gender
        address
        suite
        timeZone
        zipcode
        city
        birthday
        state
        height {
          type
          value
        }
        weight {
          type
          value
        }
        bloodType
        description
      }
      doctor {
        noUsedFeatures
        _id
        firstname
        lastname
        middlename
        username
        email
        picture
        isDemo
        demoClientId
        timeZone
        location
        locationPlace
        externalUrl
        calendarNameDisplayed
        organization
        locationId {
          _id
          name
        }
      }
      ownerUser {
        _id
        firstname
        lastname
        doctorId
        picture
        email
      }
      publicKey
    }
    subscription {
      subscription
      endDate
      type
      cost
      status
      cancelAtPeriodEnd
    }
    getEggmedProduct {
      name
      priceId
      unit_amount
      currency
      interval
    }
    patientsRelated(doctors: $doctors) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        diagnosisCode {
          description
        }
      }
    }
    doctorAutoSendTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      questions {
        _id
        question
        type
        options
        description
        isEdited
        isDescription
        required
        range
      }
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
    defaultRate {
      _id
      session
      currency
      rate
      duration
      selected
      isDefault
      isFree
      color
      cptCode {
        category
        status
        code
        description
      }
      isPrepayment
      location
      locationPlace
      externalUrl
      locationId {
        _id
        name
      }
      tags {
        tag
        color
        _id
      }
    }
  }
`;

export default function Provider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const { data, loading, error, refetch } = useQuery(ME_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const [getStaffByUser, { data: StaffData }] = useLazyQuery(
    GET_STAFF_BY_USER_ID,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const handleGenerateTokenStaffByDoctor = useGenerateTokenStaffByDoctor();
  const handleChangeSelectDoctor = useCallback(
    async (doctorId) => {
      if (typeof doctorId === 'string') {
        const token = await handleGenerateTokenStaffByDoctor(doctorId);
        localStorage.setItem('token', token?.data?.generateTokenStaffByDoctor);
        history.push('/doctor/dashboard');
        window.location.reload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );
  useEffect(() => {
    if (data?.me?.user._id) {
      getStaffByUser({ variables: { userId: data?.me?.user?._id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.me?.user?._id]);
  useEffect(() => {
    const publicKey = data?.me?.publicKey;
    localStorage.setItem('serverPublicKey', publicKey);
  }, [data?.me]);
  const role: 'patient' | 'doctor' | 'staff' =
    data?.me?.user === 'staff'
      ? 'staff'
      : data?.me?.doctor
      ? 'doctor'
      : 'patient';
  const authData = useMemo(
    () => ({
      user: {
        ...data?.me?.user,
        doctorId: data?.me?.user?.doctorId || data?.me?.doctor?._id,
      },
      publicKey: data?.me?.publicKey,
      loading,
      error,
      doctor: data?.me?.doctor,
      staff: StaffData?.getStaff,
      patient: data?.me?.patient,
      loggedIn: !!data?.me?.user,
      ownerUser: data?.me?.ownerUser,
      role,
    }),
    [data, loading, error, StaffData?.getStaff, role]
  );

  const { i18n } = useTranslation();

  React.useEffect(() => {
    i18n.changeLanguage(leng[authData.user?.language] || 'en');
  }, [i18n, authData.user?.language]);
  useEffect(() => {
    Intercom({
      app_id: 'txlb53oq',
      user_id: authData?.user?._id,
      name: capitalizeFirstLetter(authData?.user?.firstname),
      email: authData?.user?.email,
      created_at: authData?.user?.createdAt,
    });
  }, [authData?.user]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const [open, setOpen] = React.useState(false);

  // TODO: update the loading
  if (loading)
    return (
      <Box
        minHeight="100vh"
        display="flex"
        margin="auto"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ backgroundColor: '#F4F2EE' }}
      >
        <Eggm />
        <LinearProgress
          classes={{
            root: classes.root,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </Box>
    );

  if (isMobile && authData?.loggedIn) return <DefaultMobileScreen />;

  return (
    <Context.Provider
      value={{
        ...authData,
        handleChangeSelectDoctor,
        loadingSubscription: loading,
        dataSubscription: data,
        open,
        setOpen,
        refetch,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider(): IUserAuthReturn {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAuthProvider must be used within a AuthProvider');
  }

  return context;
}

export const GET_PATIENT_AUTH = gql`
  query PatientAuth {
    getPatientAuth {
      _id
      pronouns
      formatDate
      employmentStatus
      religion
      preferredLanguage
      raceAndEthnicity
      relationshipStatus
      emergencyName
      emergencyEmail
      emergencyPhone
      relationship
      firstname
      lastname
      middlename
      username
      email
      picture
      createdAt
      country
      phone
      gender
      address
      suite
      timeZone
      zipcode
      city
      birthday
      state
      height {
        type
        value
      }
      weight {
        type
        value
      }
      bloodType
      description
    }
  }
`;
