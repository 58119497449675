import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Text from 'Text';
import Button from 'components/ui/Button';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { FormProvider, useForm } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import InsuranceModal from './InsuranceModal';
import useAddInsurance from '@eggmed/graphql-client/operations/insurance/useAddInsurance';
import useEditInsurance from '@eggmed/graphql-client/operations/insurance/useEditInsurance';
import useDeleteInsurance from '@eggmed/graphql-client/operations/insurance/useDeleteInsurance';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import useGetInsurances from '@eggmed/graphql-client/operations/insurance/useGetInsurances';
import Table from 'components/ui/Table';

import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useSnackbar } from 'hooks/useSnackbar';
import dayjs from 'dayjs';
import InsuranceColumn from './InsuranceColumn';
import Menu from 'components/ui/Menu';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import TrashIcon from 'assets/TrashIcon';
import useCheckPermission from 'contexts/PermissionContext';

export const useStyles = makeStyles((theme) => ({
  type: {
    backgroundColor: '#F9FAFB',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #EAECF0',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  titleContainer: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#147AF3',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '17px',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  text: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  uploadText: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#0054B6',
    fontFamily: 'Inter',
  },
  uploadSizeText: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#475467',
    fontFamily: 'Inter',
  },
  relationShipText: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  relationShipBox: {
    height: '36px',
    paddingInline: '12px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  relationShipLabel: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
    marginLeft: '8px',
  },
  actions: {
    opacity: 0,
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionsVisible: {
    opacity: 1,
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));
const SelfSchema = yup.object().shape({
  payer: yup.string().required('Payer is a required field'),
  memberID: yup.string().required('Member ID is a required field'),
  groupID: yup.string().required('Group ID is a required field'),
  planID: yup.string().required('Plan ID is a required field'),
  copay: yup.string().required('Copay/Coinsurance is a required field'),
  deductible: yup.string().required('Deductible is a required field'),
  startDate: yup.string().required('Start date is a required field'),
  endDate: yup.string().required('End date is a required field'),
});
const OtherSchema = yup.object().shape({
  payer: yup.string().required('Payer is a required field'),
  memberID: yup.string().required('Member ID is a required field'),
  groupID: yup.string().required('Group ID is a required field'),
  planID: yup.string().required('Plan ID is a required field'),
  copay: yup.string().required('Copay/Coinsurance is a required field'),
  deductible: yup.string().required('Deductible is a required field'),
  startDate: yup.string().required('Start date is a required field'),
  endDate: yup.string().required('End date is a required field'),
  firstname: yup.string().required('First name is a required field'),
  lastname: yup.string().required('Last name is a required field'),
  sex: yup.string().required('Sex is a required field'),
  birthday: yup.string().required('Birthday is a required field'),
  phone: yup.string().required('Phone is a required field'),
  address: yup.string().required('Adress is a required field'),
  city: yup.string().required('City is a required field'),
  state: yup.string().required('State is a required field'),
  zipCode: yup.string().required('ZIP code is a required field'),
  country: yup.string().required('Country is a required field'),
});
const Insurance = ({ patientId }: { patientId?: string }) => {
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const [currentRow, setCurrentRow] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const ConditionalSchema = yup
    .object()
    .shape({
      relationShipType: yup
        .string()
        .required('Relationship Type is a required field'),
      insuranceType: yup
        .string()
        .required('Insurance Type is a required field'),
    })
    .concat(
      // Conditionally merge SelfSchema or OtherSchema based on relationShipType
      yup
        .object()
        .shape({})
        .when('relationShipType', {
          is: (value) => {
            return value === 'Self';
          },
          then: SelfSchema,
          otherwise: OtherSchema,
        })
    );

  const methods = useForm({
    resolver: yupResolver(SelfSchema),
    defaultValues: {
      ...currentRow,
      insuranceType: editMode ? currentRow?.insuranceType : 'Primary',
      relationShipType: editMode ? currentRow?.relationShipType : 'Self',
    },
  });
  React.useEffect(() => {
    if (editMode) {
      methods.reset({
        ...currentRow,
        insuranceType: editMode ? currentRow?.insuranceType : 'Primary',
        relationShipType: editMode ? currentRow?.relationShipType : 'Self',
      });
    }
  }, [currentRow, editMode, methods.reset]);
  const { patient } = useAuth();
  const { addInsurance, loading } = useAddInsurance(patient?._id || patientId);
  const { editInsurance, loading: loadingEdit } = useEditInsurance(
    patient?._id || patientId
  );
  const { deleteInsurance, loading: loadingDelete } = useDeleteInsurance(
    patient?._id || patientId
  );
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  async function handleDelete() {
    await deleteInsurance({
      variables: {
        id: currentRow?._id,
      },
    });
  }
  const { data, loading: loadingQuery } = useGetInsurances(
    patient?._id || patientId
  );
  const [page, setPage] = React.useState(1);
  function handleChangePage(e, pg) {
    setPage(pg);
  }
  const [open, setOpen] = React.useState(false);
  function handleOpen() {
    if (!editMode) {
      methods.reset({
        insuranceType: 'Primary',
        relationShipType: 'Self',
      });
    }
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setCurrentRow(null);
    setEditMode(false);
    methods.reset({
      insuranceType: 'Primary',
      relationShipType: 'Self',
    });
  }
  async function onSubmit(form) {
    if (editMode) {
      await editInsurance({
        variables: {
          id: currentRow?._id,
          insuranceInput: {
            ...form,
            birthday: form?.birthday?.toString(),
            startDate: form?.startDate?.toString(),
            endDate: form?.endDate?.toString(),
            frontPicture:
              form?.frontPicture && typeof form?.frontPicture !== 'string'
                ? form?.frontPicture[0]
                : undefined,
            backPicture:
              form?.backPicture && typeof form?.backPicture !== 'string'
                ? form?.backPicture[0]
                : undefined,
          },
        },
      });
    } else {
      await addInsurance({
        variables: {
          insuranceInput: {
            ...form,
            patient: patient?._id || patientId,
            frontPicture: form?.frontPicture[0],
            backPicture: form?.backPicture[0],
          },
        },
      });
    }

    triggerSnack(`Insurance ${editMode ? 'updated' : 'added'} successfully`);
    handleClose();
  }
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  function handleEditFields(data) {
    setEditMode(true);
    setCurrentRow(data);
    setAnchor(null);
    handleOpen();
  }

  function handleDeleteFields() {
    setAnchor(null);
    setOpenDeleteModal(true);
  }
  const handleOpenDropDown = (e: React.MouseEvent<HTMLElement>, row: any) => {
    e.stopPropagation();
    setCurrentRow(row);
    setAnchor(e.currentTarget);
  };
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<string | null>(
    null
  );

  const columns = InsuranceColumn({
    hoveredRowIndex,
    handleEditFields,
    handleOpenDropDown,
    patientId,
  });
  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Clients',
    'edit',
    patientId
  );
  const { isEnabled: isEnabledDelete } = useCheckPermission(
    'Clients',
    'delete',
    patientId
  );
  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Edit',
      onClick: isEnabled ? () => handleEditFields(currentRow) : undefined,
      isDisabled: !isEnabled,
    },

    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Delete',
      onClick: isEnabledDelete ? handleDeleteFields : undefined,
      isRed: true,
      isDisabled: !isEnabledDelete,
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.titleContainer}>Insurance</Typography>
        <Tooltip
          title={
            !isEnabled
              ? 'This action is disabled because your role does not have the required permissions'
              : ''
          }
          placement="bottom-end"
        >
          <Button
            className={classes.addBtn}
            onClick={isEnabled ? handleOpen : undefined}
            style={{ opacity: !isEnabled ? '0.4' : '1' }}
          >
            <AddTaskIcon
              style={{
                paddingRight: '6px',
              }}
            />
            <Text i18nKey="add">Add</Text>
          </Button>
        </Tooltip>
      </Box>
      <Box mt={4}>
        <Table<any>
          loading={loadingQuery}
          page={page}
          columns={columns}
          limit={10}
          handlePagination={handleChangePage}
          data={data?.patientInsurance || []}
          pagination
          count={data?.patientInsurance?.length}
          onMouseEnterRow={(row: { _id: string }) =>
            setHoveredRowIndex(row?._id)
          }
          onMouseLeaveRow={() => setHoveredRowIndex(null)}
        />
      </Box>
      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={handleClose}
          handleClose={handleClose}
          isFromModal
          isSlide
          divider
          title={editMode ? 'Edit insurance' : 'Add new insurance'}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={loading || loadingEdit}
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '53vw',
                xl: '45vw',
              },
            }}
          >
            <InsuranceModal
              country={currentRow?.country}
              frontPicture={currentRow?.frontPicture}
              backPicture={currentRow?.backPicture}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <Menu<any>
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchor}
        handleMenuClose={() => setAnchor(null)}
        MenuElements={menuElements}
      />
      <BasicModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        handleClose={() => setOpenDeleteModal(false)}
        className={classes.deleteModal}
        isRegularModal
      >
        <DeleteModalContent
          text="insurance"
          handleClose={() => setOpenDeleteModal(false)}
          handleDelete={() => {
            handleDelete();
            setOpenDeleteModal(false);
          }}
          padding={3}
          loading={loadingDelete}
        />
      </BasicModal>
    </Box>
  );
};

export default Insurance;
