import dashboard from 'assets/sidebar/dashboard.svg';
import schedule from 'assets/sidebar/schedule.svg';
import inbox from 'assets/sidebar/MessageIcon.svg';
import Setting from 'assets/sidebar/Settings.svg';
import Home from 'assets/sidebar/home_client.svg';
import Finances from 'assets/sidebar/wallet.svg';
import Activities from 'assets/sidebar/activities.svg';
// import profile from '../../../assets/profile.svg';

const links = [
  { path: '/patient/', icon: Home, title: 'Home' },
  { path: '/patient/appointment', icon: schedule, title: 'Sessions' },
  { path: '/patient/inbox', icon: inbox, title: 'Messages' },
  // { path: '/patient/records', icon: Finance, title: 'Records' },
  { path: '/patient/activities', icon: Activities, title: 'Activities' },
  // { path: '/doctor', icon: profile, title: 'Profile' },
  { path: '/patient/finance', icon: Finances, title: 'Payments' },
];
export default links;
