import React from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from 'components/ui/Tabs';
import { useStyles } from '../styles';
import CustomTab from './CustomTab';
import SettingTab from './SettingsTab';
import CustomButton from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import Text from 'Text';
import AddRoleModal from './AddRoleModal';
import RemoveRoleModal from './RemoveRoleModal';
import RemoveExistingRoleModal from './RemoveExistingRoleModal';

import { useQuery, gql, useLazyQuery } from '@apollo/client';
import useDeleteRole from '@eggmed/graphql-client/operations/staffOperations/useDeleteRole';
import { USER_ROLES } from '../teamtab/TeamSettings';
import RenameRoleModal from './RenameRoleModal';
import { useSnackbar } from 'hooks/useSnackbar';

type PermissionLevel = 'None' | 'Assigned only' | 'Everything' | 'Owned only';
type PrepaymentType = 'required' | 'none';
type LocationType = 'in person' | 'online';

interface Permission {
  feature: string;
  actions: [string];
}

export interface IRole {
  name: string;
  _id: string;
  permissions: Permission[];
}

function PermissionSettings() {
  const { triggerSnack } = useSnackbar();
  const { data, loading } = useQuery(OWNER_ROLES);
  const { data: userRoles, loading: loadingRole } = useQuery(USER_ROLES);
  const roles = userRoles?.getAllRoleByUser;
  const rolesOwner = data?.ownerRoles || [];
  const classes = useStyles();
  const [selectedRole, setSelectedRole] = React.useState<IRole | null>(
    rolesOwner && rolesOwner[0]
  );
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  React.useEffect(() => {
    if (rolesOwner?.length) {
      const updatedRole = rolesOwner?.find(
        (role) => role._id === selectedRole?._id
      );
      if (updatedRole) {
        setSelectedRole(updatedRole);
      } else {
        // Default to the first role if the selected one is not in the new data
        setSelectedRole(rolesOwner[0]);
        setSelectedTab(0); // Reset tab to the first one
      }
    }
  }, [rolesOwner]);

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [openRemoveExist, setOpenRemoveExist] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleSelectRole = (index: number) => {
    setSelectedTab(index);
    setSelectedRole(rolesOwner[index]);
  };
  const handleEdit = () => {
    setOpenEdit(true);
  };
  const [fetchList, { data: listOfstaff }] = useLazyQuery(
    LIST_STAFF_DELETED_ROLE
  );

  const teams = listOfstaff?.listOfStaffOfDeletedRole;
  const handleRemove = () => {
    if (teams?.length === 0) {
      setOpenRemove(true);
    } else {
      setOpenRemoveExist(true);
    }
  };
  const { handleDeleteRole, loading: loadingDeleteRole } = useDeleteRole();
  async function handleDeleteRoleUser() {
    await handleDeleteRole(selectedRole?.name);
    setOpenRemove(false);
    triggerSnack('Role deleted successfully');
  }

  async function handleDeleteRoleExist() {
    setOpenRemoveExist(false);
  }

  return (
    <Grid container>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          tabsMode="simple"
          tabClassHeader={classes.permissionsTab}
          tabsBar={rolesOwner?.map((role) => (
            <CustomTab
              isSelected={selectedRole?._id === role?._id}
              role={role}
              handleEdit={handleEdit}
              handleRemove={handleRemove}
              fetchList={fetchList}
            />
          ))}
          tabsContent={[]}
          setCustomValue={handleSelectRole}
          customValue={selectedTab}
        />
        <CustomButton
          className={classes.addBtn}
          buttonType="reset"
          onClick={() => setOpenAdd(true)}
        >
          <AddIcon />
          <Text i18nKey="Add team member" className={classes.addBtnText}>
            Add role
          </Text>
        </CustomButton>
      </Box>
      <SettingTab role={selectedRole} />
      <AddRoleModal open={openAdd} onClose={() => setOpenAdd(false)} />
      <RenameRoleModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        role={selectedRole}
      />
      <RemoveRoleModal
        open={openRemove}
        onClose={() => setOpenRemove(false)}
        role={selectedRole}
        handleDeleteRole={handleDeleteRoleUser}
        loading={loadingDeleteRole}
      />
      <RemoveExistingRoleModal
        open={openRemoveExist}
        onClose={() => setOpenRemoveExist(false)}
        role={selectedRole}
        teams={teams}
        roles={roles}
      />
    </Grid>
  );
}

export const OWNER_ROLES = gql`
  query ownerRoles {
    ownerRoles {
      _id
      name
      permissions {
        feature
        actions
        isAdd
      }
    }
  }
`;

export const LIST_STAFF_DELETED_ROLE = gql`
  query listOfStaffOfDeletedRole($role: String) {
    listOfStaffOfDeletedRole(role: $role) {
      firstname
      lastname
      picture
      role
    }
  }
`;

export default PermissionSettings;
