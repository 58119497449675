import React, { ReactElement } from 'react';
import {
  Box,
  FormControl,
  Input,
  InputBase,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import { ReactComponent as CloseFilter } from 'assets/patient-profile/x-close.svg';
import Button from 'components/ui/Button';
import { LimitComponent } from './LimitComponent';
import Checked from 'pages/AdminPage/Payment/Checked';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: ({ disableEntries }: { disableEntries: boolean }) =>
      !disableEntries && 4,
    display: 'flex',
    justifyContent: ({ disableEntries }: { disableEntries: boolean }) =>
      disableEntries ? 'flex-end' : 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('xl')]: {
      marginBottom: 0,
    },
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#147AF3',
    borderRadius: '10px',
    textTransform: 'unset',
    color: '#FFFFFF',
    fontSize: '17px',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginRight: '1rem',
  },
  filterLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    fontWeight: 500,
    color: '#344054',
  },
  selectedFilters: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
  },
  selectedFilter: {
    border: '1px solid #D0D5DD',
    backgroundColor: '#fff',
    borderRadius: '6px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
  },
  selectedFilterText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  placeholder: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#98A2B3',
  },
}));

const FormInput = withStyles((theme) => ({
  input: {
    minWidth: '60px',
    width: '100%',
    height: '22px',
    position: 'relative',
    backgroundColor: ' #FFF',
    borderRadius: '6px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 4,
      border: '1px solid #0265DC',
    },
  },
}))(InputBase);

interface IPatientListProps {
  handleOpen?: () => void;
  limit: number;
  setLimit: (number: number) => void;
  disableEntries?: boolean;
  btnText?: string;
  selectedValues?: ActivitiesTypes[];
  setSelectedValues?: React.Dispatch<React.SetStateAction<ActivitiesTypes[]>>;
  handleOpenMenu?: (e) => void;
}

export default function PatientListHeader({
  handleOpen,
  limit,
  setLimit,
  disableEntries = false,
  btnText = 'Add',
  selectedValues,
  setSelectedValues,
  handleOpenMenu,
}: IPatientListProps): ReactElement {
  const { t } = useTranslation();
  function handleLimitChange(event: React.ChangeEvent<any>) {
    setLimit(parseInt(event.target.value, 10));
  }
  const values = [
    ActivitiesTypes.MOOD,
    ActivitiesTypes.SLEEP,
    ActivitiesTypes.REFLECTION,
    ActivitiesTypes.FORM,
  ];

  const valuesMapping = {
    moodCheckin: t('Mood check-ins'),
    sleepCheckin: t('Sleep check-ins'),
    reflection: t('Reflections'),
    form: 'Form',
  };

  const classes = useStyles({ disableEntries });
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    setSelectedValues(value as ActivitiesTypes[]);
  };

  return (
    <Box className={classes.root}>
      {!disableEntries && (
        <LimitComponent limit={limit} handleLimitChange={handleLimitChange} />
      )}

      {selectedValues && (
        <Box className={classes.filterContainer}>
          <Text i18nKey="filterBy" className={classes.filterLabel}>
            Filter by
          </Text>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={selectedValues}
              onChange={handleChange}
              input={<FormInput />}
              renderValue={(selected: ActivitiesTypes[]) =>
                selected?.length > 0 ? (
                  <Box className={classes.selectedFilters}>
                    {(selected as ActivitiesTypes[]).map((value) => (
                      <Box key={value} className={classes.selectedFilter}>
                        <Typography className={classes.selectedFilterText}>
                          {valuesMapping[value]}
                        </Typography>
                        <CloseFilter />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography className={classes.placeholder}>
                    {t('allActivities')}
                  </Typography>
                )
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    overflowY: 'auto',
                  },
                },
              }}
              inputProps={{
                name: 'age',
                id: 'outlined-age-native-simple',
              }}
            >
              {values.map((value) => (
                <MenuItem key={value} value={value}>
                  <Checked
                    color="#0265DC"
                    checkedValue={selectedValues.indexOf(value) != -1}
                    handleChange={() => {}}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: '1rem' }}
                  >
                    {valuesMapping[value]}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <Button
        className={classes.addBtn}
        onClick={handleOpen || handleOpenMenu}
        data-cy="add-button"
      >
        <AddTaskIcon
          style={{
            paddingRight: '6px',
          }}
        />
        {btnText}
      </Button>
    </Box>
  );
}
