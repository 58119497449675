import React, { ReactElement } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography, Divider, IconButton } from '@material-ui/core';

import Help from 'assets/sidebar/help-new.svg';
import LogOut from 'assets/sidebar/logOut.svg';
import Setting from 'assets/sidebar/Settings.svg';
import { useStyles } from '../../Layout/sidebar/styles';
import links from './links';

import { SidebarLink } from '../../Layout/sidebar/SidebarLink';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse.svg';

interface ISideProps {
  sideRespons?: string;
  setIsSidebarExpanded?: (setIsSidebarExpanded: boolean) => void;
  isSidebarExpanded?: boolean;
  isPatient?: boolean;
}
const SideBar = ({
  sideRespons,
  setIsSidebarExpanded,
  isSidebarExpanded,
  isPatient = false,
}: ISideProps): ReactElement => {
  const theme = useTheme();
  // const isLarge = useMediaQuery(theme.breakpoints.down('lg'), {
  //   defaultMatches: true,
  // });
  const { pathname } = useLocation();
  const linksWidthOutSettings = links.slice(0, 6);
  const linksSettings = [
    {
      path: '/patient/help',
      icon: Help,
      title: 'Help',
    },
    { path: '/patient/settings', icon: Setting, title: 'Settings' },
  ];
  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };
  const logOutSideBar = [{ path: '/login', icon: LogOut, title: 'Logout' }];
  const classes = useStyles({ isSidebarExpanded, isPatient });

  const handleToggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };
  return (
    <Box className={classes.root}>
      {/* <Link to={'/'}>
        <picture className={classes.logoPicTag}>
          <source
            srcSet={eggmedlogo}
            media={`(min-width: ${theme.breakpoints.values.md}px)`}
          />
          <img className={classes.logoImgTag} src={eggmed} alt="egmedlogo" />
        </picture>
      </Link> */}
      <IconButton onClick={handleToggleSidebar} className={classes.menubar}>
        <CollapseIcon
          style={{ width: '50px', height: '50px', transform: 'scale(1.5)' }}
        />
      </IconButton>
      <Divider className={classes.divider} />
      <Divider className={classes.dividerSettings} />
      <Box className={`${classes.links} ${classes.space} `}>
        {sideRespons === 'normal' ? (
          <>
            <Box>
              {linksWidthOutSettings?.map((element) => {
                return (
                  <SidebarLink
                    element={element as any}
                    pathname={pathname}
                    isSidebarExpanded={isSidebarExpanded}
                  />
                );
              })}
            </Box>
            <Box px={2}>
              <Box className={classes.separator} my={2} />
            </Box>
            <Box>
              {linksSettings?.map((element) => {
                return (
                  <SidebarLink
                    element={element as any}
                    pathname={pathname}
                    isSidebarExpanded={isSidebarExpanded}
                    isSettings
                  />
                );
              })}
            </Box>
          </>
        ) : (
          linksWidthOutSettings.map((element) => {
            return (
              <Box key={element.path} className={classes.linkHover}>
                <Link
                  key={element.path}
                  className={`${classes.link}`}
                  to={element.path}
                >
                  <Box className={classes.sideBar}>
                    <Box className={classes.imgBox}>
                      <img
                        className={classes.icon}
                        src={element.icon}
                        alt={element.title}
                      />
                    </Box>
                    <Box className={classes.boxText}>
                      <Typography className={classes.text}>
                        {element.title}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            );
          })
        )}
        {/* <Box mt={5}>
               {sideRespons !== 'normal' &&
                 linksSettings.map((element) => {
                   return (
                     <Box key={element.path} className={classes.linkHover}>
                       <Link
                         key={element.path}
                         className={`${classes.linkSettings}  ${
                           element.path.split('/')[2] === pathname.split('/')[2] ||
                           (pathname === '/doctor' &&
                             element.path === '/doctor/dashboard')
                             ? classes.selected
                             : ''
                         }`}
                         to={element.path}
                       >
                         <Box className={classes.sideBar}>
                           <Box className={classes.imgBox}>
                             <img
                               className={classes.iconSettings}
                               src={element.icon}
                               alt={element.title}
                             />
                           </Box>
                           <Box className={classes.boxText}>
                             <Typography className={classes.text}>
                               {element.title}
                             </Typography>
                           </Box>
                         </Box>
                       </Link>
                     </Box>
                   );
                 })}
             </Box>
             <Box mt={-3}>
               {sideRespons !== 'normal' &&
                 logOutSideBar.map((element) => {
                   return (
                     <Box
                       key={element.path}
                       className={classes.linkHover}
                       onClick={logout}
                     >
                       <Link
                         key={element.path}
                         className={`${classes.link}  ${
                           element.path.split('/')[2] === pathname.split('/')[2] ||
                           (pathname === '/doctor' &&
                             element.path === '/doctor/dashboard')
                             ? classes.selected
                             : ''
                         }`}
                         to={element.path}
                       >
                         <Box className={classes.sideBar}>
                           <Box className={classes.imgBox}>
                             <img
                               className={classes.iconSettings}
                               src={element.icon}
                               alt={element.title}
                             />
                           </Box>
                           <Box className={classes.boxText}>
                             <Typography className={classes.text}>
                               {element.title}
                             </Typography>
                           </Box>
                         </Box>
                       </Link>
                     </Box>
                   );
                 })}
             </Box> */}
      </Box>
    </Box>
  );
};

export default SideBar;
