import React, { useReducer, useContext, useCallback, useState } from 'react';

import {
  OPEN_PPROVIDER_ASSIGN_MODAL,
  CLOSE_ASSIGN_MODAL,
  SET_PROVIDERS,
} from './action';
import reducer, { Context, initialState } from './reducer';

import { useTranslation } from 'react-i18next';
import { IProviderData } from 'pages/PatientListPage/AssignProvider';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';

export default function Provider({ children, initialValue }: any) {
  const { t } = useTranslation();
  const [assignProviderTitle, setAssignProviderTitle] =
    React.useState<string>('Assign providers');

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOpenAssignModal = useCallback(() => {
    dispatch({ type: OPEN_PPROVIDER_ASSIGN_MODAL });
  }, [dispatch]);

  const handleCloseAssignModal = useCallback(() => {
    dispatch({ type: CLOSE_ASSIGN_MODAL });
  }, []);

  const contextValue = React.useMemo(
    () => ({
      ...state,
      handleOpenAssignModal,
      handleCloseAssignModal,
      assignProviderTitle,
      setAssignProviderTitle,
    }),
    [state, assignProviderTitle]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useAssignProvider must be used within a assignProvider');
  }

  return context;
}
