import {
  MenuItem,
  Select,
  createStyles,
  withStyles,
  InputBase,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import React from 'react';

export const CustomSelectInput = withStyles((theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '&.Mui-error > div': {
        border: '1px solid red',
      },
    },
    input: {
      height: 'auto',
      position: 'relative',
      backgroundColor: ' #FFFFFF',
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      padding: '12px',
      fontSize: 16,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: 'Inter, sans-serif',
      '&:focus': {
        borderRadius: '8px',
      },
    },
  })
)(InputBase);

export default function CustomSelect({ options, renderOption, ...props }: any) {
  return (
    <Select
      variant="filled"
      input={<CustomSelectInput />}
      {...props}
      MenuProps={{
        disableScrollLock: false,
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          style: {
            maxHeight: '300px',
            overflowY: 'auto',
          },
        },
      }}
    >
      {options.map((value: any) => {
        return (
          <MenuItem value={value}>
            {renderOption ? renderOption(value) : value}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export function RegisteredSelect({
  name,
  label,
  control,
  defaultValue,
  renderOption,
  renderValue,
  children,
  onUpdate,
  style,
  className,
  options,
  ...props
}: any) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      {...props}
      render={({ onChange, ...params }) => (
        <CustomSelect
          renderValue={renderValue}
          renderOption={renderOption}
          options={options}
          onChange={(e: any) => {
            onChange(e);
            onUpdate?.();
          }}
          style={style}
          className={className}
          {...props}
          {...params}
        />
      )}
    />
  );
}
