import { gql, useMutation } from '@apollo/client';
import { OWNER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/permissionstab/PermissionSettings';
import { USER_ROLES } from '../../../../client/src/pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

const DELETE_ROLE = gql`
  mutation deleteRoleByOwner($role: String, $roles: [String]) {
    deleteRoleByOwner(role: $role, roles: $roles)
  }
`;

const useDeleteRole = () => {
  const [deleteRoleByOwner, { loading }] = useMutation(DELETE_ROLE);
  async function handleDeleteRole(role: string, roles?: [string]) {
    try {
      return await deleteRoleByOwner({
        variables: { role, roles },
        refetchQueries: [
          {
            query: OWNER_ROLES,
          },
          {
            query: USER_ROLES,
          },
          {
            query: TEAM_MEMBERS,
            variables: { role: '', status: '' },
          },
        ],
      });
    } catch (e) {
      return e;
    }
  }
  return { handleDeleteRole, loading };
};

export { DELETE_ROLE };
export default useDeleteRole;
