import { gql, useMutation } from '@apollo/client';
import { LOCATION_BY_DOCTOR } from './useGetLocationsByDoctor';

export const ADD_LOCATION = gql`
  mutation addLocation($locationInput: inputlocationInput) {
    addLocation(locationInput: $locationInput) {
      name
      _id
    }
  }
`;

export default function useAddLocation() {
  const [addLocation, { data, error, loading }] = useMutation(ADD_LOCATION, {
    refetchQueries: [{ query: LOCATION_BY_DOCTOR }],
    awaitRefetchQueries: true,
  });

  return { addLocation, data, error, loading };
}


