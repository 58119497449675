import { gql, useMutation } from '@apollo/client';

const GENERATE_ACCOUNT = gql`
  mutation generateAccount($userInput: GenerateInput) {
    generateAccount(userInput: $userInput) {
      email
      password
      patient {
        _id
        firstname
        lastname
        email
      }
    }
  }
`;

export default function useGenerateAccount() {
  const [generateAccount, result] = useMutation(GENERATE_ACCOUNT, {
    refetchQueries: [
      {
        query: PATIENTS_RELATED,
        variables: { doctors: [], isUnassign: false },
      },
    ],
  });

  return { generateAccount, ...result };
}
export const PATIENTS_RELATED = gql`
  query patientsRelated($doctors: [ID], $isUnassign: Boolean) {
    patientsRelated(doctors: $doctors) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      picture
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        isDisplayed
        diagnosisCode {
          description
        }
      }
      relatedTo {
        _id
        firstname
        lastname
        picture
        role
      }
      isDelete
    }
    listUnassignedClient(doctors: $doctors, isUnassign: $isUnassign) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      picture
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        isDisplayed
        diagnosisCode {
          description
        }
      }
      relatedTo {
        _id
        firstname
        lastname
        picture
        role
      }
      isDelete
    }
  }
`;
