import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, TextField, CircularProgress } from '@material-ui/core';
import { ReactComponent as PaperPlaneIcon } from 'assets/paperplane.svg';
import { ReactComponent as PaperPlaneGrayIcon } from 'assets/icons/paperPlaneGray.svg';

import { ReactComponent as SmileIcon } from 'assets/emoji.svg';
import { ReactComponent as PaperClipIcon } from 'assets/addfile.svg';
import IconButton from 'components/ui/IconButton';
import { useProvider } from 'pages/InboxPage/state/Provider';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import useStyles from './style';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { ReactComponent as CloseIcon } from 'assets/inbox/x.svg';
import { ReactComponent as PdfIcon } from 'assets/inbox/pdf.svg';
import { ReactComponent as DocIcon } from 'assets/inbox/word.svg';
import { ReactComponent as FileIcon } from 'assets/inbox/file.svg';
import { ReactComponent as Mp3Icon } from 'assets/inbox/mp3.svg';
import { ReactComponent as PptIcon } from 'assets/inbox/ppt.svg';
import { ReactComponent as XlsxIcon } from 'assets/inbox/xlsx.svg';
import { ReactComponent as ZipIcon } from 'assets/inbox/zip.svg';
import { ReactComponent as TextIcon } from 'assets/inbox/text.svg';
import { ReactComponent as VideoIcon } from 'assets/inbox/video.svg';

import Button from 'components/ui/Button';

const InputMessage = () => {
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const classes = useStyles({ patient: false });
  const { handlePushMessage, selected, sendMessageLoading } = useProvider();
  const [filName, setFilName] = React.useState<string | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const [inputHeight, setInputHeight] = useState(0);

  const schema = yup.object().shape({
    text: yup.string(),
    files: yup.array(),
  });

  const { register, handleSubmit, control, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text: '',
      files: [],
    },
  });

  const onSubmit = (messages: any) => {
    const IsSendingMessage = messages.text.length != 0;
    const IsSendingFile = files.length != 0;
    if (selected) {
      if (IsSendingMessage || IsSendingFile) {
        handlePushMessage(selected, { ...messages, files });
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (!sendMessageLoading) {
      reset();
      setInputValue('');
      setImagePreviews([]);
      setFilName(null);
      setFiles([]);
    }
  }, [sendMessageLoading, reset]);

  const onChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e.target.files);
    return Array.from(e.target.files || []);
  };

  const uploadFiles = useRef<HTMLInputElement>(null);

  const handleFileChange = (selectedFiles: FileList | null) => {
    if (selectedFiles && selectedFiles.length > 0) {
      const filesArray = Array.from(selectedFiles);
      setFilName(filesArray.map((file) => file.name).join(', '));
      setImagePreviews(filesArray.map((file) => URL.createObjectURL(file)));
      setFiles(filesArray);
      setValue('files', filesArray);
    }
  };

  function onClick(emojiData: EmojiClickData) {
    setInputValue(
      (inputValue) =>
        inputValue + (emojiData.isCustom ? emojiData.unified : emojiData.emoji)
    );
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitBtnRef.current?.click();
    }
  };

  const handleRemoveImage = (index: number) => {
    if (!sendMessageLoading) {
      const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
      const updatedFiles = files.filter((_, i) => i !== index);
      setImagePreviews(updatedPreviews);
      setFiles(updatedFiles);
      setFilName(updatedFiles.map((file) => file.name).join(', '));
      setValue('files', updatedFiles);
    }
  };
  const inputContainerStyle =
    (inputValue && inputHeight > 20) || files?.length > 0
      ? { alignItems: 'flex-end' }
      : { alignItems: 'center', justifyContent: 'center' };

  const getFileIcon = (file: File) => {
    const fileType = file.type;
    const fileName = file.name;

    if (fileType === 'application/pdf' || fileName.endsWith('.pdf')) {
      return (
        <PdfIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (
      fileType === 'application/msword' ||
      fileName.endsWith('.doc') ||
      fileName.endsWith('.docx')
    ) {
      return (
        <DocIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (
      fileType === 'application/vnd.ms-powerpoint' ||
      fileType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      fileName.endsWith('.ppt') ||
      fileName.endsWith('.pptx')
    ) {
      return (
        <PptIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (
      fileType === 'application/vnd.ms-excel' ||
      fileType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileName.endsWith('.xls') ||
      fileName.endsWith('.xlsx')
    ) {
      return (
        <XlsxIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (fileType === 'audio/mpeg' || fileName.endsWith('.mp3')) {
      return (
        <Mp3Icon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (fileType === 'text/plain' || fileName.endsWith('.txt')) {
      return (
        <TextIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (fileType === 'application/zip' || fileName.endsWith('.zip')) {
      return (
        <ZipIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    } else if (fileType === 'video/mp4' || fileName.endsWith('.mp4')) {
      return <video width="40" height="auto" src={URL.createObjectURL(file)} />;
    } else {
      return (
        <FileIcon
          style={{
            width: '40px',
            height: '40px',
          }}
        />
      );
    }
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    const textarea = e.target as any;
    setInputHeight(textarea.scrollHeight);
  };
  return (
    <>
      <Paper
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        elevation={1}
        style={inputContainerStyle}
        className={classes.paper}
      >
        <Controller
          data-cy="add-files"
          control={control}
          name="files"
          render={({ onChange }) => (
            <Box
              color="primary"
              aria-label="upload files"
              onClick={() => uploadFiles?.current?.click()}
              style={
                (inputValue && inputHeight > 20) || files?.length > 0
                  ? { marginBottom: '4px', cursor: 'pointer' }
                  : { marginBottom: '0px', cursor: 'pointer' }
              }
            >
              <input
                multiple
                hidden
                ref={uploadFiles}
                onChange={(e) => onChange(onChangeInputFile(e))}
                type="file"
              />
              <PaperClipIcon />
            </Box>
          )}
        />
        <Box
          color="primary"
          ml={1}
          aria-label="directions"
          style={
            (inputValue && inputHeight > 20) || files?.length > 0
              ? { position: 'relative', marginBottom: '4px', cursor: 'pointer' }
              : { position: 'relative', cursor: 'pointer' }
          }
          onClick={() => setOpenEmoji((emoji) => !emoji)}
        >
          <SmileIcon />
          {openEmoji && (
            <Box
              style={
                (inputValue && inputHeight > 20) || files?.length > 0
                  ? {
                      position: 'absolute',
                      bottom: '50px',
                      left: 0,
                      marginBottom: '4px',
                      zIndex: 1,
                      background: '#fff',
                      border: '1px solid #ccc',
                      overflowY: 'auto',
                    }
                  : {
                      position: 'absolute',
                      bottom: '50px',
                      left: 0,
                      zIndex: 1,
                      background: '#fff',
                      border: '1px solid #ccc',
                      overflowY: 'auto',
                    }
              }
              p={2}
            >
              <EmojiPicker
                searchDisabled
                skinTonesDisabled
                autoFocusSearch={false}
                onEmojiClick={onClick}
              />
            </Box>
          )}
        </Box>
        <Box
          onKeyDown={handleKeyDown}
          className={classes.inputMessageContainer}
        >
          <Box className={classes.inputMessageWrapper}>
            <TextField
              variant="outlined"
              multiline
              className={classes.inputMessage}
              placeholder="Type your message"
              name="text"
              inputRef={register()}
              value={inputValue}
              onChange={handleChangeInput}
              maxRows={6}
              minRows={1}
            />
          </Box>
          {imagePreviews.length > 0 && (
            <Box className={classes.imagePreviewContainer}>
              {files.map((file, index) => (
                <Box key={index} position="relative" width="fit-content">
                  {!sendMessageLoading && (
                    <CloseIcon
                      className={classes.imagePreviewClose}
                      onClick={() => handleRemoveImage(index)}
                    />
                  )}
                  {file.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`preview ${index}`}
                      className={classes.imagePreview}
                    />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="40px"
                      height="40px"
                      className={classes.filePreview}
                    >
                      {getFileIcon(file)}
                    </Box>
                  )}
                  {sendMessageLoading && (
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      style={{ transform: 'translate(-50%, -50%)' }}
                    >
                      <CircularProgress size={24} style={{ color: 'blue' }} />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          style={
            (inputValue && inputHeight > 20) || files?.length > 0
              ? { marginRight: '10px', marginBottom: '10px' }
              : { marginRight: '10px' }
          }
        >
          <Button
            disabled={!inputValue && files.length == 0}
            type="submit"
            className={classes.sendButton}
          >
            {inputValue || files.length > 0 ? (
              <PaperPlaneIcon />
            ) : (
              <PaperPlaneGrayIcon />
            )}
          </Button>
        </Box>
        <button
          ref={submitBtnRef}
          type="submit"
          style={{ display: 'none' }}
          disabled={sendMessageLoading}
        ></button>
      </Paper>
    </>
  );
};

export default InputMessage;
