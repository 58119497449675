import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Chip, Tooltip, TextField } from '@material-ui/core';
import { getTagColor } from 'utils/objectUtils';
import { useStyles } from './styles';
import TagsEditBar, { Itag } from './TagEdit';
import { useTag } from '../../ManageTags/useTag';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import { gql, useMutation } from '@apollo/client';
import {
  calculateVisibleTags,
  calculateVisibleTagsDiagnosis,
} from 'utils/calculateTags';
import { t } from 'i18next';

interface Iprops {
  tags: Itag[];
  editMode: boolean;
  width?: number;
  setEditMode: (value: boolean) => void;
  isSelected: boolean;
  templateId: string;
  data?: any;
  diagnosis?: boolean;
  profile?: boolean;
  page?: number;
}

const TagsCell = ({
  tags,
  editMode,
  width = 300,
  setEditMode,
  isSelected,
  templateId,
  data: _data,
  diagnosis = false,
  profile = false,
  page,
}: Iprops) => {
  const [tagState, setTagState] = useState([]);
  const [addTagToTemplate] = useMutation(ADD_TAG_MUTATION);
  const { addTag, createLoading, data } = useTag();
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [visibleTagsCount, setVisibleTagsCount] = useState(0);
  const tagWidthsRef = useRef<number[]>([]);
  const tooltipChipWidth = 40;
  const [init, setInit] = useState(true);
  const TagDiagosis = () => {
    return (
      <Box
        display="flex"
        flexWrap="nowrap"
        style={{ overflow: 'hidden', width: '100%' }}
      >
        {tagState?.length > 1 &&
          tagState?.slice(0, visibleTagsCount)?.map((tag, index) => {
            return tag?.tag?.length < 50 ? (
              <Chip
                key={index}
                label={tag?.tag}
                className={classes.chip}
                style={{
                  backgroundColor: '#FEF3F2',
                  border: '1px solid #FECDCA',
                  color: '#B42318',
                  borderRadius: '16px',
                }}
                size="small"
              />
            ) : (
              <Tooltip
                style={{
                  whiteSpace: 'normal',
                  backgroundColor: 'black',
                  color: 'white',
                  width: 'auto',
                  fontSize: '14px',
                }}
                classes={{ tooltip: classes.noMaxWidth }}
                title={
                  <div
                    style={{ width: '100%', zIndex: 1, whiteSpace: 'nowrap' }}
                  >
                    {tag?.tag}
                  </div>
                }
                placement="bottom-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 40],
                      },
                    },
                  ],
                }}
              >
                <Chip
                  key="singleTagOverflow"
                  label={tag?.tag}
                  style={{
                    backgroundColor: '#FEF3F2',
                    border: '1px solid #FECDCA',
                    color: '#B42318',
                    maxWidth: '115px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    marginRight: '4px',
                    textOverflow: 'ellipsis',
                    borderRadius: '16px',
                  }}
                  size="small"
                />
              </Tooltip>
            );
          })}

        {tagState?.length === 1 ? (
          <Tooltip
            style={{
              whiteSpace: 'normal',
              backgroundColor: 'black',
              color: 'white',
              width: 'auto',
              fontSize: '14px',
            }}
            classes={{ tooltip: classes.noMaxWidth }}
            title={
              <div style={{ width: '100%', zIndex: 1, whiteSpace: 'nowrap' }}>
                {tagState[0].tag}
              </div>
            }
            placement="bottom-end"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 40],
                  },
                },
              ],
            }}
          >
            <Chip
              key="singleTagOverflow"
              label={tagState[0]?.tag}
              style={{
                backgroundColor: '#FEF3F2',
                border: '1px solid #FECDCA',
                color: '#B42318',
                maxWidth: '160px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                borderRadius: '16px',
              }}
              size="small"
            />
          </Tooltip>
        ) : (
          visibleTagsCount < tagState?.length &&
          tagState?.length > 2 && (
            <Tooltip
              classes={{ tooltip: classes.customTooltip }}
              title={
                <div
                  className={classes.tooltipContent}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  {tagState?.slice(visibleTagsCount)?.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.tag}
                      className={`${classes.tooltipChip}`}
                      style={{
                        backgroundColor: '#FEF3F2',
                        border: '1px solid #FECDCA',
                        color: '#B42318',
                        borderRadius: '16px',
                      }}
                      size="small"
                    />
                  ))}
                </div>
              }
              arrow
              placement="bottom-end"
            >
              <Chip
                label="..."
                size="small"
                style={{ marginRight: '0px' }}
                className={classes.seeMoreChip}
              />
            </Tooltip>
          )
        )}
      </Box>
    );
  };
  const Tags = () => {
    return (
      <Box display="flex" flexWrap="nowrap" style={{ overflow: 'hidden' }}>
        {tagState?.slice(0, visibleTagsCount).map((tag, index) => (
          <Chip
            key={index}
            label={tag.tag}
            className={classes.chip}
            style={{
              backgroundColor: getTagColor(tag.color).backgroundColor,
              border: getTagColor(tag.color).border,
              color: getTagColor(tag.color).text,
            }}
            size="small"
          />
        ))}
        {visibleTagsCount < tagState?.length && (
          <Tooltip
            classes={{ tooltip: classes.customTooltip }}
            title={
              <div
                className={classes.tooltipContent}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {tagState?.slice(visibleTagsCount)?.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag.tag}
                    className={`${classes.tooltipChip} ${classes.tooltipChipSpacing}`}
                    style={{
                      backgroundColor: getTagColor(tag.color).backgroundColor,
                      border: getTagColor(tag.color).border,
                      color: getTagColor(tag.color).text,
                      width: 'fit-content',
                    }}
                    size="small"
                  />
                ))}
              </div>
            }
            arrow
          >
            <Chip
              style={{ marginRight: '4px' }}
              label="..."
              size="small"
              className={classes.seeMoreChip}
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  const onAddTag = async (newTag, inNew) => {
    const isTagExist =
      tagState && tagState?.some((tag) => tag._id === newTag._id);
    if (!isTagExist) {
      if (inNew) {
        const addedTag = await addTag({
          variables: {
            tagInput: {
              tag: newTag.tag,
              color: newTag.color,
            },
          },
        });
        setTagState((prev) => [...prev, addedTag.data.addTag]);
      } else {
        setTagState((prev) => [...prev, newTag]);
      }
    }
  };
  const renderTags = () => {
    return diagnosis ? <TagDiagosis /> : <Tags />;
  };
  const updateVisibleTags = useCallback(() => {
    if (!containerRef?.current || tagWidthsRef?.current?.length === 0) return;

    const containerWidth = containerRef.current.offsetWidth;
    const visibleCount = diagnosis
      ? calculateVisibleTagsDiagnosis(tagWidthsRef.current, containerWidth)
      : calculateVisibleTags(
          tagWidthsRef.current,
          containerWidth,
          tooltipChipWidth
        );

    setVisibleTagsCount(visibleCount);
  }, [diagnosis]);
  useEffect(() => {
    const measureDiv = document.createElement('div');
    measureDiv.className = classes.hiddenTagMeasure;
    document.body.appendChild(measureDiv);

    if (tagState?.length > 0) {
      tagWidthsRef.current = tagState?.map((tag: any) => {
        const chip = document.createElement('div');
        chip.className = classes.chip;
        chip.style.backgroundColor = diagnosis
          ? tag.background
          : getTagColor(tag.color).backgroundColor;
        chip.style.border = diagnosis
          ? tag.border
          : getTagColor(tag.color).border;
        chip.style.color = diagnosis ? tag.color : getTagColor(tag.color).text;
        chip.textContent = tag.tag;
        measureDiv.appendChild(chip);
        const width = chip.offsetWidth + 8;
        measureDiv.removeChild(chip);
        return width;
      });
      updateVisibleTags();
      document.body.removeChild(measureDiv);
    }
  }, [tagState, classes, diagnosis, page, profile]);

  useEffect(() => {
    if ((!!tags && tags?.length > 0, !!init)) {
      setTagState(tags);
      setInit(false);
    }
    if (profile) {
      setTagState(tags);
    }
  }, [tags, init, tagState, _data, page, profile]);

  useEffect(() => {
    if (!diagnosis) {
      const tagIds = tagState.map((tag) => tag._id);
      if (tagIds.length > 0) {
        addTagToTemplate({
          variables: {
            templateId,
            tags: tagIds,
          },
          refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
        });
      }
    }
  }, [tagState, diagnosis, _data, profile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        dropdownRef.current &&
        !containerRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target) &&
        isSelected &&
        editMode
      ) {
        setEditMode(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode, isSelected, setEditMode]);

  const handleDeleteTag = async (tagId) => {
    await setTagState((prev) => {
      const newTags = prev.filter((tag) => tag._id !== tagId);
      const tagIds = newTags.map((tag) => tag._id);
      addTagToTemplate({
        variables: {
          templateId,
          tags: tagIds,
        },
        refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
      });
      return newTags;
    });
  };

  return (
    <div
      className={classes.tagsContainer}
      ref={containerRef}
      style={{
        overflowY: editMode ? 'auto' : 'hidden',
        minWidth: 'auto',
        marginRight: '4px',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!editMode && renderTags()}

      {editMode && (
        <TagsEditBar
          tags={tagState}
          onAddTag={onAddTag}
          onDeleteTag={handleDeleteTag}
          allTags={data?.doctorTags.filter(
            (tag) => !tagState.some((t) => t._id === tag._id)
          )}
          dropdownAnchor={containerRef.current}
          dropdownRef={dropdownRef}
          createLoading={createLoading}
        />
      )}
    </div>
  );
};

export default TagsCell;

const ADD_TAG_MUTATION = gql`
  mutation addTagToTemplate($templateId: ID, $tags: [ID]) {
    addTagToTemplate(templateId: $templateId, tags: $tags) {
      _id
    }
  }
`;
