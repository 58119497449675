import { Box, Typography, Button, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Text from 'Text';

interface IProps {
  icon?: JSX.Element;
  btnIcon?: JSX.Element;
  title: string;
  isText?: boolean;
  onClick?: () => void;
  isClickLeft?: boolean;
  buttonText?: string;
  text?: string;
  simpleText?: boolean;
  hasAction?: boolean;
  titleMaxWidth?: string;
  noActionText?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#101828',
    marginTop: '10px',
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#475467',
  },
  simpleText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#475467',
  },
  click: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#41A8FF',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  button: {
    marginTop: '10px',
    padding: '8px 12px',
    backgroundColor: '#147AF3',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    gap: '8px',
    textTransform: 'none',
    border: '1px solid #0265DC',
    borderRadius: '8px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#0265DC',
    },
  },
}));

function EmptyState({
  icon,
  title,
  isText,
  onClick,
  isClickLeft = true,
  buttonText,
  text,
  simpleText = false,
  hasAction = true,
  titleMaxWidth = '100%',
  noActionText = false,
  btnIcon = null,
}: IProps) {
  const classes = useStyles();
  if (simpleText)
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.simpleText}>{title}</Typography>
      </Box>
    );
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Box>{icon}</Box>
      <Typography style={{ maxWidth: titleMaxWidth }} className={classes.title}>
        {title}
      </Typography>
      {noActionText && <Typography className={classes.text}>{text}</Typography>}
      {hasAction && (
        <Box display="flex" justifyContent="center" alignItems="center">
          {isText ? (
            <Box>
              {isClickLeft ? (
                <Box display="flex" style={{ gap: '5px' }}>
                  <Text
                    i18nKey="clickHere"
                    className={classes.click}
                    onClick={onClick}
                  >
                    Click here
                  </Text>
                  <Typography className={classes.text}>{text}</Typography>
                </Box>
              ) : (
                <Box display="flex" style={{ gap: '5px' }}>
                  <Typography className={classes.text}>{text}</Typography>
                  <Text
                    i18nKey="clickHere"
                    className={classes.click}
                    onClick={onClick}
                  >
                    click here
                  </Text>
                </Box>
              )}
            </Box>
          ) : btnIcon ? (
            <Button
              startIcon={btnIcon}
              className={classes.button}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          ) : (
            <Button className={classes.button} onClick={onClick}>
              {buttonText}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

export default EmptyState;
