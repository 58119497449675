import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import Button from 'components/ui/Button';
import { ReactComponent as PaperClip } from 'assets/paperclip.svg';
import { ReactComponent as PaperClipWhite } from 'assets/PaperClipWhite.svg';
import { useProvider } from 'pages/InboxPage/state/Provider';
import concatName from 'utils/userUtils';
import useStyles from './style';
import { ReactComponent as InfoIcon } from 'assets/Button.svg';
import { ReactComponent as GreyArchiveIcon } from 'assets/archive.svg';
import { ReactComponent as FileICon } from 'assets/file.svg';
import { ReactComponent as ReturnIcon } from 'assets/returnArrow.svg';
import Popover from '@material-ui/core/Popover';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { ReactComponent as GroupChatIcon } from 'assets/group.svg';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Archive } from 'assets/icons/box.svg';
import { ReactComponent as Unarchive } from 'assets/icons/Unarchive.svg';

import { ReactComponent as BookMark } from 'assets/icons/bookmark.svg';
import { ReactComponent as Delete } from 'assets/icons/trash-01.svg';

import Text from 'Text';
import Menu from 'components/ui/Menu';
import { useSnackbar } from 'hooks/useSnackbar';
interface IHeadMessageListProps {
  setFilesHidden: (value: boolean) => void;
  patient?: boolean;
}

const HeadMessageList: React.FC<IHeadMessageListProps> = ({
  setFilesHidden,
  patient,
}) => {
  const { user } = useAuth();
  const classes = useStyles({ patient });
  const [open, setOpen] = React.useState(false);
  function handleCloseModal() {
    setOpen(false);
  }

  const {
    useLazyGetThreadConversationById,
    selected,
    handelUpdateIsArchivedThreadConversation,
    setMessageHidden,
    handleSelectedThead,
  } = useProvider();
  const [getThreadById, { data }] = useLazyGetThreadConversationById();
  const { triggerSnack } = useSnackbar();

  useEffect(() => {
    if (selected) {
      getThreadById({
        variables: {
          threadConversationID: selected,
        },
      });
    }
  }, [selected]);
  const participants = data?.getThreadConvertationById?.participants;
  const users = participants
    ?.map(({ user }) => user)
    .filter((el) => el?._id !== (user?._id || user?.user?._id));

  const handelArchivedStatus = async (
    selectedThread: string | undefined,
    Status: boolean
  ) => {
    if (selectedThread) {
      await handelUpdateIsArchivedThreadConversation(selectedThread, Status);
      handleSelectedThead(null);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClicked, setIsClicked] = React.useState(false);

  const openPopover = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlefileClick = () => {
    setFilesHidden(false);
    handleClose();
  };
  const handlemessageClick = () => {
    setMessageHidden(true);
  };
  function handleOpenModal() {
    setOpen(true);
  }
  const { t } = useTranslation();
  const menuItems = [
    {
      avatar: data?.getThreadConvertationById.isArchived ? (
        <Unarchive style={{ marginRight: '8px' }} />
      ) : (
        <Archive style={{ marginRight: '8px' }} />
      ),
      name: data?.getThreadConvertationById.isArchived
        ? 'Unarchive'
        : 'Archive',
      onClick: () => handleOpenModal(),
    },
  ];
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      boxSizing="border-box"
      px="2rem"
      className={classes.HeadMessageList}
    >
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xl={12} lg={12} md={12} xs={11} sm={11}>
          <Box display="flex" alignItems="center">
            <GroupChatIcon />
            <Box ml={1.5} gridGap={'6px'} display="flex" flexDirection="column">
              <Typography className={classes.HeadMessageListSenderName}>
                {data?.getThreadConvertationById &&
                  data?.getThreadConvertationById.subject}
              </Typography>
              <Box display="flex" alignItems="center">
                {users?.map((el) => (
                  <Typography
                    className={classes.HeadMessageListSubject}
                    style={{ marginRight: '2px' }}
                  >
                    {concatName(el?.firstname, el?.middlename, el?.lastname)},
                  </Typography>
                ))}
                <Text i18nKey="you" className={classes.HeadMessageListSubject}>
                  You
                </Text>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button
          variant="outlined"
          startIcon={<PaperClipWhite />}
          className={classes.ArchiveBtn}
          onClick={handlefileClick}
        >
          {t('Shared files')}
        </Button>
        <Box
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            borderRadius: '50%',
            marginLeft: '10px',
            backgroundColor: isClicked && '#F0F9FF',
          }}
        >
          <InfoIcon
            onClick={() => setIsClicked(!isClicked)}
            style={{ width: '45px' }}
          />
        </Box>
        <Menu<any>
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          handleMenuClose={() => setAnchorEl(null)}
          MenuElements={menuItems}
          style={{ width: '100%' }}
        />
      </Box>

      {/* <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={false}
      >
        <Box
          display="flex"
          className={classes.popupitems}
          onClick={handlefileClick}
        >
          <FileICon />
          <Typography className={classes.popupTitle}>Shared files</Typography>
        </Box>
        {selected && (
          <Box
            display="flex"
            className={classes.popupitems}
            onClick={handleOpenModal}
          >
            <GreyArchiveIcon />
            <Typography className={classes.popupTitle}>
              {data?.getThreadConvertationById.isArchived
                ? 'Unarchive'
                : 'Archive'}
            </Typography>
          </Box>
        )}
      </Popover> */}
      <ModalDelete
        text="conversation"
        open={open}
        message={
          data?.getThreadConvertationById.isArchived ? 'unarchive' : 'archive'
        }
        archive={true}
        isArchived={data?.getThreadConvertationById.isArchived}
        onClose={handleCloseModal}
        onDelete={() => {
          handelArchivedStatus(
            selected,
            !data?.getThreadConvertationById.isArchived
          );
          handleCloseModal();
          triggerSnack(
            data?.getThreadConvertationById.isArchived
              ? `Conversation unarchived succssfully`
              : `Conversation archived succssfully`
          );
        }}
      />
    </Box>
  );
};

export default HeadMessageList;
