import { gql, useMutation } from '@apollo/client';

export const REMOVE_RATE_ROLE = gql`
  mutation updateRate($rateId: ID, $roleId: ID) {
    removeRateRole(rateId: $rateId, roleId: $roleId) {
      session
      duration
      currency
      rate
      selected
    }
  }
`;

export default function useRemoveRateRole() {
  const [removeRateRole, result] = useMutation(REMOVE_RATE_ROLE, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          rates() {
            return data?.removeRateRole;
          },
          rateByRole() {
            return data?.removeRateRole;
          },
        },
      });
    },
  });
  return { removeRateRole, ...result };
}
