import { Box } from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import React from 'react';
import useStyles from './styles';
import Text from 'Text';
import { ReactComponent as Agreement } from 'assets/agreement.svg';
import { ReactComponent as Link } from 'assets/link.svg';
import { ReactComponent as UncheckedBox } from 'assets/unchecked-checkbox.svg';
import { ReactComponent as CheckedBox } from 'assets/checked-box.svg';
import useEdituser from 'graphql/operations/userOperations/useUpdateUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Button from 'components/ui/Button';

const PopupAgreement = () => {
  const classes = useStyles();
  const { updateUser } = useEdituser();
  const { user: authData, role } = useAuth();
  const { agreement } = authData;
  const [isAgreementOpen, setIsAgreementOpen] = React.useState(
    !agreement && role === 'patient' ? true : false
  );
  const [checkedAgreement, setCheckedAgreement] = React.useState({
    isBAA: role === 'patient' ? true : false,
    isPolicy: false,
    isCondition: false,
  });
  const handleCheckedBoxBAA = () => {
    setCheckedAgreement({
      ...checkedAgreement,
      isBAA: !checkedAgreement.isBAA,
    });
  };
  const handleCheckedBoxPolicy = () => {
    setCheckedAgreement({
      ...checkedAgreement,
      isPolicy: !checkedAgreement.isPolicy,
    });
  };
  const handleCheckedBoxCondition = () => {
    setCheckedAgreement({
      ...checkedAgreement,
      isCondition: !checkedAgreement.isCondition,
    });
  };

  const handleClickBusiness = () => {
    window.open(
      'https://www.eggmed.com/business-associate-agreement',
      '_blank'
    );
  };
  const handleClickPolicy = () => {
    window.open('https://www.eggmed.com/privacy-policy', '_blank');
  };
  const handleClickCondition = () => {
    window.open('https://www.eggmed.com/terms-and-conditions', '_blank');
  };

  const handleAgree = async () => {
    await updateUser({
      variables: {
        userId: authData._id || authData?.user?._id,
        updateUser: {
          agreement: {
            isAgreement: true,
          },
        },
      },
    });
    setIsAgreementOpen(false);
  };
  return (
    <BasicModal open={isAgreementOpen}>
      <Box className={classes.popupWrapper}>
        <Box className={classes.popUpBox}>
          <Agreement />
          <Text i18nKey="Agreement required" className={classes.agreementText}>
            Agreement required
          </Text>
          <Box className={classes.agreeText}>
            <Text
              i18nKey="By proceeding, you confirm that you agree to the following:"
              className={classes.subText}
            >
              By proceeding, you confirm that you agree to the following:
            </Text>
          </Box>
          <Box className={classes.allLinks}>
            {role !== 'patient' && (
              <Box className={classes.links}>
                {!checkedAgreement.isBAA ? (
                  <UncheckedBox onClick={handleCheckedBoxBAA} />
                ) : (
                  <CheckedBox onClick={handleCheckedBoxBAA} />
                )}
                <Box
                  onClick={handleClickBusiness}
                  className={classes.linkWrapper}
                >
                  <Text
                    i18nKey="Business Associate Agreement"
                    className={classes.link}
                  >
                    Business Associate Agreement
                  </Text>
                  <Link />
                </Box>
              </Box>
            )}

            <Box className={classes.links}>
              {!checkedAgreement.isPolicy ? (
                <UncheckedBox onClick={handleCheckedBoxPolicy} />
              ) : (
                <CheckedBox onClick={handleCheckedBoxPolicy} />
              )}
              <Box onClick={handleClickPolicy} className={classes.linkWrapper}>
                <Text i18nKey="Privacy Policy" className={classes.link}>
                  Privacy Policy
                </Text>
                <Link />
              </Box>
            </Box>
            <Box className={classes.links}>
              {!checkedAgreement.isCondition ? (
                <UncheckedBox onClick={handleCheckedBoxCondition} />
              ) : (
                <CheckedBox onClick={handleCheckedBoxCondition} />
              )}
              <Box
                onClick={handleClickCondition}
                className={classes.linkWrapper}
              >
                <Text i18nKey="Terms and Conditions" className={classes.link}>
                  Terms and Conditions
                </Text>
                <Link />
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          className={classes.agreeButton}
          disabled={
            !checkedAgreement.isBAA ||
            !checkedAgreement.isCondition ||
            !checkedAgreement.isPolicy
          }
          style={{
            background:
              !checkedAgreement.isBAA ||
              !checkedAgreement.isCondition ||
              !checkedAgreement.isPolicy
                ? '#D0D5DD'
                : '#0265DC',
          }}
          onClick={handleAgree}
        >
          <Text i18nKey="Agree" className={classes.agreeButtonText}>
            Agree
          </Text>
        </Button>
      </Box>
    </BasicModal>
  );
};

export default PopupAgreement;
