import { gql, useMutation } from '@apollo/client';

const REMOVE_RELATED_PATIENT = gql`
  mutation removeRelatedPatient($patientId: ID, $isRemoved: Boolean) {
    removeRelatedPatient(patientId: $patientId, isRemoved: $isRemoved)
  }
`;
const useRemoveRelatedPatient = () => {
  const [removePatient, { loading }] = useMutation(REMOVE_RELATED_PATIENT, {
    refetchQueries: [
      {
        query: PATIENTS_RELATED,
        variables: { doctors: [], isUnassign: false },
      },
    ],
  });
  async function handleRemoveRelatedPatient(
    patientId: String,
    isRemoved: boolean
  ) {
    try {
      return await removePatient({
        variables: { patientId, isRemoved },
      });
    } catch (e) {
      return e;
    }
  }
  return { handleRemoveRelatedPatient, loading };
};

export { REMOVE_RELATED_PATIENT };
export default useRemoveRelatedPatient;
export const PATIENTS_RELATED = gql`
  query patientsRelated($doctors: [ID], $isUnassign: Boolean) {
    patientsRelated(doctors: $doctors) {
      _id
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      gender
      bloodType
      createdAt
      age
      state
    }
    listUnassignedClient(doctors: $doctors, isUnassign: $isUnassign) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      picture
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        isDisplayed
        diagnosisCode {
          description
        }
      }
      relatedTo {
        _id
        firstname
        lastname
        picture
        role
      }
      isDelete
    }
  }
`;
