import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as AddIcon } from 'assets/plus-square.svg';
import { Box, Typography, Avatar, Checkbox } from '@material-ui/core';
import { useStyles } from '../styles';
import Button from 'components/ui/Button';
import { IRole } from './PermissionSettings';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import SessionSelect from 'pages/SchedulePage/AddEventPage/EventForm/SessionSelect';
import useAssignRate from '@eggmed/graphql-client/operations/ratesOperations/useAssignRate';
import { useSnackbar } from 'hooks/useSnackbar';
import ProgressLoader from 'components/ui/ProgressLoader';

interface IEditProps {
  open: boolean;
  onClose: any;
  role: IRole;
  teams: any;
}

function AssignService({ open, onClose, role, teams }: IEditProps) {
  const { assignRate, loading } = useAssignRate();
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const { control, watch, register, errors, setValue, handleSubmit } =
    useFormContext();
  const [isUpdated, setIsUpdated] = React.useState(true);
  const isType = watch('type') || false;
  const isTyping = watch('typing') || [];
  function getSelectedUserIds(typingArray, teamSettings) {
    if (!Array.isArray(typingArray) || !Array.isArray(teamSettings)) {
      return [];
    }

    return teamSettings
      .filter((_, index) => typingArray[index])
      .map((team) => team._id);
  }
  async function onSubmit(data) {
    const selectedUserIds = getSelectedUserIds(
      data?.typing,
      teams?.teamSettings
    );
    const sessionId = data?.sessionType?._id;
    await assignRate({
      variables: {
        rateId: sessionId,
        isRole: data?.type,
        assignees: selectedUserIds,
        role: { _id: role?._id, name: role?.name },
      },
    });
    onClose();
    triggerSnack('Service assigned successfully');
  }
  React.useEffect(() => {
    const sessionType = watch('sessionType');
    if (sessionType) {
      if (sessionType?.assignedRoles?.some((el) => el?._id === role?._id)) {
        setIsUpdated(false);
        setValue('type', true);
      } else {
        setIsUpdated(false);
        setValue('type', false);
      }

      if (sessionType?.assignedProviders) {
        const assignedProviderIds = sessionType?.assignedProviders.map(
          (provider) => provider?._id
        );

        teams?.teamSettings?.forEach((team, idx) => {
          const isAssigned = assignedProviderIds.includes(team._id);
          const currentValue = watch(`typing.${idx}`);

          if (currentValue !== isAssigned) {
            setIsUpdated(false);
            setValue(`typing.${idx}`, isAssigned);
          } else {
            setIsUpdated(false);
          }
        });
      }
    }
  }, [watch('sessionType'), teams?.teamSettings, role?._id, setValue]);

  React.useEffect(() => {
    if (teams?.teamSettings?.length > 0 && isUpdated) {
      teams.teamSettings.forEach((_, idx) => {
        setValue(`typing.${idx}`, isType);
      });
    }
  }, [teams?.teamSettings?.length, setValue, isType, isUpdated]);

  return (
    <BasicModal open={open} onClose={onClose} handleClose={onClose} isNew>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.PasswordModal}>
          <AddIcon />
          <Typography className={classes.modalTitle}>
            Assign a service
          </Typography>
          <Typography className={classes.modalText}>
            Select the service you want to make available for the{' '}
            <span style={{ fontWeight: '700' }}>{role?.name}</span> role
          </Typography>
          <Box mt={2}>
            <SessionSelect
              feedMode={false}
              control={control}
              register={register}
              errors={errors}
              hasMeetingOptions={false}
              placeholder="Select a service to assign"
            />
          </Box>
          <Box
            mt={2}
            mb={4}
            py={1}
            pl={1}
            pr={2}
            style={{
              backgroundColor: '#F5FBFF',
              border: '1px solid #147AF3',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              py={1}
              borderBottom={'0.5px solid #CAE8FF'}
            >
              <Box display="flex" alignItems="center">
                <Controller
                  name={'type'}
                  control={control}
                  render={({ onChange, value, ref }) => {
                    return (
                      <Checkbox
                        size="medium"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setIsUpdated(true);
                        }}
                        checkedIcon={<CheckedIcon width={16} height={16} />}
                        icon={<UncheckedIcon width={16} height={16} />}
                        style={{
                          backgroundColor: 'transparent',
                          marginLeft: '-5px',
                        }}
                      />
                    );
                  }}
                />
                <Typography
                  style={{
                    color: '#344054',
                    fontWeight: 700,
                    fontSize: '14px',
                  }}
                >
                  Assign to {role?.name} role
                </Typography>
              </Box>
            </Box>
            <Box>
              {teams?.teamSettings
                ?.filter((el) => el?.role !== 'owner')
                ?.map((team, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={1}
                    borderBottom={
                      index !== teams?.teamSettings?.length - 1 &&
                      '0.5px solid #CAE8FF'
                    }
                  >
                    <Box display="flex" alignItems="cemter">
                      <Box mt={-0.5}>
                        <Controller
                          name={`typing.${index}`}
                          defaultValue={false}
                          control={control}
                          render={({ onChange, value, ref }) => {
                            return (
                              <Checkbox
                                size="medium"
                                checked={isTyping[index] || false}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  if (!e.target.value) {
                                    setIsUpdated(false);
                                    setValue('type', false);
                                  } else {
                                    setIsUpdated(true);
                                  }
                                }}
                                checkedIcon={
                                  <CheckedIcon width={16} height={16} />
                                }
                                icon={<UncheckedIcon width={16} height={16} />}
                                style={{
                                  backgroundColor: 'transparent',
                                  marginLeft: '-5px',
                                }}
                              />
                            );
                          }}
                        />
                      </Box>
                      <Avatar
                        src={team.picture}
                        style={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 10,
                          textTransform: 'capitalize',
                        }}
                      >
                        {team?.firstname && team?.firstname[0]}{' '}
                        {team?.lastname && team?.lastname[0]}
                      </Avatar>
                      <Typography
                        style={{
                          marginLeft: '5px',
                          color: '#344054',
                          fontSize: '14px',
                          fontWeight: 500,
                          textTransform: 'capitalize',
                          marginTop: '2px',
                        }}
                      >
                        {team?.firstname} {team?.lastname}
                      </Typography>{' '}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ gap: '1rem' }}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.saveButton}
              disabled={!isTyping?.some((state) => state) && !isType}
            >
              {loading ? <ProgressLoader width={18} height={18} /> : 'Assign'}
            </Button>
          </Box>
        </Box>
      </form>
    </BasicModal>
  );
}

export default AssignService;
