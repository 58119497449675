import { createContext } from 'react';
import {
  
  OPEN_PPROVIDER_ASSIGN_MODAL,
  CLOSE_ASSIGN_MODAL,
  SET_PROVIDERS,
} from './action';

import { IProviderData } from '../AssignProvider';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';



interface IAssignProviderContext extends IInitialState {

  handleOpenAssignModal?: () => void;
  handleCloseAssignModal?: () => void;
  assignProviderTitle: string,
  setAssignProviderTitle: (assignProviderTitle?: string) => void,
}

interface IInitialState {
  openPatientModal: boolean;
  openProviderAssignModal: boolean;
  providers: IProviderData[];
  
}


export * from './action';

export const initialState = {
  openPatientModal: false,
  openProviderAssignModal: false,
  providers: [],
};

export const Context = createContext<IAssignProviderContext | undefined>(
  undefined
);

interface IAction {
  type:

    | typeof OPEN_PPROVIDER_ASSIGN_MODAL
    | typeof CLOSE_ASSIGN_MODAL
    | typeof SET_PROVIDERS

  payload?: any;
}

export default function Reducer(state: IInitialState, action: IAction) {
  switch (action.type) {    
    case SET_PROVIDERS:
      return { ...state, providers: action.payload};    
    case OPEN_PPROVIDER_ASSIGN_MODAL:
      return { ...state, openProviderAssignModal: true };    
    case CLOSE_ASSIGN_MODAL:
      return { ...state, openProviderAssignModal: false };   
    default:
      throw new Error('No action found');
  }
}
