import React from 'react';
import { InfoBlock } from './InfoBlock';
import { useTranslation } from 'react-i18next';
import { useStyles } from './PracticeInformation';
import { Box, Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import Text from 'Text';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as Trash } from 'assets/ClearTrash.svg';
import SignatureCanvas from 'react-signature-canvas';
import { TranslationKeys } from 'shared/interfaces/i18';

const SignatureComponent = ({
  showContent,
  updatedAt,
  BlockNames,
  editableBlock,
  setEditableBlock,
  loadingQuery,
  setEditMode,
  setOpen,
  loadingSignature,
  options,
  handleSignatureSelect,
  checked,
  setSelectedSignature,
  signatures,
  commonProps,
  loading,
  sigCanvasRef,
  handleChange,
  selectedSignature,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <InfoBlock
      title={'Signature'}
      content={showContent ? updatedAt : t('No signature recorded')}
      contentClassName={!showContent && classes.content}
      description={t("The signature you'll use for forms and contracts")}
      blockName={BlockNames.signature}
      isEditable={editableBlock === BlockNames.signature}
      editableBlock={editableBlock}
      setEditableBlock={setEditableBlock}
      loading={loadingQuery}
      showAddButtonInitially={!showContent}
      buttonClick={() => {
        setEditMode(showContent ? true : false);
        setOpen(true);
      }}
      disabledButton={loadingSignature}
      buttonLoading={loadingSignature}
    >
      <Box
        sx={{
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box className={classes.topsection}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={'2rem'}
            width="50%"
          >
            {options.map((el, key) => (
              <Box display="flex" alignItems="center" flexDirection="row">
                <Radio
                  className={classes.rootRadio}
                  color="default"
                  disableRipple
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.iconRadio,
                        classes.checkedIconRadio
                      )}
                    />
                  }
                  icon={<span className={classes.iconRadio} />}
                  checked={checked === el.value}
                  onChange={handleChange}
                  value={el.value}
                  data-cy="male-input"
                  inputProps={{ 'aria-label': 'A' }}
                />
                <Typography
                  className={classes.radioLabel}
                  style={{
                    fontWeight: checked === el?.value ? 700 : 500,
                  }}
                >
                  {' '}
                  {t(`${el?.name as TranslationKeys}`)}
                </Typography>
              </Box>
            ))}
          </Box>
          {checked === 0 && (
            <Box px="4.1rem">
              <Text i18nKey="Your name" className={classes.label}>
                Your name
              </Text>
              <InputBlock
                {...commonProps}
                name="name"
                type="text"
                style={{ width: '50%' }}
                onChange={() => setSelectedSignature(0)}
              />
            </Box>
          )}
        </Box>
        <Box mt={4} mb={2} px={checked === 0 ? '4.1rem' : '2rem'}>
          {checked === 0 && (
            <Box>
              <Text i18nKey="Pick preferred option" className={classes.label}>
                Pick preferred option
              </Text>
              <div style={{ width: '100%' }}>
                {signatures?.length > 0 && !loading
                  ? signatures.map((signature, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Radio
                          className={classes.rootRadio}
                          color="default"
                          disableRipple
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.iconRadio,
                                classes.checkedIconRadio
                              )}
                            />
                          }
                          icon={<span className={classes.iconRadio} />}
                          checked={selectedSignature === index}
                          onChange={(e) => {
                            e.preventDefault();
                            handleSignatureSelect(index);
                          }}
                          data-cy="male-input"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                        <img
                          src={`data:image/svg+xml;base64,${btoa(signature)}`}
                          alt={`Signature ${index + 1}`}
                          width="30%"
                          height="30%"
                          style={{ marginTop: '15px' }}
                        />
                      </div>
                    ))
                  : loading && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '50px',
                          height: '50px',
                        }}
                      >
                        <ProgressLoader width={20} height={20} color="blue" />
                      </div>
                    )}
              </div>
            </Box>
          )}
          {checked === 1 && (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
                width={500}
              >
                <Text i18nKey="Draw your signature" className={classes.label}>
                  Draw your signature
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => sigCanvasRef.current.clear()}
                >
                  <Trash style={{ marginRight: '5px' }} />
                  <Text i18nKey="Clear" className={classes.clearTxt}>
                    Clear
                  </Text>
                </Box>
              </Box>
              <Box
                style={{
                  border: '1px solid black',
                  height: 200,
                  width: 500,
                }}
              >
                <SignatureCanvas
                  ref={sigCanvasRef}
                  penColor="black"
                  canvasProps={{
                    height: 200,
                    width: 500,
                    className: 'sigCanvas',
                  }}
                />{' '}
              </Box>
            </Box>
          )}
        </Box>
        <Box mt="auto" pb={2} px={'2rem'}>
          <Text
            i18nKey="SignatureNotice"
            pb={2}
            px={'2rem'}
            className={classes.paragraph}
          >
            By clicking Create, I agree that the signature will be the
            electronic representation of my signature for all purposes when I
            use them on forms, including legally binding contracts.{' '}
          </Text>
        </Box>
      </Box>
    </InfoBlock>
  );
};

export default SignatureComponent;
