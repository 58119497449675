import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { formatToFullDate, formatFullDate } from 'utils';
import concatName from 'utils/userUtils';
import {
  GetThreadMessages_getThreadMessages,
  GetThreadMessages_getThreadMessages_content_files,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import useStyles from './style';
import { _file } from './utils';
import { getIconByMimeTypeAndUrl } from './FileList';
import Menu from 'components/ui/Menu';
import { ReactComponent as Archive } from 'assets/icons/box.svg';
import { ReactComponent as BookMark } from 'assets/icons/bookmark-bubble.svg';
import { ReactComponent as Delete } from 'assets/icons/trash-01.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/dots-vertical_bubble.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

interface IMessageBubble {
  isOwner: boolean;
  data: GetThreadMessages_getThreadMessages;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
}
const MessageBubble: React.FC<IMessageBubble> = ({
  isOwner,
  data,
  isFirstInGroup,
  isLastInGroup,
}) => {
  const { updatedAt, content, user } = data;
  const { text, files } = content;
  const { firstname, lastname, middlename, picture } = user;
  const classes = useStyles({ patient: false });
  const direction = isOwner ? 'row' : 'row-reverse';
  const onDownload = (url: string, name: string) => {
    const link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.click();
  };

  const fileCard = (
    file: GetThreadMessages_getThreadMessages_content_files
  ) => {
    const { url, fileName, mimeType } = file;
    return (
      <Box className={classes.fileCardContainer}>
        <Box className={classes.fileTypeIcon}>
          {getIconByMimeTypeAndUrl(mimeType, url)}
        </Box>
        <Box onClick={() => onDownload(url, fileName)}>
          <Typography className={classes.fileText}>{fileName}</Typography>
        </Box>
      </Box>
    );
  };

  const renderFiles = (
    files: GetThreadMessages_getThreadMessages_content_files[]
  ) => {
    if (files.length > 0) {
      const fileContainer = files.map((file) => {
        const { fileName, url, mimeType } = file;
        return (
          <Grid item key={fileName}>
            {mimeType.split('/')[0] === 'image' ? (
              <img
                className={classes.imageInBubble}
                src={url}
                alt={fileName}
                loading="lazy"
              />
            ) : (
              fileCard(file)
            )}
          </Grid>
        );
      });

      return fileContainer;
    }
  };
  const menuItems = [
    {
      avatar: <EditIcon style={{ marginRight: '8px' }} />,
      name: 'Edit',
    },
    {
      avatar: <Delete style={{ marginRight: '8px' }} />,
      name: 'Delete',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isClicked, setIsClicked] = React.useState(false);
  const [isClickedBubble, setIsClickedBubble] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Grid container direction={direction} className={classes.bubbleContainer}>
      <Tooltip
        classes={{ tooltip: classes.customTooltip }}
        title={formatToFullDate(updatedAt)}
      >
        <Box
          onClick={() => setIsClickedBubble(!isClickedBubble)}
          className={classes.bubbleMessage}
        >
          <Box
            display="flex"
            justifyContent="flex-direction"
            alignItems="flex-end"
            mr={1}
          >
            {!isOwner && isLastInGroup && (
              <Avatar
                alt={firstname.toUpperCase()}
                src={picture}
                style={{
                  width: '45px',
                  height: '45px',
                }}
              />
            )}
          </Box>
          {/* <Box onClick={handleClick} className={classes.moreInfo}>
            <InfoIcon style={{ width: '45px' }} />
          </Box>

          <Menu<any>
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={!!anchorEl}
            handleMenuClose={() => setAnchorEl(null)}
            MenuElements={menuItems}
            style={{ width: '100%' }}
          />

          <BookMark
            onClick={() => setIsClicked(!isClicked)}
            className={`${classes.markIcon} ${isClicked ? 'filled' : ''}`}
          />*/}
          <Box
            className={clsx(
              classes.bubble,
              isOwner
                ? classes.bubbleRight
                : isLastInGroup
                ? classes.bubbleLeft
                : classes.bubbleLeftFirstGroup
            )}
            // ml={isAvatarHidden && 1 + 4}
            style={{
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: isFirstInGroup ? '8px' : '20px',
            }}
          >
            <Box>{renderFiles(files)}</Box>
            <Box>
              <Box>
                <Typography className={classes.bubbleText}>{text}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};

export default MessageBubble;
