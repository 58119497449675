import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  menubar: {
    position: 'absolute',
    right: -12,
    zIndex: 1,
    top: 10,
    width: 32,
    height: 29,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    transition: 'all 0.3s',
    transform: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      isSidebarExpanded ? 'rotate(0deg) ' : 'rotate(180deg)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  root: {
    position: 'sticky',
    top: '0',
    height: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    borderRight: '0.5px solid #EAECF0',
    [theme.breakpoints.only('xl')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '6px',
      paddingBottom: ({ isPatient }: { isPatient: boolean }) =>
        isPatient ? '5rem' : '15px',
      // justifyContent: 'center',
      // alignContent: 'center',
      // alignItems: 'center',
    },
    [theme.breakpoints.only('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '15px',
      // justifyContent: 'center',
      // alignContent: 'center',
      // alignItems: 'center',
    },
    [theme.breakpoints.only('md')]: {
      width: '8.11vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.only('sm')]: {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      position: 'fixed',
      overflowY: 'auto',
    },
    [theme.breakpoints.only('xs')]: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      position: 'fixed',
      overflowY: 'auto',
    },
  },
  sideBar: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      // '&:last-child': {
      //   BorderTop: 'px solid white'
      // },
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      // '&:last-child': {
      //   BorderTop: 'px solid white'
      // },
    },
  },
  boxText: {
    width: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      !isSidebarExpanded ? '100px' : '0px',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      left: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      left: '4rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  logoImgTag: {
    width: '100%',
  },
  logoPicTag: {
    position: 'absolute',
    width: '40vw',
    top: '1vh',
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      width: '25vw',
    },

    [theme.breakpoints.up('md')]: {
      left: '1.55vw',
      width: '5vw',
      marginTop: '0.5vw',
      marginLeft: 'unset',
      zIndex: 1000000000,
    },

    [theme.breakpoints.up('lg')]: {
      left: '0.55vw',
    },
  },
  separator: {
    border: '2px solid #F4F2EE',
    marginTop: '10px',
    marginBottom: '10px',
  },

  selectedIcon: {
    filter:
      'invert(28%) sepia(63%) saturate(679%) hue-rotate(179deg) brightness(90%) contrast(104%);',
    marginRight: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      isSidebarExpanded ? '0px' : '8px',
  },
  selectedText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#0265DC',
    fontWeight: 700,
    // maxWidth: '6.11vw',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
  },
  text: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    color: '#182230',
    fontWeight: 600,
    // maxWidth: '6.11vw',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0px !important',

    width: '100%',

    [theme.breakpoints.only('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },

  selectedLink: {
    textDecoration: 'none',
    color: '#0265DC',
    backgroundColor: '#E0F2FE',
    fontWeight: 'bold',
  },

  link: {
    display: 'flex',
    color: '#182230',
    flexDirection: 'column',
    // justifyContent: 'center',
    // alignContent: 'center',
    // alignItems: 'center',
    textDecoration: 'none',
    borderRadius: '6px',
    fontFamily: 'Inter, sans-serif',
    transition: 'background-color 0.3s',
    padding: `${theme.spacing(1)}px 10px`,

    '&:hover': {
      backgroundColor: '#F0F9FF',
    },
    // textAlign: 'center',
    [theme.breakpoints.only('xl')]: {
      // textAlign: 'center',
      // padding: `${theme.spacing(2)}px 0px`,
      padding: `${theme.spacing(1)}px 10px`,

      marginInline: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? theme.spacing(2) : '16px',
      marginBlock: theme.spacing(0.5),
    },
    [theme.breakpoints.only('lg')]: {
      // textAlign: 'center',
      // padding: `${theme.spacing(0.85)}px 0px`,
      padding: `${theme.spacing(1)}px 10px`,

      marginInline: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
        !isSidebarExpanded ? theme.spacing(2) : '16px',
      marginBlock: theme.spacing(0.5),
    },
    [theme.breakpoints.only('md')]: {
      // textAlign: 'center',
      padding: `${theme.spacing(1)}px 0px`,
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '4vh',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '4vh',
    },
  },
  linkSettings: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.5vh',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingBottom: '15px',
    textDecoration: 'none',
    // textAlign: 'center',
    [theme.breakpoints.only('xl')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('lg')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  linkHover: {
    // '&:hover': {
    //   backgroundColor: '#f7f7f5',
    //   borderRadius: '6px',
    // },
  },
  links: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '45px',
    // justifyContent: 'space-between',
    width: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      !isSidebarExpanded ? '175px' : '71px',
    transform: 'translate(0)',
    transition: 'all 0.3s ease',
  },
  linksPatient: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      !isSidebarExpanded ? '162px' : '71px',
    transform: 'translate(0)',
    transition: 'all 0.3s ease',
  },
  icon: {
    [theme.breakpoints.only('sm')]: {
      width: '31px',
      height: '31px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '31px',
      height: '31px',
    },
    marginRight: ({ isSidebarExpanded }: { isSidebarExpanded: boolean }) =>
      isSidebarExpanded ? '0px' : '8px',
  },
  iconSettings: {
    width: '2vw',
    [theme.breakpoints.only('sm')]: {
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '24px',
      height: '24px',
    },
  },
  space: {
    // paddingTop: '10vw',
  },
  selected: {
    backgroundColor: '#E0F2FE',

    // [theme.breakpoints.only('sm')]: {
    //   display: 'none',
    //   // width: '32.8vw',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   display: 'none',
    //   // width: '32.8vw',
    // },
  },
  divider: {
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '7.9rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '7.9rem',
    },
  },
  dividerSettings: {
    display: 'none',
    [theme.breakpoints.only('sm')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '38.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      position: 'relative',
      // bottom:'1.4rem',
      // left:'0rem',
      backgroundColor: '#FFFFFF',
      opacity: '0.14',
      width: '100%',
      marginLeft: theme.spacing(0),
      top: '40rem',
    },
  },
}));
