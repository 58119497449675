import React, { useEffect } from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { ReactComponent as TooltipIcon } from 'assets/info-circle.svg';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DropDownSelect from '../DropdownSelect';
import { supervisors } from '../constants';
import SelectUser from '../SelectUser';
import { gql, useQuery } from '@apollo/client';
import TagSelect from 'pages/AdminPage/ManageTags/TagSelect';
import { colors } from 'pages/AdminPage/ManageTags/Constant';
import ErrorIcon from '@material-ui/icons/Error';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { convertObjectsFormatStaffs } from 'pages/SchedulePage/AddEventPage/utils';

function TeamMemberForm({
  editMode,
  selectedPatients,
  setSelectedPatients,
  roles,
  teamsMember,
  setSelectedSupervisors,
  selectedSupervisors,
  setSelectedColor,
  selectedColor,
}: {
  editMode: boolean;
  selectedPatients: any;
  setSelectedPatients: (patient: any) => void;
  roles: [string];
  teamsMember: any;
  setSelectedSupervisors: (supervisor: any) => void;
  selectedSupervisors: any;
  setSelectedColor: (color: any) => void;
  selectedColor: any;
}) {
  const classes = useStyles();
  const { control, errors, register, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const commonProps = { control, errors, register, variant: 'filled' };

  const { data } = useQuery(PATIENTS_RELATED);

  return (
    <Box>
      <Box px={'2rem'} pt={2} pb="2rem">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Email')}
              {...commonProps}
              name="email"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('First name')}
              {...commonProps}
              name="firstname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Last name')}
              {...commonProps}
              name="lastname"
              type="text"
            />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography
              className={classes.labelText}
              style={{ marginBottom: '6px' }}
            >
              Role
              <span style={{ color: '#D92D20' }}>*</span>
            </Typography>
            <Controller
              name="role"
              control={control}
              render={({ onChange, value }) => (
                <DropDownSelect
                  defaultMenuName={value}
                  setValue={(role) => onChange(role)}
                  placeholder="Select role"
                  options={roles ?? []}
                  onClickAddOption={() => {}}
                  addOptionText="Add a new role"
                  hasAddOption={false}
                  height="44px"
                  errors={!!errors?.role}
                />
              )}
            />
            {errors?.role && (
              <Box
                display="flex"
                alignItems="center"
                data-cy="error-message"
                mt={1}
              >
                <ErrorIcon
                  style={{ color: 'red', fontSize: 14 }}
                  className={classes.errorIcon}
                />
                <Typography style={{ color: 'red', fontSize: '12px' }}>
                  {t(errors['role']?.message)}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb={0.5}
            >
              <Typography
                className={classes.labelText}
                style={{ marginRight: 5 }}
              >
                Supervisor
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="Assign a Supervisor to oversee providers in training, ensuring compliance with supervision guidelines. Supervisors can review documentation, provide feedback, and monitor care to meet regulatory standards."
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <Symptoms
              img={true}
              type=""
              disableCustomFilter={true}
              options={convertObjectsFormatStaffs(teamsMember, '') || []}
              values={selectedSupervisors}
              setValues={setSelectedSupervisors}
              hasErrors={false}
              placeholder="Select team member"
              multiple={false}
            />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="1px"
            >
              <Typography
                className={classes.labelText}
                style={{ marginRight: 5 }}
              >
                NPI
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="This is only applicable to clinicians"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <InputBlock {...commonProps} name="npi" type="number" />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.labelText} style={{ margin: 0 }}>
              License number
            </Typography>
            <InputBlock {...commonProps} name="licensenumber" type="text" />
          </Grid>
          <Box className={classes.divider} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb={0.5}
            >
              <Typography className={classes.labelText} style={{ margin: 0 }}>
                Assigned clients
              </Typography>
            </Box>
            <Symptoms
              img={true}
              type=""
              disableCustomFilter={true}
              options={
                convertObjectsFormatStaffs(data?.patientsRelated, '') || []
              }
              values={selectedPatients}
              setValues={setSelectedPatients}
              hasErrors={false}
              placeholder="Add assigned clients"
              multiple
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              mb="6px   "
            >
              <Typography
                className={classes.labelText}
                style={{ marginRight: 5 }}
              >
                Color on the calendar
              </Typography>
              <Tooltip
                placement="top-start"
                classes={{ tooltip: classes.popup }}
                title="Select a color to represent this team member on the calendar"
              >
                <TooltipIcon />
              </Tooltip>
            </Box>
            <TagSelect
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              values={colors}
              hasLabel={false}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TeamMemberForm;

const PATIENTS_RELATED = gql`
  query patientsRelated($doctors: [ID]) {
    patientsRelated(doctors: $doctors) {
      _id
      firstname
      lastname
      picture
    }
  }
`;
