/*eslint-disable*/
import React, { ReactElement } from 'react';
import { TableBody, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Row from './TableRow';
import Cell, { ICellProps } from './TableCell';
import { IColumn } from './types';
import Skeleton from '../Skeleton';
import { selectedRow } from './utils';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  stateText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: '150%',
    color: '#425364',
    textTransform: 'capitalize',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(30),
  },
  textDetails: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(16),
    color: '#464665',
    textTransform: 'capitalize',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(10),
  },
  emptyState: {
    marginLeft: theme.typography.pxToRem(20),
  },
  alignCenter: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginLeft: '-2rem',
    [theme.breakpoints.only('md')]: {
      position: 'relative',
      left: '4.5rem',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'relative',
      left: '3rem',
    },
    [theme.breakpoints.only('xs')]: {
      position: 'relative',
      left: '-3.5rem',
    },
  },
  row: {
    cursor: ({ onClick }: { onClick: (row: unknown) => void }) =>
      onClick && 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
  },
  stickyLeft: {
    left: 0,
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0, // Shadow on the right edge
      bottom: 0,
      width: '5px', // Adjust thickness
    },
  },
  stickyRight: {
    right: 0,
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0, // Shadow on the left edge
      bottom: 0,
      width: '5px', // Adjust thickness
    },
  },
  sticky: {
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
  },
}));
interface ITableBodyProps<T> extends ICellProps {
  data: T[];
  columns: IColumn<T>[];
  loading: boolean;
  sorting?: any;
  isFreezed?: boolean;
  stateText?: string;
  EmptyState?: string;
  textDetails?: string;
  className?: string;
  onClick?: (row: T) => void;
  onMouseEnterRow?: (row: T) => void;
  onMouseLeaveRow?: (row: T) => void;
  rowStyle?: (row?: T) => any;
}

export default function TableBodyRow<T>({
  data,
  columns,
  loading,
  stateText,
  EmptyState,
  textDetails,
  type,
  className,
  onClick,
  onMouseEnterRow,
  onMouseLeaveRow,
  isFreezed = false,
  rowStyle = undefined,
}: ITableBodyProps<T>): ReactElement {
  const classes = useStyles({ onClick });
  const { t } = useTranslation();

  const formatValue = (row, id, format) => {
    const value = (row as any)[id];
    if (format) {
      const formattedValue = format(value, row);
      return typeof formattedValue === 'string'
        ? t(formattedValue)
        : formattedValue;
    }
    return value;
  };
  const Rows =
    data.length > 0 ? (
      data.map((row: T, index: number) => {
        const styleHover = rowStyle ? rowStyle(row) : null;
        return (
          <Row
            data-cy="row"
            key={`${row}${index}`}
            type={type}
            className={classes.row}
            style={styleHover && styleHover}
            onMouseEnter={() => onMouseEnterRow && onMouseEnterRow(row)}
            onMouseLeave={() => onMouseLeaveRow && onMouseLeaveRow(null)}
          >
            {columns.map(
              ({ id, format, align, freeze }: IColumn<T>, colIndex: number) => (
                <Cell
                  data-cy="table-row"
                  key={id}
                  type={type}
                  style={
                    isFreezed
                      ? {
                          height: '56px',
                          whiteSpace: 'nowrap',
                          ...styleHover,
                        }
                      : {
                          whiteSpace: 'nowrap',
                        }
                  }
                  align={align}
                  size="small"
                  borderTop={selectedRow(row)}
                  borderBottom={selectedRow(row)}
                  borderRight={selectedRow(row)}
                  borderLeft={selectedRow(row)}
                  onClick={() => onClick && onClick(row)}
                  className={clsx({
                    [classes.sticky]: freeze && isFreezed,
                    [classes.stickyLeft]: freeze && colIndex === 0 && isFreezed,
                    [classes.stickyRight]:
                      freeze && colIndex === columns.length - 1 && isFreezed,
                  })}
                >
                  <Skeleton loading={loading}>
                    <Box>{formatValue(row, id, format)}</Box>
                  </Skeleton>
                </Cell>
              )
            )}
          </Row>
        );
      })
    ) : (
      <Row data-cy="row" type={type} className={classes.row}>
        <Cell
          data-cy="table-row"
          type={'cellWidthOutBorder'}
          align="center"
          className={clsx(className, classes.tableCell)}
        >
          <img src={EmptyState} className={classes.emptyState} />
          <Typography className={classes.stateText}>{stateText}</Typography>
          <span className={classes.textDetails}>{textDetails}</span>
        </Cell>
      </Row>
    );
  if (loading)
    return (
      <>
        {[1, 2, 3].map(() => (
          <Row
            data-cy="row"
            type={type}
            style={{ width: '100%' }}
            className={classes.row}
          >
            {columns?.map(({ id, format, align }: any) => (
              <Cell
                data-cy="table-row"
                key={id}
                type={type}
                align={align}
                size="small"
              >
                <Skeleton loading={loading} height={30} width="100%">
                  <Typography />
                </Skeleton>
              </Cell>
            ))}
          </Row>
        ))}
      </>
    );
  return <TableBody>{Rows}</TableBody>;
}
