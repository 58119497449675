import React from 'react';
import { Box } from '@material-ui/core';
import Table from 'components/ui/Table';
import Columns from './Columns';

export interface ITeam {
  _id: string;
  picture: string;
  firstname: string;
  lastname: string;
  email: string;
  inviteStatus: string;
  role: string;
  lastActive: Date;
  supervisor: {
    picture: string;
    firstname: string;
    lastname: string;
  };
  patientsRelated: {
    _id: string;
    picture: string;
    firstname: string;
    lastname: string;
  };
  colorCalendar: string;
  doctorData: {
    _id: string;
    providerSettings: {
      npi: string;
      licenseNumber: string;
    };
  };
}

interface ITableInterface {
  team: ITeam[];
  handleOpenDropDown(e: React.MouseEvent<HTMLElement>, row: ITeam): void;
  handleEditFields(row: ITeam): void;
  handleViewFields(row: ITeam): void;
  handleChangeRole(row: ITeam, newRole: string): void;
  loading: boolean;
  roles: [string];
  query?: string;
}

function TeamSettingsTable({
  team,
  handleOpenDropDown,
  handleEditFields,
  handleViewFields,
  handleChangeRole,
  loading,
  roles,
  query,
}: ITableInterface) {
  const [hoveredRowIndex, setHoveredRowIndex] = React.useState<string | null>(
    null
  );
  const columnProps = {
    hoveredRowIndex,
    handleOpenDropDown,
    handleEditFields,
    handleChangeRole,
    roles,
    query,
  };
  const columns = Columns(columnProps);
  const [page, setPage] = React.useState(1);
  function handlePagination(e, page) {
    setPage(page);
  }
  return (
    <Box width="100%">
      <Table
        loading={loading}
        page={page}
        columns={columns as any}
        limit={10}
        data={team || []}
        sorting={false}
        onMouseEnterRow={(row) => setHoveredRowIndex(row?._id)}
        onMouseLeaveRow={() => setHoveredRowIndex(null)}
        onRowClick={(row) => handleViewFields(row)}
        pagination
        handlePagination={handlePagination}
      />
    </Box>
  );
}

export default TeamSettingsTable;
