import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography, Grid } from '@material-ui/core';
import Table from 'components/ui/Table';
import { IRole } from './PermissionSettings';
import { ReactComponent as DropDownComponent } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useStyles } from '../styles';
import Text from 'Text';
import { useRate } from 'pages/AdminPage/Payment/useRate';
import { ReactComponent as InPersonAppt } from 'assets/InPersonAppt.svg';
import { ReactComponent as VideoAppt } from 'assets/VideoAppt.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import TrashIcon from 'assets/TrashIcon';
import Menu from 'components/ui/Menu';
import RemoveServiceModal from './RemoveServieModal';
import CustomButton from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/patientActivities/pluss.svg';
import { ReactComponent as ManageAll } from 'assets/settings-02.svg';
import AssignService from './AssignModal';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import BasicModal from 'components/ui/Modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { schema, methodSchema } from 'pages/AdminPage/Payment/Rates';
import { sanitizeData } from 'utils';
import { useSnackbar } from 'hooks/useSnackbar';
import RatesModal from 'pages/AdminPage/Payment/RatesModal';
import { TEAM_MEMBERS } from '../teamtab/TeamSettings';
import { useQuery } from '@apollo/client';
interface ITableInterface {
  role: IRole;
  isSelected: boolean;
  handleSelect: () => void;
}

function BottomSection({ role, isSelected, handleSelect }: ITableInterface) {
  const classes = useStyles();
  const [currentRow, setSelectedRow] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [RemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const history = useHistory();
  const { triggerSnack } = useSnackbar();

  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  const handleClickDrop = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...(currentRow as any),
      tags: (currentRow as any)?.tags?.map((el) => {
        return { ...el, name: el.tag, id: el?._id };
      }),
      assignedRoles: (currentRow as any)?.assignedRoles?.map((el) => {
        return { ...el, name: el.name, id: el?._id };
      }),
      assignedProviders: (currentRow as any)?.assignedProviders?.map((el) => {
        return {
          ...el,
          name: `${el?.firstname} ${el?.middlename ?? ''} ${el?.lastname}`,
          id: el?._id,
        };
      }),
      cptCode: {
        ...(currentRow as any)?.cptCode,
        name: (currentRow as any)?.cptCode
          ? `${(currentRow as any)?.cptCode?.code} - ${
              (currentRow as any)?.cptCode?.description
            }`
          : '',
        id: (currentRow as any)?.cptCode?.code,
      },
    },
  });
  React.useEffect(() => {
    if (currentRow) {
      methods.reset({
        ...(currentRow as any),
        tags: (currentRow as any)?.tags?.map((el) => {
          return { ...el, name: el.tag, id: el?._id };
        }),
        assignedRoles: (currentRow as any)?.assignedRoles?.map((el) => {
          return { ...el, name: el.name, id: el?._id };
        }),
        assignedProviders: (currentRow as any)?.assignedProviders?.map((el) => {
          return {
            ...el,
            name: `${el?.firstname} ${el?.middlename ?? ''} ${el?.lastname}`,
            id: el?._id,
          };
        }),
        cptCode: {
          ...(currentRow as any)?.cptCode,
          name: (currentRow as any)?.cptCode
            ? `${(currentRow as any)?.cptCode?.code} - ${
                (currentRow as any)?.cptCode?.description
              }`
            : '',
          id: (currentRow as any)?.cptCode?.code,
        },
      });
    }
  }, [currentRow, methods.reset]);
  const [page, setPage] = React.useState(1);
  const handleEditFields = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const {
    data,
    rate,
    loadingRate,
    updateRate,
    updateLoading,
    removeRateRole,
    removeRoleLoading,
  } = useRate(role?._id, role?.name);
  const { data: teams, loading } = useQuery(TEAM_MEMBERS, {
    variables: {
      role: role?.name,
      status: '',
    },
  });

  const assignMethods = useForm({
    defaultValues: {
      type: false,
      typing: teams?.teamSettings?.map(() => false),
      sessionType: undefined,
    },
    resolver: yupResolver(methodSchema),
  });

  const locationIcon = {
    'In-person': <InPersonAppt />,
    Online: <VideoAppt />,
  };
  function handlePagination(e, pg) {
    setPage(pg);
  }
  const [error, setError] = React.useState('');
  const [id, setId] = React.useState(['']);

  async function onSubmit(data: any) {
    const sanitizedData = sanitizeData(data);
    const { duration, rate, isFree, ...rest } = sanitizedData || {};

    await updateRate({
      variables: {
        rateId: (currentRow as { _id: string })?._id,
        updateRate: {
          ...rest,
          isFree,
          duration: parseInt(duration, 10),
          rate: isFree ? 0 : parseInt(rate, 10),
          assignedRoles: data?.assignedRoles?.map((el) => el?._id),
          assignedProviders: data?.assignedProviders?.map((el) => el?._id),
        },
        isSelect: false,
      },
    });

    handleClose();
    triggerSnack('Service updated successfully');
  }

  const columns = [
    {
      id: 'session',
      label: (
        <Box fontSize="1rem">
          <Typography>Service</Typography>
        </Box>
      ),
      freeze: true,
      align: 'left',
      minWidth: 230,
      format: (value, row) => (
        <>
          {value}
          {row?.isDefault && (
            <Tooltip title="Default service">
              <Typography
                variant="caption"
                className={classes.type}
                style={{ marginLeft: '5px' }}
              >
                Default
              </Typography>
            </Tooltip>
          )}
        </>
      ),
    },

    {
      id: 'duration',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="duration">Duration</Text>
        </Box>
      ),
      align: 'left',
      minWidth: 100,
      format: (value) => value,
    },

    {
      id: 'rate',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="pricing">Pricing</Text>
        </Box>
      ),
      minWidth: 100,
      align: 'left',
      format: (value, { currency }) => `$${value}`,
    },
    {
      id: 'isPrepayment',
      label: (
        <Box fontSize="1rem">
          <Typography>Prepayment</Typography>
        </Box>
      ),
      minWidth: 10,
      align: 'left',
      format: (value) => (
        <Box
          style={{
            backgroundColor: value ? '#FEF3F2' : '#ECFDF3',
            border: `1px solid ${value ? '#FECDCA' : '#ABEFC6'}`,
            borderRadius: '16px',
            padding: '3px 8px',
          }}
          width="30%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontWeight: 500,
              fontSize: '12px',
              color: value ? '#B42318' : '#067647',
            }}
          >
            {value ? 'Required' : 'None'}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'location',
      label: (
        <Box fontSize="1rem">
          <Typography>Location</Typography>
        </Box>
      ),
      minWidth: 150,
      align: 'left',
      format: (value) => (
        <Box display="flex" alignItems="center">
          {locationIcon[value]}
          <Typography style={{ marginLeft: '5px' }}>{value || '-'}</Typography>
        </Box>
      ),
    },
    {
      id: 'actions',
      label: <Box fontSize="1rem"></Box>,
      minWidth: 10,
      align: 'left',
      freeze: true,
      format: (value, row) => (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={`${classes.actions} ${
              hoveredRowIndex === row?._id ? classes.actionsVisible : ''
            }`}
          >
            <EditIcon
              data-cy="edit-action"
              onClick={(e) => {
                e.stopPropagation();
                handleEditFields(row);
              }}
              style={{ cursor: 'pointer', marginRight: '1rem' }}
            />
          </Box>
          <Box onClick={(e) => handleClickDrop(e, row)}>
            <MoreIcon
              data-cy="more-action"
              style={{
                cursor: 'pointer',
                fontSize: '24px',
                color: '#425364',
                padding: '10px',
              }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const handleEdit = () => {
    handleEditFields(currentRow);
  };
  const handleRemove = () => {
    setRemoveModalOpen(true);
  };
  async function onSubmitRemove() {
    await removeRateRole({
      variables: {
        rateId: currentRow?._id,
        roleId: role?._id,
      },
    });
    setRemoveModalOpen(false);
    triggerSnack('Service removed successfully');
  }

  const menuElements = [
    {
      avatar: <EditIcon />,
      name: 'Edit',
      onClick: handleEdit,
    },
    {
      avatar: <TrashIcon width={18} height={18} fill="#B42318" />,
      name: 'Remove',
      onClick: handleRemove,
      isRed: true,
    },
  ];

  return (
    <Box width="100%" mt="1rem">
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        onClick={handleSelect}
        style={{
          cursor: 'pointer',
          borderBottom: !isSelected && '1px solid #D0D5DD',
          paddingBottom: !isSelected && '10px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {isSelected ? (
            <ChevronUp />
          ) : (
            <DropDownComponent style={{ transform: 'rotate(-90deg)' }} />
          )}
        </Box>
        <Typography className={classes.sectionTitle}>
          Available services for the{' '}
          <span style={{ color: '#0054B6' }}>{role?.name}</span> role
        </Typography>
      </Box>
      {isSelected && (
        <Box width="100%" mt="1rem">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mb="1rem"
          >
            <CustomButton
              className={classes.addBtn}
              buttonType="reset"
              onClick={() => history.push('/doctor/admin?tab=14')}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <ManageAll />
              <Text
                i18nKey="Add team member"
                className={classes.addBtnText}
                style={{
                  color: '#147AF3',
                }}
              >
                Manage all services
              </Text>
            </CustomButton>
            {role?.name !== 'Admin' && (
              <CustomButton
                className={classes.addBtn}
                buttonType="reset"
                onClick={() => setOpenAdd(true)}
              >
                <AddIcon />
                <Text i18nKey="Add team member" className={classes.addBtnText}>
                  Assign service
                </Text>
              </CustomButton>
            )}
          </Box>
          <Table
            loading={loadingRate}
            page={page}
            columns={columns as any}
            limit={10}
            handlePagination={handlePagination}
            data={rate || []}
            pagination
            sorting={false}
            onMouseEnterRow={(row: any) => setHoveredRowIndex(row?._id)}
            onMouseLeaveRow={() => setHoveredRowIndex(null)}
          />
        </Box>
      )}
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={() => setAnchorEl(null)}
        MenuElements={
          role?.name === 'Admin'
            ? menuElements?.filter((el) => el?.name !== 'Remove')
            : menuElements
        }
      />
      <RemoveServiceModal
        open={RemoveModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        role={role}
        service={currentRow}
        onSubmit={onSubmitRemove}
        removeRoleLoading={removeRoleLoading}
      />
      <FormProvider {...assignMethods}>
        <AssignService
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          role={role}
          teams={teams}
        />
      </FormProvider>
      <FormProvider {...methods}>
        <BasicModal
          open={open}
          onClose={() => {
            handleClose();
          }}
          handleClose={() => {
            handleClose();
          }}
          isFromModal
          isSlide
          divider
          title={'Edit service'}
          titlePadding="2rem"
          onSubmit={onSubmit}
          loading={updateLoading}
        >
          <Grid container style={{ marginBottom: '5vh' }}>
            <RatesModal
              currentRow={currentRow}
              editMode={true}
              tags={null}
              cpts={null}
              error={error}
              setError={setError}
              id={id}
              setId={setId}
            />
          </Grid>
        </BasicModal>
      </FormProvider>
    </Box>
  );
}

export default BottomSection;
