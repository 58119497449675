import React from 'react';
import { IColumn } from 'components/ui/Table/types';
import { Box, Grid, Typography } from '@material-ui/core';
import { convertType } from './utils';
import { ReactComponent as NotSavedIcon } from 'assets/patientActivities/emptyCopy.svg';
import { ReactComponent as SavedIcon } from 'assets/patientActivities/filledCopy.svg';
import { ReactComponent as EyeIcon } from 'assets/patientActivities/smallEye.svg';
import DropdownIcon from 'assets/patientActivities/dropdown';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useStyles } from 'pages/PatientActivities/styles';
import { ITableColumnsProps } from 'pages/PatientActivities/commun/Columns';
import { getType } from './ActivityTab';

export default function TableColumns({
  openDownloadMenu,
  setEdited,
  setPdfUrl,
  setDeleteId,
  openActionsMenu,
  setBookmark,
  handleBookMark,
  setTypeValue,
}: ITableColumnsProps) {
  const classes = useStyles({});
  const columns: IColumn<any>[] = [
    {
      id: 'type',
      label: <Box fontSize="1rem">Activity type</Box>,
      align: 'left',
      minWidth: 100,
      freeze: true,
      format: (value) => {
        return (
          <Typography style={{ textTransform: 'capitalize' }}>
            {convertType(value)}
          </Typography>
        );
      },
    },

    {
      id: 'entryDate',
      label: <Box fontSize="1rem">Entry Date and Time</Box>,
      align: 'left',
      minWidth: 150,
      format: (value, { patientResponse }) => {
        const date = patientResponse.length > 0 && patientResponse[0].createdAt;
        return date ? date : '-';
      },
    },
    {
      id: 'response',
      label: <Box fontSize="1rem">State</Box>,
      align: 'left',
      minWidth: 100,
      format: (value, { patientResponse }) => {
        const response =
          patientResponse.length > 0 && patientResponse[0].response;
        return response ? response : '-';
      },
    },

    {
      id: 'Reminder',
      label: <Box fontSize="1rem">Results</Box>,
      align: 'left',
      minWidth: 100,
      disableSorting: true,
      format: (value, { activityUrl, _id, isEdited, patientResponse }) => {
        const date = patientResponse.length > 0;
        const handleClick = (event) => {
          openDownloadMenu(event);
          setPdfUrl(activityUrl);
          setEdited(isEdited);
          setDeleteId(_id);
        };
        return (
          <Grid container wrap="nowrap">
            <Grid item>
              <Box
                className={
                  date ? classes.viewButton : classes.viewButtonDisabled
                }
                height="100%"
              >
                <EyeIcon />
                <Typography
                  className={
                    date ? classes.buttonText : classes.buttonTextDisabled
                  }
                >
                  View results
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box
                className={
                  date ? classes.downloadButton : classes.downloadButtonDisabled
                }
                onClick={(event) => {
                  date && handleClick(event);
                }}
                height="100%"
              >
                <DropdownIcon />
              </Box>
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'saved',
      label: <Box fontSize="1rem">Actions</Box>,
      align: 'left',
      minWidth: 100,
      disableSorting: true,
      format: (value, { _id, type, bookmarked }) => {
        return (
          <Box
            onClick={(event) => {
              setTypeValue(getType(type));
              openActionsMenu(event);
              setDeleteId(_id);
              setBookmark(bookmarked);
            }}
          >
            <MoreHorizIcon
              style={{
                cursor: 'pointer',
                fontSize: '24px',
                color: '#425364',
              }}
            />
          </Box>
        );
      },
    },
    {
      id: 'bookmarked',
      label: '',
      align: 'right',
      minWidth: 100,
      disableSorting: true,
      freeze: true,
      format: (value, { _id }) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            paddingRight="2rem"
            style={{
              boxSizing: 'border-box',
            }}
          >
            <Box
              style={{ cursor: 'pointer' }}
              onClick={() => handleBookMark(_id, !value)}
            >
              {value ? <SavedIcon /> : <NotSavedIcon />}
            </Box>
          </Box>
        );
      },
    },
  ];
  return columns;
}
