import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { useStyles } from './styles';
import { QuestionTypeEnum } from './Constants';
import Input from 'components/ui/Inputs';
import Radio from './inputs/Radio';
import Checked from 'pages/AdminPage/Payment/Checked';
import { useForm } from 'react-hook-form';
import { IQuestion } from './FormsPage';
import {
  convertToRawAndStringify,
  editorStateFromStringifiedRaw,
  tryParseJSON,
  convertStringToEditorState,
} from '@eggmed/common/utils/DraftUtils';
import TableForm from 'pages/PatientActivitiesPage/CreateEditForm/TableForm';
import ListForm from 'pages/PatientActivitiesPage/CreateEditForm/ListForm';
import { FormAddress } from 'pages/PatientActivitiesPage/CreateEditForm/FormAddress';
import Email from 'assets/form/blocks/Email';
import { ReactComponent as Signature } from 'assets/PatientSignature.svg';
import PhoneInput from 'react-phone-input-2';

function Response({
  isFormGenerator,
  question,
  isFastPreview = false,
  isNotes = false,
  handleInputChange,
  handleCheckboxChange,
  handleAddress,
  index,
  noMargin = false,
  isView = false,
}: {
  isFormGenerator?: boolean;
  question: IQuestion;
  isFastPreview?: Boolean;
  isNotes?: Boolean;
  index?: number;
  handleInputChange?: (index: number, value: string) => void;
  handleCheckboxChange?: (index: number, value: string) => void;
  handleAddress?: (index: number, value: string, field: string) => void;
  noMargin?: boolean;
  isView?: boolean;
}): React.ReactElement {
  const classes = useStyles({ isFormGenerator });
  const { control, register, watch, setValue } = useForm();
  const generateNumbersArray = (range) => {
    const [min, max] = range;
    const numbersArray = [];

    for (let i = min; i <= max; i++) {
      numbersArray.push(i.toString());
    }

    return numbersArray;
  };
  const handleCheckBox = (value) => {
    handleCheckboxChange(index, value);
  };
  const numbersArray = generateNumbersArray(question?.range);
  const dividerArray = [
    QuestionTypeEnum.MultipleChoice,
    QuestionTypeEnum.SingleChoice,
    QuestionTypeEnum.Dropdown,
    QuestionTypeEnum.LinearScale,
  ];
  const hasDivider =
    dividerArray.includes(question.type as QuestionTypeEnum) && isNotes;

  const handleDraftJs = () => {
    const values = watch('answer');
    handleInputChange(index, convertToRawAndStringify(values));
  };
  const handleLinkClick = (link) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  return (
    <Box width="100%" mt={isNotes ? '-20px' : '-1rem'} ml={!noMargin && '3px'}>
      {hasDivider && (
        <Divider style={{ marginBottom: '1rem', marginTop: '5px' }} />
      )}
      {isFormGenerator &&
        typeof question?.question === 'string' &&
        question.type !== QuestionTypeEnum.header &&
        question?.type !== 'paragraph' &&
        question?.type !== 'HorizantaleLine' &&
        question?.type !== 'Image' &&
        question?.type !== 'Link' && (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="column"
            maxWidth="95%"
            mt="1rem"
            mb="0.3rem"
            style={{ gap: '8px' }}
          >
            {question.type !== QuestionTypeEnum.List &&
              question.type !== QuestionTypeEnum.Orderedlist && (
                <>
                  <Typography className={classes.QuestionBlock}>
                    {question?.question}
                  </Typography>
                  <Typography className={classes.DescriptionBlock}>
                    {question?.description ? question?.description : ''}
                  </Typography>
                </>
              )}
          </Box>
        )}
      <Box width="100%" mr={'1rem'}>
        {question.type === QuestionTypeEnum.ShortAnswer && (
          <Input
            className={classes.answers}
            variant="outlined"
            onBlur={(e) => handleInputChange(index, e.target.value)}
            multiline
            rows={1}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={question.responses}
            placeholder={'Short answer'}
            disabled={isView}
          />
        )}
        {question.type === QuestionTypeEnum.Link && (
          <Box onClick={() => handleLinkClick(question.question)}>
            <Input
              className={classes.link}
              variant="outlined"
              multiline
              rows={2}
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
                readOnly: true,
              }}
              value={question.question}
              placeholder={'Link'}
              readOnly
            />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Email && (
          <Input
            variant="filled"
            type="text"
            style={{ height: '40px' }}
            onBlur={(e) => handleInputChange(index, e.target.value)}
            inputProps={{
              style: {
                height: '40px',
                padding: '0 14px',
                fontStyle: 'italic',
                paddingLeft: '0px',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="email" className={classes.icon}>
                    <Email />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={question.responses}
            placeholder={'e.g., name@example.com'}
            disabled={isView || isFormGenerator}
          />
        )}
        {question.type === QuestionTypeEnum.Phone && (
          <Input
            variant="filled"
            name="phone"
            type="phone"
            register={register}
            control={control}
            value={question.responses}
            onBlur={(e) => handleInputChange(index, e.target.value)}
            disabled={isView || isFormGenerator}
            inputProps={{
              style: {
                height: '40px',
                fontSize: '14px',
                width: '100%',
                fontStyle: 'italic',
              },
            }}
          />
        )}
        {question.type === QuestionTypeEnum.LongAnswer && (
          <TextField
            name="description"
            className={classes.answers}
            variant="outlined"
            onBlur={(e) => handleInputChange(index, e.target.value)}
            multiline
            rows={4}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={question.responses}
            placeholder={'Long answer'}
            disabled={isView}
          />
        )}
        {question.type === QuestionTypeEnum.Table && (
          <Box width={'100%'}>
            <TableForm data={question.question} />
          </Box>
        )}
        {question.type === QuestionTypeEnum.List && (
          <Box width={'100%'}>
            <ListForm data={question?.options} type="unordered" />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Orderedlist && (
          <Box width={'100%'}>
            <ListForm data={question?.options} type="ordered" />
          </Box>
        )}
        {question.type === QuestionTypeEnum.SingleChoice && (
          <Radio
            options={question.options}
            name="options"
            handleInputChange={handleInputChange}
            selectedValue={
              Array.isArray(question.responses)
                ? question.responses?.[0]
                : question.responses
            }
            index={index}
            disabled={isView}
          />
        )}
        {question.type === QuestionTypeEnum.MultipleChoice && (
          <Box width="100%">
            {question.options.map((option) => (
              <Box display="flex" alignItems="center" ml="-10px">
                <Checked
                  color="rgba(31, 97, 220, 1)"
                  handleChange={() => handleCheckBox(option)}
                  disabled={isView}
                  checkedValue={question.responses?.includes(option)}
                />
                <Typography className={classes.optionsPreview}>
                  {option}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {question.type === QuestionTypeEnum.Dropdown && isFormGenerator && (
          <Box width="100%">
            <Input
              variant="filled"
              name="type"
              type="select"
              options={question.options}
              defaultValue={question.options[0]}
              onChange={(e) => handleInputChange(index, e.target.value)}
              register={register}
              control={control}
              // value={question.responses}
              disabled={isView}
            />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Address && (
          <Box width="100%">
            <FormAddress
              register={register}
              control={control}
              response={question.responses}
              handleAddress={handleAddress}
              isView={isView}
            />
          </Box>
        )}
        {question.type === QuestionTypeEnum.Dropdown &&
          isFastPreview &&
          !isFormGenerator && (
            <Box width="100%">
              {question.options.map((option, index) => (
                <Box display="flex" alignItems="center" ml="-10px" mt="1rem">
                  <Typography
                    className={classes.optionsPreview}
                    style={{ margin: '3px 5px 0 1rem' }}
                  >
                    {index + 1}.
                  </Typography>
                  <Typography className={classes.optionsPreview}>
                    {option}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        {question.type === QuestionTypeEnum.Date && (
          <Input
            variant="filled"
            type="date"
            name="dueDate"
            register={register}
            defaultValue={new Date(question.responses).getTime()}
            onChange={(e) => {
              handleInputChange(index, e);
              setValue('dueDate', e);
            }}
            placeholderText={'Date'}
            disabled={isView || isFormGenerator}
            control={control}
            className={classes.dateInput}
            style={{
              width: '50%',
              maxWidth: '50%',
            }}
          />
        )}
        {question.type === QuestionTypeEnum.DateRange && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              gap: '6px',
            }}
          >
            <Box width="100%">
              {question && question?.responses?.startDate && (
                <Typography className={classes.titleStyle}>
                  Start date
                </Typography>
              )}

              <Input
                variant="filled"
                type="date"
                name="startDate"
                register={register}
                defaultValue={new Date(question.responses?.startDate).getTime()}
                onChange={(e) => {
                  handleInputChange(index, e);
                  setValue('startDate', e);
                }}
                placeholderText={'Start date'}
                disabled={isView || isFormGenerator}
                control={control}
                className={classes.dateInput}
              />
            </Box>
            <Box width="100%">
              {question && question?.responses?.endDate && (
                <Typography className={classes.titleStyle}>End date</Typography>
              )}
              <Input
                type="date"
                variant="filled"
                name="endDate"
                register={register}
                defaultValue={new Date(question.responses?.endDate).getTime()}
                onChange={(e) => {
                  handleInputChange(index, e);
                  setValue('endDate', e);
                }}
                placeholderText={'End date'}
                disabled={isView || isFormGenerator}
                control={control}
                className={classes.dateInput}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box width="100%">
        {question.type === QuestionTypeEnum.LinearScale && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="nowrap"
            width="100%"
          >
            <Typography className={classes.linearPreview}>
              {question.options[0]}
            </Typography>
            <Radio
              options={numbersArray}
              handleInputChange={handleInputChange}
              index={index}
              selectedValue={question.responses?.[0]}
              name="range"
              row
              topLabel
              disabled={isView}
            />
            <Typography className={classes.linearPreview}>
              {question.options[1]}
            </Typography>
          </Box>
        )}
        {question.type === QuestionTypeEnum.TextBox && (
          <Input
            type="draftjs"
            name="answer"
            register={register}
            control={control}
            defaultValue={editorStateFromStringifiedRaw(
              question.responses?.[0],
              !tryParseJSON(question.responses?.[0])
            )}
            isBigger
            onBlur={handleDraftJs}
            disabled={isView}
          />
        )}
        {isFormGenerator && question.type === QuestionTypeEnum.Signature && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{
              backgroundColor: '#F0F9FF',
              padding: '16px',
              borderRadius: '16px',
            }}
            width="95%"
            py="4rem"
          >
            <Signature style={{ marginBottom: '5px', color: '#0054B6' }} />
            <Typography className={classes.signText}>Sign here</Typography>
            <Box mt={1}>
              <Typography className={classes.signDesc}>
                By Inserting your signature
              </Typography>
              <Typography className={classes.signDesc}>
                you are e-signing this form
              </Typography>
            </Box>
          </Box>
        )}
        {isFormGenerator && question.type === QuestionTypeEnum.Image && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            height="100%"
          >
            <img src={question.question} height="100%" width="100%" />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Response;
