import React, { useEffect } from 'react';
import {
  Box,
  BoxProps,
  Grid,
  Typography,
  Divider,
  Backdrop,
  IconButton,
  TextField,
  Avatar,
} from '@material-ui/core';
import { MetricUnit } from 'shared';
import BloodHeightWeighInformations from 'components/PatientProfile/BloodHeightWeighInformations';
import { gql, useQuery } from '@apollo/client';
import useModal from 'hooks/useModal';
import { calculateAge, convertMsToDate, formatDate } from 'utils/dateUtils';
import { ReactComponent as GeneralInfoIcon } from 'assets/icons/green/generalInfoIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Button from 'components/ui/Button';
import Skeleton from 'pages/PatientGeneralInfo/../../components/ui/Skeleton';
import useStyles from 'pages/PatientGeneralInfo/./styles';
import { ReactComponent as FileCopyIcon } from 'assets/copy-01.svg';
import { ReactComponent as ContactIcon } from 'assets/patient-profile/contact.svg';
import { ReactComponent as PersonalNotesIcon } from 'assets/patient-profile/personal.svg';
import { ReactComponent as DemographicsIcon } from 'assets/patient-profile/demographics.svg';
import { ReactComponent as AssigneProvider } from 'assets/patient-profile/assignProvider.svg';
import { ReactComponent as FilesIcon } from 'assets/patient-profile/files.svg';
import Accordion from 'components/ui/Accordion';
import FileDocument from './PatientReports';
import {
  convertToPlainText,
  editorStateFromStringifiedRaw,
  tryParseJSON,
} from '@eggmed/common/utils/DraftUtils';
import { Editor } from 'draft-js';
import { DropzoneDialogBase } from 'material-ui-dropzone';
import clsx from 'clsx';
import { convertToRawAndStringify } from '@eggmed/common';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import useCheckPermission from 'contexts/PermissionContext';
import BasicModal from 'components/ui/Modal';
import UnassignProvider from 'pages/PatientListPage/UnassignProvider';
import { useProvider as useAssignProviderContext } from 'pages/PatientListPage/state/Provider';
import AssignProvider, {
  IProviderData,
} from 'pages/PatientListPage/AssignProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { useAssignProvider } from '@eggmed/graphql-client/operations/patientOperations/useEditPatient';
import { log } from 'config';
import { useSnackbar } from 'hooks/useSnackbar';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useRemoveRelatedPatient from '@eggmed/graphql-client/operations/patientAccessControlOperations/useRemoveRelatedPatient';
import { useHistory } from 'react-router-dom';

interface IKeyValueProps {
  label: string;
  value: string | number | Date | undefined;
  loading?: boolean;
  isMargin?: boolean;
  isCopy?: boolean;
}
const KeyValue = ({
  label,
  value,
  loading = false,
  isMargin = true,
  isCopy = false,
  ...rest
}: IKeyValueProps) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(value.toString());
  };
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      mb={1}
      style={{ gap: '0.5rem' }}
      {...rest}
    >
      <Skeleton loading={loading}>
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 500,
            color: '#475467',
          }}
        >
          {label}
        </span>
      </Skeleton>
      <Skeleton loading={loading}>
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 500,
            color: '#344054',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isCopy && (
            <IconButton onClick={copyToClipboard} size="small">
              <FileCopyIcon />
            </IconButton>
          )}
          {t(value) || '-'}
        </span>
      </Skeleton>
    </Box>
  );
};

interface IGeneralInfoProps extends BoxProps {
  _id: string;
  gender: string;
  phone: string;
  city: string;
  registration_date?: string;
  birthday?: string;
  address: string;
  loading: boolean;
  zipcode: string;
  member_status?: string;
  height: MetricUnit;
  weight: MetricUnit;
  bloodType: string;
  createdAt: string;
  editOn: boolean;
  generalInformation: boolean;
  email: string;
  country: string;
  firstname: string;
  lastname: string;
  description: string;
  emergencyName: string;
  emergencyEmail: string;
  emergencyPhone: string;
  relationshipStatus: string;
  employmentStatus: string;
  raceAndEthnicity: string;
  religion: string;
  setCurrentReport: any;
  setOpen: any;
  reportsData: any;
  addReportLoading: any;
  handleAddReport: any;
  handleDeleteReport: any;
  files: any;
  setContactModalOpen: any;
  setDemographicModalOpen: any;
  onDescriptionSubmit: (data: string) => void;
  relatedTo?: IProviderData[];
}

export default function GeneralInfo({
  gender,
  email,
  phone,
  city,
  height,
  weight,
  bloodType,
  birthday,
  address,
  createdAt,
  loading,
  generalInformation,
  zipcode,
  country,
  firstname,
  lastname,
  description,
  setCurrentReport,
  setOpen,
  reportsData,
  addReportLoading,
  handleAddReport,
  handleDeleteReport,
  files,
  setContactModalOpen,
  setDemographicModalOpen,
  emergencyName,
  emergencyEmail,
  emergencyPhone,
  relationshipStatus,
  employmentStatus,
  raceAndEthnicity,
  religion,
  onDescriptionSubmit,
  relatedTo,
  _id,
  ...props
}: IGeneralInfoProps) {
  const { doctor, user } = useAuth();

  const methods = useForm({
    defaultValues: {
      selectedProviders: relatedTo,
      selectedProvider: {},
    },
  });
  const { setValue, watch, handleSubmit, reset } = methods;

  useEffect(() => {
    if (relatedTo) {
      reset({
        selectedProviders: relatedTo || [],
      });
    }
  }, [reset, relatedTo]);

  const classes = useStyles();
  const { dispatch, state } = useModal();
  const isAdmin = ['Admin', 'owner']?.includes(user?.role);

  const {
    openProviderAssignModal,
    handleCloseAssignModal,
    handleOpenAssignModal,
  } = useAssignProviderContext();

  const [contactExpanded, setContactExpanded] = React.useState<boolean>(false);
  const [openUnassignModal, setOpenUnassignModal] =
    React.useState<boolean>(false);

  const [demographicsExpanded, setDemographicsExpanded] =
    React.useState<boolean>(false);
  const [assignedProviders, setAssignedProvidersExpanded] =
    React.useState<boolean>(false);
  const [personalExpanded, setPersonalExpanded] =
    React.useState<boolean>(false);
  const [filesExpanded, setFilesExpanded] = React.useState<boolean>(false);
  const [openDoc, setOpenDoc] = React.useState(false);

  const [fileObjects, setFileObjects] = React.useState([]);

  const { triggerSnack } = useSnackbar();
  function onDeletePicture(deleteFileObj) {
    const filltredArray = fileObjects.filter(
      (value, index: number) => value !== deleteFileObj
    );
    setFileObjects(filltredArray);
  }
  function onAddPicture(newFileObjs) {
    if (fileObjects.length > 0) return null;
    setFileObjects([].concat(fileObjects, newFileObjs));
    return null;
  }
  const { assignProvider, loading: loadingAssign } = useAssignProvider();
  const { t } = useTranslation();
  const history = useHistory();
  const { handleRemoveRelatedPatient, loading: loadingRemove } =
    useRemoveRelatedPatient();
  const hendRemoveProvider = async () => {
    await handleRemoveRelatedPatient(_id, false);
    setOpenUnassignModal(false);
    triggerSnack('Client unassigned successfully');
    history.push('/doctor/patient');
  };
  const onSubmit = async (data) => {
    const relatedProvidersIDs = data?.selectedProviders?.map(
      (provider) => provider?._id
    );
    await assignProvider(
      {
        EditPatientInput: {
          updatedAt: new Date(),
          email: email,
          firstname: firstname,
          lastname: lastname,
          relatedTo: relatedProvidersIDs,
        },
        patientId: _id,
      },
      (e) => log('error', e)
    );
    handleCloseAssignModal();
    triggerSnack();
  };

  async function onSave() {
    await handleAddReport(fileObjects);
    setOpenDoc(false);
  }
  const label = `Remove <strong>${capitalizeFirstLetter(
    firstname
  )} ${capitalizeFirstLetter(lastname)}</strong> from your clients?`;

  const descriptionModal = `Once removed, you will be removed from their care team and your access and interactions will be limited to permissions set at the practice level.`;

  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Clients',
    'edit',
    _id
  );
  return (
    <>
      <Box>
        <Box width="100%" {...props}>
          <Box mt={2}>
            <Accordion
              square
              expanded={contactExpanded}
              onChange={() => setContactExpanded((s) => !s)}
              className={clsx(classes.summary, classes.summaryTopArrow)}
              AccoridonSummary={
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={'center'}
                  width="100%"
                >
                  <Box mt={'0.7rem'}>
                    <ContactIcon />
                  </Box>
                  <Box ml={1} flex={1} mt={'9px'}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        i18nKey="contactInformation"
                        className={classes.titleAccordion}
                      >
                        Contact information
                      </Text>
                      <Skeleton loading={loadingAccess} width={'20px'}>
                        {isEnabled && (
                          <Text
                            i18nKey="edit"
                            className={classes.editButton}
                            onClick={(e) => {
                              setContactModalOpen(true);
                              e.stopPropagation();
                            }}
                          >
                            Edit
                          </Text>
                        )}
                      </Skeleton>
                    </Box>
                    <Box mt={2} mb={1}>
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Email: ')}
                        value={email}
                      />
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Phone: ')}
                        value={phone}
                      />
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Address: ')}
                        value={city && country ? `${city}, ${country}` : '-'}
                      />
                      {!contactExpanded && (
                        <>
                          <Divider className={classes.accordionInDivider} />
                          <KeyValue
                            isMargin={false}
                            data-cy="key-value-general"
                            loading={loading}
                            label={t('Emergency contact: ')}
                            value={emergencyName}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Box ml={'68px'} mr={2} mb={2}>
                <Divider className={classes.accordionDivider} />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Emergency contact: ')}
                  value={emergencyName}
                />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Email: ')}
                  value={emergencyEmail}
                />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Phone: ')}
                  value={emergencyPhone}
                />
              </Box>
            </Accordion>
          </Box>
          <Box mt={2}>
            <Accordion
              square
              expanded={demographicsExpanded}
              onChange={() => setDemographicsExpanded((s) => !s)}
              className={clsx(classes.summary, classes.summaryTopArrow)}
              AccoridonSummary={
                <Box display="flex" alignItems="flex-start" width="100%">
                  <Box mt={'0.5rem'}>
                    <DemographicsIcon />
                  </Box>
                  <Box ml={1} flex={1} mt={'9px'}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        i18nKey="demographics"
                        className={classes.titleAccordion}
                      >
                        Demographics
                      </Text>
                      <Skeleton loading={loadingAccess} width={'20px'}>
                        {isEnabled && (
                          <Text
                            i18nKey="edit"
                            className={classes.editButton}
                            onClick={(e) => {
                              setDemographicModalOpen(true);
                              e.stopPropagation();
                            }}
                          >
                            Edit
                          </Text>
                        )}
                      </Skeleton>
                    </Box>
                    <Box mt={2} mb={1}>
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Age: ')}
                        value={
                          birthday
                            ? `${birthday && calculateAge(birthday)} years`
                            : '-'
                        }
                      />
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Gender: ')}
                        value={capitalizeFirstLetter(t(gender))}
                      />
                      <KeyValue
                        isMargin={false}
                        data-cy="key-value-general"
                        loading={loading}
                        label={t('Relationship status:')}
                        value={capitalizeFirstLetter(t(relationshipStatus))}
                      />
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Box ml={'68px'} mr={2} mb={2}>
                <Divider className={classes.accordionDivider} />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Employment status:')}
                  value={capitalizeFirstLetter(t(employmentStatus))}
                />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Race and ethnicity:')}
                  value={capitalizeFirstLetter(t(raceAndEthnicity))}
                />
                <KeyValue
                  isMargin={false}
                  data-cy="key-value-general"
                  loading={loading}
                  label={t('Relegion :')}
                  value={capitalizeFirstLetter(t(religion))}
                />
              </Box>
            </Accordion>
          </Box>
          {isAdmin && (
            <Box mt={2}>
              <Accordion
                square
                expanded={assignedProviders}
                onChange={() => setAssignedProvidersExpanded((s) => !s)}
                className={clsx(classes.summary, classes.summaryTopArrow)}
                AccoridonSummary={
                  <Box display="flex" alignItems="flex-start" width="100%">
                    <Box marginTop={'0.5rem'}>
                      <AssigneProvider />
                    </Box>
                    <Box ml={1} flex={1} mt={'9px'}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text
                          i18nKey="Assigned providers"
                          className={classes.titleAccordion}
                        >
                          Assigned providers
                        </Text>
                        <Skeleton loading={loadingAccess} width={'20px'}>
                          {isEnabled && (
                            <Text
                              i18nKey="edit"
                              className={classes.editButton}
                              onClick={(e) => {
                                handleOpenAssignModal();
                                e.stopPropagation();
                              }}
                            >
                              Edit
                            </Text>
                          )}
                        </Skeleton>
                      </Box>
                    </Box>
                  </Box>
                }
              >
                <Box ml={'1rem'} mr={2} mb={2} style={{ gap: '27px' }}>
                  {relatedTo &&
                    relatedTo?.map((provider, id) => (
                      <Box key={id} className={classes.wrapperProvider}>
                        <Avatar
                          alt={`${provider.firstname} ${provider.lastname}`}
                          src={provider.picture}
                          className={classes.avatar}
                        >
                          {`${provider?.firstname?.[0]?.toUpperCase() || ''}${
                            provider?.lastname?.[0]?.toUpperCase() || ''
                          }`}
                        </Avatar>
                        <Box className={classes.providerName}>
                          {`${capitalizeFirstLetter(
                            provider?.firstname
                          )} ${capitalizeFirstLetter(provider?.lastname)}`}
                        </Box>
                      </Box>
                    ))}
                  {isAdmin &&
                    relatedTo?.some(
                      (el) => String(el?._id) === String(doctor?._id)
                    ) && (
                      <Button
                        className={classes.confirmButton}
                        data-cy="delete-confirm"
                        onClick={() => setOpenUnassignModal(true)}
                      >
                        {`Remove ${capitalizeFirstLetter(
                          firstname
                        )} from my clients`}
                      </Button>
                    )}
                </Box>
              </Accordion>
            </Box>
          )}
          <Box mt={2}>
            <Accordion
              square
              expanded={personalExpanded}
              onChange={() => setPersonalExpanded((s) => !s)}
              className={clsx(classes.summary, classes.summaryTopArrow)}
              AccoridonSummary={
                <Box display="flex" alignItems="flex-start" width="100%">
                  <Box mt={'0.5rem'}>
                    <PersonalNotesIcon />
                  </Box>

                  <Box ml={1} flex={1} mt={'9px'}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        i18nKey="personalNotes"
                        className={classes.titleAccordion}
                      >
                        Personal notes
                      </Text>
                    </Box>
                    <Box mt={2} mb={1}>
                      {!personalExpanded &&
                        (description ? (
                          tryParseJSON(description) ? (
                            <Typography
                              className={classes.personalNotesSummary}
                            >
                              {convertToPlainText(
                                editorStateFromStringifiedRaw(
                                  description,
                                  false
                                )
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              className={classes.personalNotesSummary}
                            >
                              {description}
                            </Typography>
                          )
                        ) : (
                          '-'
                        ))}
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Box ml={'68px'} mr={2.4} mb={2.4}>
                <TextField
                  name="description"
                  className={classes.textField}
                  variant="outlined"
                  multiline
                  minRows={4}
                  maxRows={4}
                  defaultValue={
                    tryParseJSON(description)
                      ? convertToPlainText(
                          editorStateFromStringifiedRaw(description, false)
                        )
                      : description
                  }
                  onBlur={(e) => {
                    onDescriptionSubmit(e.target.value);
                  }}
                />
              </Box>
            </Accordion>
          </Box>
          <Box mt={2}>
            <Accordion
              square
              expanded={filesExpanded}
              onChange={() => setFilesExpanded((s) => !s)}
              className={classes.summary}
              AccoridonSummary={
                <Box display="flex" alignItems="flex-start">
                  <Box mt={'0.2rem'}>
                    <FilesIcon />
                  </Box>
                  <Box ml={1} flex={1}>
                    <Box>
                      <Text i18nKey="files" className={classes.titleAccordion}>
                        Files
                      </Text>
                      <Box
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenDoc(true);
                        }}
                      >
                        <Text
                          i18nKey="+AddNewFile"
                          className={classes.subtitle}
                        >
                          + Add new file
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              }
            >
              <Box mx={2}>
                <FileDocument
                  loading={loading}
                  setCurrentReport={setCurrentReport}
                  setOpen={setOpen}
                  reports={reportsData}
                  addReportLoading={addReportLoading}
                  handleAddReport={handleAddReport}
                  handleDeleteReport={handleDeleteReport}
                  files={files}
                  notShow={false}
                />
              </Box>
            </Accordion>
          </Box>
        </Box>
        <DropzoneDialogBase
          dialogTitle={t('Upload your picture')}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          fileObjects={fileObjects}
          filesLimit={1}
          cancelButtonText={t('cancel')}
          submitButtonText={t('submit')}
          maxFileSize={5000000}
          open={openDoc}
          onAdd={onAddPicture}
          onDelete={onDeletePicture}
          onClose={() => setOpenDoc(false)}
          onSave={onSave}
          showPreviews
          showFileNamesInPreview={false}
          showAlerts={['error', 'info']}
        />
      </Box>

      <FormProvider {...methods}>
        <BasicModal
          open={openProviderAssignModal}
          onClose={() => {
            handleCloseAssignModal();
          }}
          handleClose={() => {
            handleCloseAssignModal();
          }}
          isFromModal
          isSlide
          divider
          title={'Edit assigned providers'}
          titlePadding="2rem"
          onSubmit={handleSubmit(onSubmit)}
          loading={loadingAssign}
        >
          <Box
            px="2rem"
            py={1}
            sx={{
              width: {
                xs: '50vw',
                lg: '43vw',
                xl: '35vw',
              },
            }}
            height="100%"
          >
            <AssignProvider patient={{ firstname, lastname }} />
          </Box>
        </BasicModal>
      </FormProvider>
      <BasicModal
        open={openUnassignModal}
        onClose={() => setOpenUnassignModal(false)}
        isRegularModal
        isIcon={false}
        title=""
        handleClose={() => setOpenUnassignModal(false)}
        loading={loadingRemove}
      >
        <UnassignProvider
          label={label}
          description={descriptionModal}
          deleteBtn="Remove"
          handleClose={() => setOpenUnassignModal(false)}
          selectedProvider={watch()?.selectedProvider as IProviderData}
          handleDelete={hendRemoveProvider}
        />
      </BasicModal>
    </>
  );
}

GeneralInfo.fragments = {
  GeneralInfo: gql`
    fragment GeneralInformations on Patient {
      gender
      phone
      city
      birthday
      zipcode
      address
      createdAt
      height {
        value
        type
      }
      weight {
        value
        type
      }
      bloodType
    }
  `,
};
