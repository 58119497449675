import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from 'components/ui/Button';
import { ReactComponent as AddMessage } from 'assets/inbox/plus.svg';
import { ReactComponent as Down } from 'assets/icons/black/down.svg';
import { useProvider } from '../../state/Provider';
import useStyles from './styles';
import { enumToArray } from 'utils/enumToArry';
import { typeConversationEnum } from '@eggmed/graphql-client/__generated__/globalTypes';
import { FormatText } from 'utils/FormatText';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Text from 'Text';

const ConversationListHeader = () => {
  const { patient } = useAuth();
  const isPatient = !!patient;
  const classes = useStyles({ isPatient });

  const { handleOpenCreateNewThreadModal, handelSelectTypeConversationList } =
    useProvider();

  const handleOpenNewThreadModal = () => {
    handleOpenCreateNewThreadModal();
  };
  return (
    <Box className={classes.headerContainer} py={2} px={3}>
      <Text i18nKey="Messaging" className={classes.messagingTxt}>
        Messages
      </Text>
      <Button
        variant="contained"
        className={classes.addMessageBtn}
        onClick={handleOpenNewThreadModal}
      >
        <AddMessage />
        <span className={classes.addMessageBtnTextWrapper}>
          <Text
            i18nKey="newMessage"
            className={classes.addMessageBtnText}
            style={{
              display: 'block',
            }}
          >
            New message
          </Text>
        </span>
      </Button>
    </Box>
  );
};
export default ConversationListHeader;
