import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',

    gap: '10px',
    maxHeight: '250px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '12px',
    },
    '&::-webkit-scrollbar-track': {
      background: ' #d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#d7e0eb',
      borderRadius: '20px',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '  #d7e0eb',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    gap: '8px',
    backgroundColor: 'white',
    borderRadius: '4px',
    flex: 'none',
    order: 2,
    alignSelf: 'stretch',
    flexGrow: 0,

    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#CAE8FF',
    },
  },
  messageButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(40),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  profileButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(40),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonsMessage: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
  },
  upcomingBox: {
    backgroundColor: 'white',
    borderRadius: '16px',
    border: '1px solid #EAECF0',
    [theme.breakpoints.down('md')]: {
      maxHeight: '24.5rem',
    },
  },
  subject: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#475467',
  },
  container: {
    background:
      'linear-gradient(54.32deg, rgba(206, 213, 239, 0.45) -14.81%, rgba(244, 242, 238, 0.45) 121.69%)',
    borderRadius: '16px',
  },
  upperBox: {
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '35vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '24.5vw',
    },

    [theme.breakpoints.up('xl')]: {
      width: '19vw',
    },
  },

  lowerBox: {
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '35vw',
    },
    [theme.breakpoints.down('md')]: {
      width: '30vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '24.5vw',
    },

    [theme.breakpoints.up('xl')]: {
      width: '19vw',
    },
  },

  timeUpdated: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#0265DC',
  },
  content: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  labelTxt: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
  },
  WhiteBtn: {
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    padding: '6px 12px',
    width: '100%',
    color: '#344054',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  joinBtn: {
    backgroundColor: '#0265DC',
    borderRadius: '8px',
    border: '1px solid #0265DC',
    padding: '6px 12px',
    width: '100%',
    color: 'white',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#0265DC',
    },
  },
  picture: {
    display: 'flex',
    justifyContent: 'center',
    width: '52px',
    height: '52px',
  },
  doctorLastName: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#475467',
    textTransform: 'capitalize',
  },
  textTitle: {
    fontWeight: 600,
    fontSize: '25px',
    color: '#101828',
  },
  buttonBook: {
    borderRadius: '8px',
    border: '1px solid #0265DC',
    padding: '6px 12px',
    color: '#0265DC',
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'none',
  },
  main: {
    marginBottom: 0,
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '30px',
    fontWeight: 900,
    lineHeight: '24px',
    color: '#425364',
  },
  illustration: {
    width: '100%',
  },
  textDoctor: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    color: 'white',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  textDrugs: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '16px',
    color: '#464665',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  titleBoxes: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '20px',
    color: '#425364',
  },
  physicalBox: {
    backgroundColor: 'white',
    borderRadius: '20px',
    width: '100%',
    boxShadow: '0px 0px 13px #A1B0C4',
    height: '100%',
  },
  calendarBox: {
    backgroundColor: '#ffffff',
    // border: '0.5px solid #BCC6D3',
    borderRadius: '24px',
    flex: 1,
    // marginTop: theme.spacing(2),
  },
  calendarTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '20px',
    color: '#425364',
  },
  doctorName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  timeCal: {
    fontFamily: 'Roboto',
    fontWeight: 900,
    fontSize: '14px',
  },
  age: {
    fontFamily: 'Roboto',
    fontWeight: 200,
    fontSize: '15px',
    color: '#425364',
    textAlign: 'center',
  },
  patientName: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '18px',
    color: '#425364',
    textTransform: 'capitalize',
  },
  doctorEmail: {
    fontWeight: 300,
    fontFamily: 'Roboto',
    fontSize: '12px',
  },
  rightSide: {
    backgroundColor: 'transparent',
    borderRadius: '20px',
    height: '100%',
  },
  boxDetail: {
    border: '0.5px solid #425364',
    borderRadius: '24px',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  drugInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    color: '#828282',
    marginLeft: '8px',
  },
  drugCard: {
    backgroundColor: 'white',
    borderRadius: '10px',
    borderLeft: '4px solid  #6FCF97 ',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    marginBottom: theme.spacing(2),
    boxShadow: '0px 0px 10px #A1B0C4',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px',
  },
  btnMsg: {
    backgroundColor: '#082CAF',
    borderRadius: '12px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    padding: '5px 35px',
    transition: 'all 0.3s',
    [theme.breakpoints.down('lg')]: {
      padding: '2px 15px',
    },

    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  contactedDoctor: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  infoName: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: 'white',
  },
  infoType: {
    fontFamily: 'Roboto',
    fontWeight: 800,
    fontSize: '19px',
    color: 'white',
  },
  informat: {
    backgroundColor: '#1F61DC',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '72px',
    height: '79px',
    padding: '20px',
  },
  avatar: {
    width: '70px',
    height: '70px',
  },
  avatarCal: {
    width: '35px',
    height: '35px',
  },
  avatarPad: { width: '29px', height: '29px', padding: '3px' },
  bookBtn: {
    backgroundColor: '#082CAF',
    borderRadius: '28px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    fontSize: '14px',
    marginTop: theme.spacing(2),
    padding: '8px 20px',
    transition: 'all 0.3s',

    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  textName: {
    fontFamily: 'Roboto',
    fontSize: '30px',
    fontWeight: 900,
    lineHeight: '24px',
    color: '#082CAF',
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize',
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#828282',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
  additionalTitle: {
    fontWeight: 700,
    fontSize: '13px',
    marginLeft: theme.spacing(1),
  },
  additionalDescription: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#828282',
  },
  boxInformation: {
    backgroundColor: 'white',
    borderRadius: '20px',
    width: '46%',
  },
  margin: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    gap: '10px',
  },
  gap: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boxInformationNew: {
    backgroundColor: 'white',
    borderRadius: '20px',
    width: '100%',
    position: 'relative',
  },
  btn: {
    backgroundColor: '#49DCBB',
    borderRadius: '25px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: 600,
    textAlign: 'center',
    padding: '10px 30px',
    textTransform: 'none',
    marginLeft: theme.spacing(2),
  },
  boxAddition: {
    backgroundColor: 'white',
    borderRadius: '20px',
    width: '100%',
    padding: theme.spacing(2),
    boxShadow: '0px 0px 13px #A1B0C4',
  },
  visit: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#082CAF',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  textCalendar: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '38px',
    color: '#425364',
    marginBottom: theme.spacing(2),
  },
  details: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#BCC6D3',
    marginTop: theme.spacing(3),
  },
  boxDetails: {
    width: '100%',
    height: '100px',
    backgroundColor: '#F8FBFC',
    borderRadius: '20px',
  },
  boxDetailsNew: {
    width: '100%',
    // height: '60%',
    backgroundColor: '#F8FBFC',
    borderRadius: '20px',
  },
  bloodName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#567FBD',
    textAlign: 'center',
  },
  bloodType: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '33px',
    color: '#425364',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  avatat: {
    width: '65px',
    height: '65px',
  },
  recommandation: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#9CB2CD',
  },
  circle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#F96999',
  },
  checkText: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000',
  },
  img: {
    width: '100%',
    borderRadius: '20px',
    height: '100%',
    marginTop: theme.spacing(2),
  },
  stat: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#082CAF',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  statImg: {
    width: '155px',
    height: '80px',
    marginLeft: theme.spacing(2),
  },
  legend: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#425364',
  },
  helpAndTerms: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#828282',
    fontStyle: 'normal',
    cursor: 'pointer',
  },
  flexColumn: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      gap: '5.5rem',
    },
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  leftCard: {
    minWidth: '25%',
    width: '25%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      minWidth: '37%',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
}));
export default useStyles;
