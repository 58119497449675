import { Box, Typography, Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Finances } from 'assets/sidebar/wallet.svg';
import useStyles from './styles';
import Skeleton from 'components/ui/Skeleton';
import Button from 'components/ui/Button';
import { ReactComponent as SettingsIcon } from 'assets/dashboard/finances/settings.svg';
import SelectMenu from './SelectMenu';
import Table from 'components/ui/Table';
import EmptyState from 'pages/DashboardPage/EmptyState';
import { ReactComponent as EmptyPayment } from 'assets/emptyState/payment.svg';
import { useProvider } from 'pages/AppointmentListPage/state/Provider';
import useGetFinances from '@eggmed/graphql-client/operations/appointmentsOperations/useGetFinances';
import { transformArrayTimeZone } from 'utils';
import { IColumn } from 'components/ui/Table/types';
import dayjs from 'dayjs';
import { Payment } from 'pages/DoctorFinancePage/DoctorFinance';
import { ReactComponent as PayIcon } from 'assets/dashboard/finances/coins.svg';
import { ReactComponent as DotsIcon } from 'assets/dashboard/finances/dots.svg';
import { ReactComponent as InvoiceIcon } from 'assets/dashboard/finances/invoice.svg';
import { ReactComponent as PaymentIcon } from 'assets/dashboard/finances/coins-hand.svg';
import { ReactComponent as MessageIcon } from 'assets/dashboard/finances/message.svg';
import Menu from 'components/ui/Menu';
import Modals from 'pages/InboxPage/modals';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { useProvider as useInbox } from 'pages/InboxPage/state/Provider';
import { useHistory } from 'react-router-dom';
import BasicModal from 'components/ui/Modal';
import PaymentModal from 'pages/PatientAppointmentPage/PaymentModal';
import { InvoicePreview } from 'pages/DoctorFinancePage/invoice/InvoicePreview';
import Text from 'Text';
import { Trans, useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import FreeTag from 'components/ui/FreeTag';

export const PatientFinance = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [paymentMenuName, setPaymentMenuName] = useState(t('Payment status'));
  const [paymentMenuValue, setPaymentMenuValue] = useState('');
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = useState<any>();
  const [open, setOpen] = useState(false);
  const { handleOpenCreateNewThreadModal } = useInbox();
  const history = useHistory();
  const [step, setStep] = useState<number>(1);
  const [checkoutSession, { loading: loadingMutation }] =
    useMutation(CheckOutSession);

  const { firstname, lastname, middlename, _id, email, picture } =
    currentRow?.doctor || {};

  const { startDate } = currentRow || {};
  const subject = `${dayjs(startDate).format(
    'MMMM DD'
  )}th session with ${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(
    lastname
  )}`;

  const { currentPage, handleChangePage } = useProvider();

  const { data: finances, loading } = useGetFinances({
    limit: '5',
    page: `${currentPage}`,
    filter: paymentMenuValue,
    patient: '',
  });

  const timezone =
    finances?.finances?.result &&
    finances?.finances?.result[0]?.patient?.timeZone;
  const filteredAppointments = transformArrayTimeZone(
    finances?.finances?.result,
    timezone
  );
  function openPayModal() {
    setOpen(true);
  }

  function closePayModal() {
    setOpen(false);
  }

  function handleCloseInvoicePreview() {
    setOpenInvoicePreview(false);
  }
  const CENT = 100;
  async function checkout(_id: string, sessionType: any, roomId: string) {
    const session = await checkoutSession({
      variables: {
        checkoutInput: {
          amount: Number(sessionType?.rate) * CENT,
          session: `${sessionType?.session} (${sessionType?.duration}min)`,
          sessionId: _id,
          roomId: roomId,
          redirectedUrl: 'patient/finance',
        },
      },
    });
    window.location.href = session.data.checkoutSession;
  }
  const columns: IColumn<any>[] = [
    {
      id: 'firstname',
      label: (
        <Box fontSize="1rem">
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'unset',
            }}
            i18nKey="provider"
          >
            Provider
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 190,
      freeze: true,
      format: (_, row) => {
        const { firstname, middlename, lastname, picture } = row?.doctor || {};
        const avatarPicture = picture?.split('/')[3];
        return (
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Avatar
                alt="Doctor picture"
                className={classes.providerAvatar}
                src={avatarPicture !== 'undefined' ? picture : ''}
              >
                {`${firstname && firstname[0].toUpperCase()}${
                  lastname && lastname[0].toUpperCase()
                }`}
              </Avatar>
              <Typography className={classes.providerName}>{`${firstname} ${
                middlename || ''
              } ${lastname}`}</Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'unset',
            }}
            i18nKey="service"
          >
            Service
          </Text>
        </Box>
      ),
      minWidth: 190,
      align: 'left',
      format: (value) =>
        value && (
          <Typography className={classes.rowText}>
            {value?.duration} min {value?.session}
          </Typography>
        ),
    },
    {
      id: 'startDate',
      label: (
        <Box fontSize="1rem">
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'unset',
            }}
            i18nKey="date"
          >
            Date
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 210,
      format: (value) => {
        return (
          <Typography className={classes.rowText}>
            {dayjs(value).format('MMM D, YYYY')}
          </Typography>
        );
      },
    },
    {
      id: 'isPaid',
      label: (
        <Box fontSize="1rem">
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'unset',
            }}
            i18nKey="status"
          >
            Status
          </Text>
        </Box>
      ),
      minWidth: 190,
      align: 'left',
      format: (value, { sessionType }) =>
        value === Payment.paid ? (
          <Text className={classes.paid} i18nKey="paid">
            Paid
          </Text>
        ) : value === Payment.overdue ? (
          <Text className={classes.overdue} i18nKey="overdue">
            Overdue
          </Text>
        ) : value === Payment.waived ? (
          <Text className={classes.waived} i18nKey="waived">
            Waived
          </Text>
        ) : value === Payment.refunded ? (
          <Text className={classes.refunded} i18nKey="refunded">
            Refunded
          </Text>
        ) : parseInt(sessionType?.rate) === 0 ? (
          <Text i18nKey="Not applicable" className={classes.free}>
            Not applicable
          </Text>
        ) : (
          <Text className={classes.pending} i18nKey="pending">
            Pending
          </Text>
        ),
    },
    {
      id: 'sessionType',
      label: (
        <Box fontSize="1rem">
          <Text
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              textTransform: 'unset',
            }}
            i18nKey="amount"
          >
            Amount
          </Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value, { sessionType }) =>
        value &&
        (parseInt(sessionType?.rate) === 0 ? (
          <FreeTag />
        ) : (
          <Typography className={classes.rowText}>US${value?.rate}</Typography>
        )),
    },
    {
      id: 'isPaid',
      label: '',
      minWidth: 300,
      align: 'left',
      freeze: true,
      format: (value, current) =>
        value && (
          <Box className={classes.lastCol}>
            <Box className={classes.lastColbox}>
              {(value === Payment.overdue || value === Payment.pending) &&
                !(parseInt(current?.sessionType?.rate) === 0) && (
                  <Button
                    className={classes.btnPay}
                    onClick={() => {
                      // setStep(1);
                      // openPayModal();
                      setCurrentRow(current);
                      checkout(
                        current?._id,
                        current?.sessionType,
                        current?.invitation?.invitation_id
                      );
                    }}
                  >
                    <PayIcon />
                    <Text className={classes.payBtnText} i18nKey="payNow">
                      Pay now
                    </Text>
                  </Button>
                )}
            </Box>
            <Box className={classes.lastColbox}>
              {current?.invoice?.invoiceRef && (
                <Typography
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentRow(current);
                    setOpenInvoicePreview(true);
                  }}
                  className={classes.invoiceText}
                >
                  INV {`#${current?.invoice?.invoiceRef}`}
                </Typography>
              )}
            </Box>
            <Box
              className={classes.lastColbox}
              minHeight="40px"
              style={{ cursor: 'pointer', width: '30px' }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setCurrentRow(current);
              }}
            >
              <DotsIcon className={classes.dots} />
            </Box>
          </Box>
        ),
    },
  ];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const MenuElementsData = [
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: 'Message',
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: 'View invoice',
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
  ];

  const MenuElementsDataExtended = [
    {
      cy: 'make-payment',
      avatar: <PaymentIcon />,
      name: t('Make payment'),
      onClick: () => {
        checkout(
          currentRow?._id,
          currentRow?.sessionType,
          currentRow?.invitation?.invitation_id
        );
        handleCloseMenu();
      },
      isDisabled: parseInt(currentRow?.sessionType?.rate) === 0,
    },
    {
      cy: 'message',
      avatar: <MessageIcon />,
      name: t('Message'),
      onClick: () => {
        handleOpenCreateNewThreadModal();
        handleCloseMenu();
      },
    },
    {
      cy: 'view-invoice',
      avatar: <InvoiceIcon />,
      name: t('View invoice'),
      onClick: () => {
        setOpenInvoicePreview(true);
        handleCloseMenu();
      },
    },
  ];
  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" gridGap="0.5rem">
        <Finances className={classes.icon} />
        <Text i18nKey="Payments" className={classes.title}>
          Payments
        </Text>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
      >
        <Box display="flex" alignItems="center" gridGap="1rem">
          <SelectMenu
            values={[
              Payment.overdue,
              Payment.pending,
              Payment.waived,
              Payment.paid,
              Payment.refunded,
            ]}
            menuName={paymentMenuName}
            setMenuName={setPaymentMenuName}
            setMenuValue={setPaymentMenuValue}
          />
        </Box>
        <Skeleton loading={false}>
          <Button
            className={classes.btnSettings}
            onClick={() => {
              history.push('/patient/settings?tab=4');
            }}
          >
            <SettingsIcon />
            <Text
              style={{
                color: '#147AF3',
                fontSize: '17px',
                fontWeight: 500,
                textTransform: 'unset',
              }}
              i18nKey="paymentSettings"
            >
              Payment settings
            </Text>
          </Button>
        </Skeleton>
      </Box>
      <Box mt={3}>
        <Table<any>
          loading={loading}
          page={currentPage}
          columns={columns}
          limit={5}
          handlePagination={handleChangePage}
          data={filteredAppointments || []}
          pagination
          EmptyState={
            <Box py="15rem">
              <EmptyState
                icon={<EmptyPayment />}
                title="No payment history yet"
                hasAction={false}
              />
            </Box>
          }
          count={finances?.finances?.count}
          fullData={false}
        />
      </Box>
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={handleCloseMenu}
        MenuElements={
          currentRow?.isPaid === Payment.paid ||
          currentRow?.isPaid === Payment.waived
            ? MenuElementsData
            : MenuElementsDataExtended
        }
      />
      <Modals
        subject={subject}
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
      <BasicModal
        open={open}
        onClose={closePayModal}
        isRegularModal
        title={step === 1 ? t('Make payment') : t('Add new card')}
        titlePadding={step === 1 ? '3rem' : '2rem'}
        handleClose={closePayModal}
        isSlide={step === 1}
        divider
      >
        <PaymentModal
          closeModal={closePayModal}
          step={step}
          setStep={setStep}
          {...currentRow}
        />
      </BasicModal>
      <BasicModal
        open={openInvoicePreview}
        onClose={handleCloseInvoicePreview}
        isRegularModal
        title={t('Invoice Preview')}
        titlePadding="2rem"
        handleClose={handleCloseInvoicePreview}
        isSlide
        divider
      >
        <InvoicePreview
          currentRow={currentRow}
          handleClose={handleCloseInvoicePreview}
        />
      </BasicModal>
    </Box>
  );
};
const CheckOutSession = gql`
  mutation checkoutSession($checkoutInput: CheckoutInput) {
    checkoutSession(checkoutInput: $checkoutInput)
  }
`;
