import React from 'react';

import TaskPageProvider from './state/Provider';
import TaskPage from './TaskPage';

export interface ITaskGlobalPage {
  isDashboard?: boolean;
  data?: {
    patient: {
      _id: string;
      firstname: string;
      lastname: string;
      middlename: string;
      timeZone: string;
      username: string;
      email: string;
      picture: string;
    };
  };
}
export default function index({ ...props }: ITaskGlobalPage) {
  return (
    <TaskPageProvider isAppt={false} patientParam={undefined}>
      <TaskPage {...props} />
    </TaskPageProvider>
  );
}
