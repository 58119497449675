import React, { ReactElement, lazy, Suspense } from 'react';
import Layout from 'components/Layout';
import PatientLayout from 'components/PatientLayout';
import { ReactComponent as EggmedLogo } from 'assets/logos/eggmedlogocomplet.svg';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import AssignProvider from 'pages/PatientListPage/state/Provider';
import TaskPageProvider from 'pages/Taskpage/state/Provider';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const AddPatientPage = lazy(() => import('./pages/AddPatientPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const PatientProfilePage = lazy(() => import('./pages/PatientProfilePage'));
const DoctorProfilePage = lazy(() => import('./pages/DoctorProfilePage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const VerificationSignup = lazy(() => import('./pages/VerificationSignup'));
const VerificationOrderPage = lazy(
  () => import('./pages/VerificationOrderPage')
);
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignupPage = lazy(() => import('./pages/SignupPage'));
const WikiPage = lazy(() => import('./pages/WikiPage'));
const SchedulePage = lazy(() => import('./pages/SchedulePage'));
const PatientListPage = lazy(() => import('./pages/PatientListPage'));
const InboxPage = lazy(() => import('./pages/InboxPage'));
const AppointmentListPage = lazy(() => import('./pages/AppointmentListPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const TaskPage = lazy(() => import('./pages/Taskpage'));
const MeetingPage = lazy(() => import('./pages/MeetingPage'));
const WaitingRoomPage = lazy(() => import('./pages/WaitingRoomPage'));
const LeftMeetingPage = lazy(() => import('./pages/LeftMeetingPage'));
const DoctorMeetingWaitingRoom = lazy(
  () => import('./pages/DoctorMeetingWaitingPage')
);
const AppointmentPage = lazy(() => import('./pages/AppointmentPage'));
const WelcomePage = lazy(() => import('./pages/WelcomePage'));
const StaffProfile = lazy(() => import('./pages/StaffProfile'));
const SMSPage = lazy(() => import('./pages/SMSPage'));
import PatientDashboard from './pages/PatientDashboard';
import PatientAppointment from './pages/PatientAppointmentPage';
import PatientInbox from './pages/PatientInboxPage';
import PatientProfile from './pages/PatientProfile';
import AccessControl from './pages/AccessControlPage';
import PatientNotification from 'pages/PatientNotificationPage';
import DoctorNotification from 'pages/DoctorNotificationPage';

import { IRouteProps } from 'components/RootGenerator';
import DoctorAccessPage from 'pages/AccessControlPage/DoctorAccessPage';
import PatientSettings from 'pages/PatientSettingsPage';
import RefillRequest from 'pages/RefillRequestsPage';
import HelpPage from 'pages/HelpPage';
import DoctorHelpPage from 'pages/DoctorHelpPage';
import Terms from 'pages/TermsPage';
import PatientDoctorProfile from 'pages/PatientDoctorProfile';
import DoctorTerms from 'pages/DoctorTerms';
import PatientTerms from 'pages/PatientTerms';
import DoctorFinance from 'pages/DoctorFinancePage';
import PatientActivities from 'pages/PatientActivitiesPage';
import PatientFinance from 'pages/PatientFinancePage';
// import VideoRoomPage from 'pages/VideoRoomPage';
import { ReactComponent as Eggm } from 'assets/LogoEggm.svg';

const useStyles = makeStyles({
  root: {
    width: '30%',
    height: '10px',
    backgroundColor: 'white',
    marginTop: '3%',
    borderRadius: '50px',
  },
  barColorPrimary: {
    backgroundImage: 'linear-gradient(to right, #39A0FF, #8FFF85)',
    borderRadius: '50px',
  },
});
const WithSuspense = ({ children }: any) => {
  const classes = useStyles();
  return (
    <Suspense
      fallback={
        <Box
          minHeight="100vh"
          display="flex"
          margin="auto"
          width="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: '#F4F2EE' }}
        >
          <Eggm />
          <LinearProgress
            classes={{
              root: classes.root,
              barColorPrimary: classes.barColorPrimary,
            }}
          />
        </Box>
      }
    >
      {children}
    </Suspense>
  );
};

const routes: IRouteProps[] = [
  {
    path: '/login',
    component: () => (
      <WithSuspense>
        <LoginPage />
      </WithSuspense>
    ),
  },
  {
    path: '/welcome',
    component: () => (
      <WithSuspense>
        <WelcomePage />
      </WithSuspense>
    ),
  },
  {
    path: '/signup',
    component: () => (
      <WithSuspense>
        <SignupPage />
      </WithSuspense>
    ),
  },
  {
    path: '/org/:id',
    component: (): ReactElement => <SignupPage />,
  },
  // {
  //   path: '/signup/:id',
  //   component: () => (
  //     <WithSuspense>
  //       <SignupPage />
  //     </WithSuspense>
  //   ),
  // },
  {
    path: '/forgotPassword',
    component: () => (
      <WithSuspense>
        <ForgotPasswordPage />
      </WithSuspense>
    ),
  },
  {
    path: '/resetPassword/:id',
    component: () => (
      <WithSuspense>
        <ResetPasswordPage />
      </WithSuspense>
    ),
  },
  {
    path: '/verificationStaff/:id',
    component: () => (
      <WithSuspense>
        <VerificationSignup />
      </WithSuspense>
    ),
  },
  {
    path: '/verification/:id',
    component: () => (
      <WithSuspense>
        <VerificationSignup />
      </WithSuspense>
    ),
  },
  {
    path: '/verification',
    component: () => (
      <WithSuspense>
        <VerificationOrderPage />
      </WithSuspense>
    ),
  },
  {
    path: '/terms',
    component: () => (
      <WithSuspense>
        <Terms />
      </WithSuspense>
    ),
  },
  {
    path: '/meeting',
    layout: WithSuspense,
    nestedRoutes: [
      {
        path: '/end/:roomId',
        component: (): ReactElement => <LeftMeetingPage />,
      },
      {
        path: '/waitingroom',
        component: (): ReactElement => <WaitingRoomPage />,
      },
      // {
      //   path: '/test/:id',
      //   component: (): ReactElement => <VideoRoomPage />,
      // },
      {
        path: '/:id',
        component: (): ReactElement => <MeetingPage />,
      },
    ],
  },
  {
    path: '/sms',
    component: () => (
      <WithSuspense>
        <SMSPage />
      </WithSuspense>
    ),
  },
  {
    path: '/doctor',
    layout: Layout,
    nestedRoutes: [
      {
        path: '/help',
        component: (): ReactElement => <DoctorHelpPage />,
      },
      {
        path: '/finance',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <DoctorFinance />,
      },
      {
        path: '/task',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <TaskPage />,
      },
      {
        path: '/terms',
        component: (): ReactElement => <DoctorTerms />,
      },
      {
        path: '/meeting/drroom/:roomId',
        component: (): ReactElement => <DoctorMeetingWaitingRoom />,
      },
      {
        path: '/admin',
        layoutProps: {
          isMargin: false,
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <AdminPage />,
      },
      {
        path: '/appointment/:id',
        component: (): ReactElement => <AppointmentPage />,
      },
      {
        path: '/appointment',
        component: (): ReactElement => <AppointmentListPage />,
      },
      {
        path: '/inbox',
        layoutProps: {
          isMargin: false,
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <InboxPage />,
      },
      {
        path: '/patient/:id',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <PatientProfilePage />,
      },
      {
        path: '/patient',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => (
          <AssignProvider>
            <PatientListPage />
          </AssignProvider>
        ),
      },
      {
        path: '/staff/:id',
        component: (): ReactElement => <DoctorProfilePage isPatient />,
      },
      {
        path: '/notifications',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <DoctorNotification />,
      },
      {
        path: '/addpatient',
        component: (): ReactElement => <AddPatientPage />,
      },
      {
        path: '/refillRequests',
        component: (): ReactElement => <RefillRequest />,
      },

      {
        path: '/schedule',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <SchedulePage />,
      },
      {
        path: '/wiki',
        component: (): ReactElement => <WikiPage />,
      },
      {
        path: '/dashboard',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <DashboardPage />,
      },
      {
        path: '/:id',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <DoctorProfilePage />,
      },
      {
        path: '/',
        layoutProps: {
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <DashboardPage />,
      },
    ],
  },
  {
    path: '/patient',
    layout: PatientLayout,
    nestedRoutes: [
      {
        path: '/doctor/:id',
        component: (): ReactElement => <PatientDoctorProfile />,
      },
      {
        path: '/appointment',
        component: (): ReactElement => <PatientAppointment />,
      },
      {
        path: '/access_control/:id',
        component: (): ReactElement => <DoctorAccessPage />,
      },
      {
        path: '/access_control',
        component: (): ReactElement => <AccessControl />,
      },

      {
        path: '/notifications',
        component: (): ReactElement => <PatientNotification />,
      },
      {
        path: '/settings',
        layoutProps: {
          isMargin: false,
          isBackgroundGrey: true,
        },
        component: (): ReactElement => <PatientSettings />,
      },
      {
        path: '/inbox',
        component: (): ReactElement => <PatientInbox patient />,
      },
      {
        path: '/records',
        component: (): ReactElement => (
          <TaskPageProvider isAppt={true} patientParam={undefined}>
            <InboxPageProvider>
              <PatientProfile />
            </InboxPageProvider>
          </TaskPageProvider>
        ),
      },
      {
        path: '/activities',
        component: (): ReactElement => <PatientActivities />,
      },
      {
        path: '/finance',
        component: (): ReactElement => <PatientFinance />,
      },
      {
        path: '/help',
        component: (): ReactElement => <HelpPage />,
      },
      {
        path: '/terms_and_service',
        component: (): ReactElement => <PatientTerms />,
      },
      {
        path: '/:id',
        component: (): ReactElement => (
          <TaskPageProvider isAppt={true} patientParam={undefined}>
            <InboxPageProvider>
              <PatientProfile />
            </InboxPageProvider>
          </TaskPageProvider>
        ),
      },
      {
        path: '/',
        component: (): ReactElement => <PatientDashboard />,
      },
    ],
  },
  {
    path: '/error',
    component: (): ReactElement => (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
  {
    path: '/',
    component: (): ReactElement => (
      <WithSuspense>
        <LoginPage />
      </WithSuspense>
    ),
  },
  {
    path: '*',
    render: (): ReactElement => (
      <WithSuspense>
        <h1>Not found</h1>
      </WithSuspense>
    ),
  },
];

export default routes;
