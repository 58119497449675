import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Table, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../Pagination';
import { calculatePaginationTabs, getLimitData } from './tableutils';
import { IColumn } from './types';
import TableHeadRow from './TableHeadRow';
import TableBodyRow from './TableBodyRow';
import ScrollBar from '../ScrollBar/index';

const useStyles = makeStyles({
  tableContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    maxHeight: '56px',
  },
  tableCell: {
    padding: '0.5px',
    textAlign: 'center',
    minHeight: '56px',
    border: 'none',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
  },
  tableHeadCell: {
    padding: '0.5px',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
    borderBottom: '2px solid #ddd',
    height: '24px',
    minWidth: '150px',
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
  },
  stickyColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    minHeight: '56px',
  },
  scrollableMiddle: {
    overflowX: 'auto',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    overflowY: 'auto',
  },
  lastColumn: {
    position: 'sticky',
    right: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
  },
  assigneeColumnHeader: {
    minWidth: '450px',
  },
  lastColumnHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    minHeight: '56px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '56px',
  },
  middleTable: {
    minWidth: 'max-content', 
    width: '100%',
  }
});

interface ITableProps<T> {
  columns: IColumn<T>[];
  data: T[];
  handlePagination?: (event: any, page: number) => void;
  limit: number;
  page: number;
  pagination?: boolean;
  downSortIcon?: any;
  upSortIcon?: any;
  sorting?: boolean;
  loading: boolean;
  type?: 'rounded' | 'normal' | 'gray';
  count?: number;
  EmptyState?: string | ReactElement;
  stateText?: string;
  textDetails?: string;
  className?: string;
  isFreezed?: boolean;
  fullData?: boolean;
  onRowClick?: (row: T) => void;
  onMouseEnterRow?: (row: T) => void;
  onMouseLeaveRow?: (row: T) => void;
}

export default function FreezedTables<T>({
  columns,
  data,
  handlePagination,
  downSortIcon,
  upSortIcon,
  limit,
  page,
  sorting = false,
  pagination = false,
  loading = false,
  type = 'rounded',
  count,
  EmptyState,
  stateText,
  textDetails,
  fullData = true,
  className,
  onRowClick,
  onMouseEnterRow,
  isFreezed = false,
  onMouseLeaveRow,
}: ITableProps<T>): ReactElement {
  const classes = useStyles();
  const [tableData, setTableData] = useState<T[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);
  const [hoveredRow, setHoveredRow] = useState<any | null>(null);

  const handleRowHover = (row: T) => {
    setHoveredRow(row);
    onMouseEnterRow && onMouseEnterRow(row);
  };

  const removeRowHover = (row: T) => {
    setHoveredRow(null);
    onMouseLeaveRow && onMouseLeaveRow(row);
  };
  const rowStyle = (row: T) => ({
    backgroundColor: row === hoveredRow ? '#F5FBFF' : 'transparent',
    cursor: 'pointer',
  });
  const limitedData = getLimitData<T>(page, limit, tableData, fullData);

  if (data?.length === 0 && !loading) return <>{EmptyState}</>;

  return (
    <Box>
      <TableContainer
        className={classes.tableContainer}
        data-cy="table-container"
      >
        <Table
          style={{
            height: '24px',
            zIndex: 1,
            boxShadow: '6px 0px 4px  rgba(0, 0, 0, 0.05)',
            width: 'auto',
          }}
          classes={{ root: classes.table }}
          aria-label="first-column"
        >
          <TableHeadRow<T>
            type={type}
            columns={columns.slice(0, 1)}
            tableData={tableData}
            initialData={data}
            setTableData={setTableData}
            isFreezed={isFreezed}
            DownSortIcon={downSortIcon}
            UpSortIcon={upSortIcon}
            loading={loading}
            sorting={sorting}
          />
          <TableBodyRow<T>
            type={type}
            loading={loading}
            columns={columns.slice(0, 1)}
            sorting={sorting}
            data={limitedData}
            isFreezed={isFreezed}
            stateText={stateText}
            textDetails={textDetails}
            className={className}
            onClick={onRowClick}
            onMouseEnterRow={handleRowHover}
            onMouseLeaveRow={removeRowHover}
            rowStyle={rowStyle}
          />
        </Table>
        <div className={classes.scrollableMiddle}>
        <ScrollBar table={true}>
          <Table classes={{ root: classes.table }} aria-label="middle-columns">
            <TableHeadRow<T>
              type={type}
              columns={columns.slice(1, columns.length - 1)}
              tableData={tableData}
              initialData={data}
              setTableData={setTableData}
              isFreezed={isFreezed}
              DownSortIcon={downSortIcon}
              UpSortIcon={upSortIcon}
              loading={loading}
              sorting={sorting}
            />
            <TableBodyRow<T>
              type={type}
              loading={loading}
              columns={columns.slice(1, columns.length - 1)}
              sorting={sorting}
              data={limitedData}
              isFreezed={isFreezed}
              stateText={stateText}
              textDetails={textDetails}
              className={className}
              onClick={onRowClick}
              onMouseEnterRow={handleRowHover}
              onMouseLeaveRow={removeRowHover}
              rowStyle={rowStyle}
            />
          </Table>
        </ScrollBar></div>

        <Table
          style={{
            width: 'auto',
            height: '24px',
            zIndex: 1,
            boxShadow: '-6px 0px 4px  rgba(0, 0, 0, 0.05)',
          }}
          classes={{ root: classes.table }}
          aria-label="last-column"
        >
          <TableHeadRow<T>
            type={type}
            columns={columns.slice(columns.length - 1, columns.length)}
            tableData={tableData}
            isFreezed={isFreezed}
            initialData={data}
            setTableData={setTableData}
            DownSortIcon={downSortIcon}
            UpSortIcon={upSortIcon}
            loading={loading}
            sorting={sorting}
          />
          <TableBodyRow<T>
            type={type}
            loading={loading}
            columns={columns.slice(columns.length - 1, columns.length)}
            sorting={sorting}
            data={limitedData}
            stateText={stateText}
            isFreezed={isFreezed}
            textDetails={textDetails}
            className={className}
            onClick={onRowClick}
            rowStyle={rowStyle}
            onMouseEnterRow={handleRowHover}
            onMouseLeaveRow={removeRowHover}
          />
        </Table>
      </TableContainer>

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={1.5}
      >
        {pagination && (
          <Pagination
            count={calculatePaginationTabs<T>(limit, count || data.length)}
            onChange={handlePagination}
            page={page}
            variant="outlined"
            shape="rounded"
          />
        )}
      </Box>
    </Box>
  );
}
