import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, BoxProps } from '@material-ui/core';
import { ReactComponent as UploadFileIcon } from 'assets/icons/upload-01.svg';

const useStyles = makeStyles((theme) => ({
  root: (dragActive) => ({
    border: dragActive ? '2px solid #0265DC' : '1px solid #BCC6D3',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '1%',
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      border: '1px solid #BCC6D3',
    },
  }),
  iconFile: {
    fontSize: '30px',
    marginRight: theme.spacing(2),
    backgroundColor: 'white',
    padding: '1%',
    borderRadius: '8px',
    marginTop: '20px',
    border: '1px solid #BCC6D3',
    color: '#8ca7ce',
  },
  fileNamePart1: {
    color: '#0054B6',
    marginRight: '4px',
  },
  fileNamePart2: {
    color: '#475467',
    marginLeft: '4px',
  },
  fileNameMaxSize: {
    color: '#475467',
    marginBottom: '20px',
    marginTop: '5px',
  },
  typographyStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    height: '10px',
    marginTop: '20px',
    alignItems: 'center',
  },
  date: {
    fontSize: '12px',
  },
  dragIcon: {
    color: '#0054B6',
    alignSelf: 'flex-end',
  },
}));

interface IAttachmentInputProps extends BoxProps {
  handleAction?: (e: any) => void;
  handleActionDragAndDrop?: (e: any) => void;
  namePart1: string;
  namePart2: string;
  max_size: string;
  createdAt: Date;
  isActionable?: boolean;
  time?: boolean;
  custormIcon?: React.ReactNode;
}

export default function AttachmentInputFile({
  handleAction,
  handleActionDragAndDrop,
  namePart1,
  namePart2,
  max_size,
  isActionable = false,
  createdAt,
  time = true,
  custormIcon,
  ...props
}: IAttachmentInputProps) {
  const [dragActive, setDragActive] = useState(false);
  const classes = useStyles(dragActive);
  const handleDragEnter = () => setDragActive(true);
  const handleDragLeave = () => setDragActive(false);
  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);
    handleActionDragAndDrop(event);
  };

  return (
    <label htmlFor="raised-button-file">
      <Box
        className={classes.root}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <Box
          ml={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {custormIcon ? (
            custormIcon
          ) : (
            <UploadFileIcon className={classes.iconFile} />
          )}
          <Typography className={classes.typographyStyle}>
            <b className={classes.fileNamePart1}>{namePart1}</b> or{' '}
            <p className={classes.fileNamePart2}>{namePart2}</p>
          </Typography>

          <Typography className={classes.fileNameMaxSize}>
            {max_size}
          </Typography>
        </Box>

        {isActionable && (
          <Box mt={2} display="flex" justifyContent="center">
            <input
              id="raised-button-file"
              type="file"
              onChange={handleAction}
              style={{ display: 'none' }}
            />
          </Box>
        )}
      </Box>
    </label>
  );
}
